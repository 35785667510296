const DEFAULT_QUESTIONS = [
  {
    text: 'Tu balance mensual',
    question: '¿Cuál es mi balance mensual?'
  },
  {
    text: 'Tu saldo de banco',
    question: '¿Cuáles es mi saldo de banco?'
  },
  {
    text: 'Las deudas de tus proveedores',
    question: '¿Cuáles son las deudas de mis proveedores?'
  }
]

const DefaultQuestions = ({ handleSendMessage }) => {
  return (
    <div className="flex gap-4 text-base">
      {DEFAULT_QUESTIONS.map((question, index) => (
        <div
          key={index}
          className="flex-grow pl-4 pr-12 py-2 border border-gray rounded-lg resize-none cursor-pointer"
          onClick={() => handleSendMessage(question.question)}
        >
          <p className="font-semibold">Pregúntame</p>
          <p className="italic">{question.text}</p>
        </div>
      ))}
    </div>
  )
}

export default DefaultQuestions
