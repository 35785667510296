/* eslint-disable no-console */
import { AxiosInstance } from 'axios'
import { BaseHTTP } from './base.http'

export class TablesAPI {

  private http !: AxiosInstance

  constructor() {
    this.http = BaseHTTP.getInstance().http
  }

  getDataHistoricalSales(params) {
    const path = `v1/business/${params}/resume`
    return this.http.get(path)
  }

  getDataBalance(businessId: number, queryFrom: string, queryTo: string) {
    const encodedQueryFrom = encodeURIComponent(queryFrom)
    const encodedQueryTo = encodeURIComponent(queryTo)
    const path = `v1/business/${businessId}/balance?queryFrom=${encodedQueryFrom}&queryTo=${encodedQueryTo}`
    return this.http.get(path)
  }

  patchApproveAll(businessId, payload){
    const path = `v1/business/${businessId}/approve-all`
    return this.http.patch(path, payload)
  }
  
  getCuentaContable(queryParams) {
    const path = 'https://luca-api-dev-cbs8qj1d.ue.gateway.dev/v1/cuenta-contable/'
    return this.http.get(path, {
      params: queryParams,
      transformResponse: res => res, // Desactiva transformaciones
    })
  }
  

  // Si necesitas agregar un POST, este sería el formato:
  createCuentaContable(data) {
    const path = `v1/cuenta-contable/`
    return this.http.post(path, data)
  }
}

export default new TablesAPI()