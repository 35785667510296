// Components
import SideBar from './sideBar/SideBar'
import { Header } from './header/Header'
import { SidebarProvider } from './sideBar/SidebarContext'
/**
 *
 * @todo change in the future for <Outlet/> instead of children
 */
import { useLocation } from 'react-router-dom'

const Layout = ({ children }) => {
  const location = useLocation()
  const isBusinessPage = location.pathname === '/' || location.pathname === '/BusinessInsights'

  return (
    <SidebarProvider>
      <div className="flex min-h-screen bg-slate-100/70">
        <SideBar />
        <div className="flex-1 overflow-x-hidden">
          <Header />
          <div className={`mx-auto px-14 py-5 ${isBusinessPage ? 'pt-4' : 'pt-24'}`}>
            {children}
          </div>
        </div>
      </div>
    </SidebarProvider>
  )
}

export default Layout
