import { useState, forwardRef, Fragment, useEffect, useContext } from 'react'
import GlobalContext from '../../../context/global-context'

//assets
import isologo_SII from '../../../assets/isologo_SII.png'
import iconCalendario from '../../../assets/iconos/iconCalendario.png'

//api
import { OrganizationAPI } from '../../../api/organization.api'

// components
import { Button, Text } from '../ui/index'
import Modal from './ModalDialogAddProps'
import { formatDate, formatCurrency } from '../../utils/formatter'

// material
import ClearIcon from '@mui/icons-material/Clear'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import Slide from '@mui/material/Slide'
import FilterDateControl from '../table/FilterDateControl'
import Loader from '../ui/Loader'
import CustomButton from '../ui/CustomButton'
import ModalAdmVinculacion from './ModalAdmVinculacion'

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

const ModalDialogMotionEditForm = ({open,setOpen,idLibro,type,status,setSwitchGetData,title,aprobado}) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [data, setData] = useState({})
  const { currentBusiness } = useContext(GlobalContext)
  const organizationAPI = new OrganizationAPI()
  const [switchDate, setSwitchDate] = useState(false)
  const [comentario, setComentario] = useState(data.glosa || '')
  const [errorComentario, setErrorComentario] = useState('')
  const [selectedCuentaVenta, setSelectedCuentaVenta] = useState(null)
  const [initialized, setInitialized] = useState(false)
  const [selectedType, setSelectedType] = useState('Gastos')
  const [selectedClasificador, setSelectedClasificador] = useState(null)
  const [isLoaderVisible, setIsLoaderVisible] = useState(false)
  const [dataContable, setDataContable] = useState([])
  const [isChecked, setIsChecked] = useState(false)
  const [openModalCreate, setOpenModalCreate] = useState(false)

  const handleClose = () => setOpen(false) 
  
  const functionGetDataLibroContable = async (overrideType = null) => {
    try {
      setIsLoaderVisible(true)
      const typeMapping = {
        'Gastos': 'gastos',
        'Existencias': 'existensias',
        'Activos Fijos': 'activos-fijos',
        'Activos Intangibles': 'activos-intangibles',
      }
      const selectedTypeKey = type === 'venta'
        ? 'ingreso'
        : typeMapping[overrideType || selectedType] || 'gastos'
      const response = await organizationAPI.getDataLibroContable(currentBusiness.id, idLibro, type)
      const responseCuentas = await organizationAPI.getCuentasContables({
        businessId: currentBusiness.id,
        type: selectedTypeKey
      })
      setSwitchDate(false)
      setData(response.data)
      setDataContable(responseCuentas.data)
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoaderVisible(false)
    }
  }  

  const handleSubmit = async () => {
    try {
      setIsLoaderVisible(true)
      const cuentaContableId =type === 'venta'? selectedCuentaVenta: selectedClasificador
      const updateData = {
        cuentaContableId,
        glosa: comentario,
        vencimiento: data.fechaVencimiento,
        validation: data.validation !== undefined ? data.validation : 1
      }
      const response = await organizationAPI.patchDataLibroContable(
        currentBusiness?.id,
        idLibro,
        updateData
      )
      console.info(response)
      setSwitchGetData(true)
      functionGetDataLibroContable()
      handleClose()
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoaderVisible(false)
    }
  }

  useEffect(() => {
    functionGetDataLibroContable()
  }, [])

  const handleOptionSelect = (e) => {
    const { name, value } = e.target
    if (value === '0') return setModalOpen(true)
  
    setData((prevData) => ({
      ...prevData,
      [name]: name === 'validation' ? Number(value) : value
    }))
    switch (name) {
      case 'idCuentaVenta':
        setSelectedCuentaVenta(value)
        break
      case 'idCuentaClasificador':
        setSelectedClasificador(value)
        break
      default:
        break
    }
  }

  const handleNoConsiderar = (e) => {
    const { name } = e.target
    setData((prevData) => ({
      ...prevData,
      [name]: prevData.validation === -5 ? 1 : -5, // Alterna entre 1 y -5
    }))
  }

  const formatDateToShow = dateString => {
    if (!dateString) return ''
    const date = new Date(dateString)
    const year = date.getFullYear()
    const month = (date.getMonth() + 1).toString().padStart(2, '0')
    let day = switchDate ? date.getDate() + 1 : date.getDate()
    day = day.toString().padStart(2, '0')
    return `${year}-${month}-${day}`
  }

  const handleComentario = e => {
    const valorNuevo = e.target.value
    if (valorNuevo.length <= 40) {
      setComentario(valorNuevo)
      setErrorComentario('')
    } else {
      setErrorComentario('El comentario no puede exceder los 40 caracteres.')
    }
  }

  useEffect(() => {
    if (data && data.validation === undefined) {
      setData((prevData) => ({
        ...prevData,
        validation: aprobado ?? 1
      }))
    }
  }, [data, aprobado])
  
  useEffect(() => {
    if (data && data.nombreCuenta && !initialized) {
      const cuentaSeleccionada = dataContable.find(
        (cuenta) => cuenta.name === data.nombreCuenta
      )
      if (cuentaSeleccionada) {
        if (type === 'venta') {
          setSelectedCuentaVenta(cuentaSeleccionada.id)
        } else {
          setSelectedClasificador(cuentaSeleccionada.id)
        }
      }
      setInitialized(true)
    }
  }, [data, dataContable, type, initialized])

  const handleTypeSelect = (e) => {
    const newType = e.target.value
    setSelectedType(newType)
    setSelectedClasificador(null)
    functionGetDataLibroContable(newType)
  }
  
  const handleClasificadorSelect = (e) => {
    setSelectedClasificador(e.target.value)
  }
  
  const getFilteredOptions = () => {
    return dataContable.map(item => ({
      id: item.id,
      nombreCuenta: item.name
    }))
  }

  const CustomSelect = ({ label, name, options, value, onChange, disabled }) => (
    <div className='flex flex-col'>
      {label && (
        <label htmlFor={name} className='block text-gray-700 text-sm font-bold mb-2'>
          {label}
        </label>
      )}
      <select
        className='w-80 h-9 block border border-gray-300 px-4 py-2 rounded-full'
        name={name}
        onChange={onChange}
        value={value}
        disabled={disabled}
      >
        {options.map((option) => (
          <option key={option.id} value={option.id}>
            {option.nombreCuenta}
          </option>
        ))}
      </select>
    </div>
  )
  
  const ClassificationSection = ({ type, data, dataClasificacion, onOptionSelect }) => (
    <>
      {type === 'venta' ? (
        <>
          <label htmlFor='idCuentaVenta' className='block text-gray-700 text-sm font-bold mb-2 ml-5'>
            Clasificación
          </label>
          <CustomSelect
            name='idCuentaVenta'
            options={dataClasificacion}
            value={data.idCuentaVenta || ''}
            onChange={onOptionSelect}
            disabled={data.validation === -5 || dataClasificacion.length === 0}
          />
        </>
      ) : (
        <>
          <CustomSelect
            label='Selecciona Tipo'
            name='selectedType'
            options={[
              { id: 'Gastos', nombreCuenta: 'Gastos' },
              { id: 'Existencias', nombreCuenta: 'Existencias' },
              { id: 'Activos Fijos', nombreCuenta: 'Activos Fijos' },
              { id: 'Activos Intangibles', nombreCuenta: 'Activos Intangibles' }
            ]}
            value={selectedType}
            onChange={handleTypeSelect}
            disabled={data.validation === -5}
          />
          <CustomSelect
            label='Clasificador'
            name='idCuentaClasificador'
            options={getFilteredOptions()}
            value={selectedClasificador || ''}
            onChange={handleClasificadorSelect}
            disabled={data.validation === -5}
          />
        </>
      )}
    </>
  )

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked)
  }

  const handleOpenModal = () => {
    setOpenModalCreate(true)
  }
  
  return (
    <div>
      <Loader visible={isLoaderVisible} />
      <Fragment>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          maxWidth='lg'
          scroll='body'
          PaperProps={{sx: {backgroundColor: '#f2f2f2'}}}
        >
          <DialogContent>
            <div className='bg-[#f2f2f2] flex justify-end items-center'>
              <button type='button' onClick={handleClose}>
                <ClearIcon sx={{ fontSize: 40 }} />
              </button>
            </div>
            <div>
              <h1 className='text-lg font-semibold mb-7'>{title}</h1>
              <section className='flex flex-col bg-white shadow-lg px-4 py-2 rounded-lg gap-5'>
                <section className='flex justify-between items-center gap-60'>
                  <div className='flex items-start gap-10'>
                    <div>
                      <div className='flex justify-center items-center'>
                        <img className='w-5 h-5' src={isologo_SII} alt='' />
                        <p className='text-m font-bold uppercase'>
                          {type === 'honorario' ? data.nombreCuenta : data.nombreCodigoDocumento}
                          {type === 'honorario' ? data.codigoCuenta : `(${data.codigoDocumento})`}
                        </p>
                      </div>
                      {type !== 'honorario' && (
                        <p className='text-sm font-bold uppercase pl-8'>folio {data.folio}</p>
                      )}
                    </div>
                    <div className='flex justify-center items-center gap-2'>
                      <img className='w-8 h-8' src={iconCalendario} alt='' />
                      <p className='text-sm font-bold uppercase text-center'>
                        {formatDate(data.fecha)}
                      </p>
                    </div>
                  </div>
                  <div className='flex flex-col items-end pt-2'>
                    <p className='text-lg font-bold uppercase'>{formatCurrency(data.montoTotal)}</p>
                    <Text className='text-sm  uppercase text-accent3'>
                      {type === 'honorario' ? 'retenido:' : 'iva:'}
                      {formatCurrency(data.montoIva)}
                    </Text>
                    <Text className='text-sm'>
                      {type !== 'honorario' && `Neto:${formatCurrency(data.montoNeto)}`}
                    </Text>
                  </div>
                </section>
                <section className='flex justify-between items-center gap-96'>
                  <div>
                    <p className='text-sm font-bold uppercase'>{data.razonSocial}</p>
                    <p className='text-sm font-bold uppercase'>{data.rut}</p>
                  </div>
                  <Text
                    className={
                      status === 'POR PAGAR' ? 'text-accent3 font-bold' : 'text-accent2 font-bold'
                    }
                  >
                    {status}
                  </Text>
                </section>
              </section>
              <section className='flex px-6 py-4 justify-between gap-6 mt-8'>
                <div className='flex justify-stretch'>
                  <div>
                  <ClassificationSection
    type={type}
    data={{
      idCuentaVenta: selectedCuentaVenta,
      idCuentaClasificador: selectedClasificador,
      validation: data.validation
    }}
    dataClasificacion={getFilteredOptions()}
    onOptionSelect={handleOptionSelect}
  />
                    <div className='flex items-center my-3 gap-2'>
                      <select
                        className='w-80 h-9 appearance-none focus:outline-none focus:ring-0 block border border-gray-300 hover:border-gray-400 focus:border-gray-400 px-4 py-2 pr-8 rounded-full shadow leading-tight '
                        name='validation'
                        id='validation'
                        value={data.validation || ''}
                        onChange={e => handleOptionSelect(e)}
                        disabled={data.validation === -5}
                      >
                        <option value="" disabled>
                          Seleccione temporalidad de la nueva clasificación
                        </option>
                        <option value={1}>Aplicar sólo a este registro</option>
                        <option value={2}>Recordar esta selección en el futuro</option>
                        <option value={3}>Aplicar esta selección a todos los registros</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className='flex flex-col gap-5 py-6'>
                  <div className='flex justify-between items-center'>
                    <FilterDateControl
                      label='Fecha Vencimiento'
                      name='fechaVencimiento'
                      value={formatDateToShow(data.fechaVencimiento)}
                      onChange={e => handleOptionSelect(e)}
                      disabled={data.validation === -5}
                    />
                  </div>
                  {/* <div className='flex justify-between'> */}
                  <div className='flex flex-col justify-between'>
                  </div>
                  {type === 'venta' ? (
                  <div className='flex gap-2 items-center'>
                    <label htmlFor='pagado' className='text-sm text-gray-700'>
                      COBRADO
                    <input type='checkbox' id='pagado' className='ml-2 h-4 w-4 rounded border-gray-300' onChange={handleCheckboxChange} checked={isChecked}/>
                    </label>
                    <CustomButton title='Seleccionar Pago' className='rounded-full ml-auto' color='primary' onClick={handleOpenModal} disabled={!isChecked}/>
                  </div>
                   ) : ( 
                    <div className='flex flex-col gap-2'>
                      <div className='flex items-center gap-2'>
                        <label htmlFor='pagado' className='text-sm text-gray-700 flex items-center gap-2'>
                          NO CONSIDERAR
                          <input type='checkbox' id='noConsiderar' className='h-4 w-4 rounded border-gray-300' name='validation' onClick={handleNoConsiderar} value={-5} checked={data.validation === -5}/>
                        </label>
                      </div>
                      <div className='flex items-center gap-2'>
                        <label htmlFor='pagado' className='text-sm text-gray-700 flex items-center gap-2'>
                          PAGADO
                          <input type='checkbox' id='pagado' className='h-4 w-4 rounded border-gray-300' onChange={handleCheckboxChange} checked={isChecked}/>
                        </label>
                        <CustomButton title='Seleccionar Pago' className='rounded-full ml-auto' color='primary' onClick={handleOpenModal} disabled={!isChecked}/>
                      </div>
                    </div>
                  )}
                  <div className='flex flex-col'>
                    <label htmlFor='comentario' className='text-sm'>Comentarios</label>
                    <textarea
                      className='rounded-lg'
                      id='comentario'
                      rows='4'
                      cols='50'
                      value={comentario}
                      onChange={handleComentario}
                      disabled={data.validation === -5}
                    ></textarea>
                    {errorComentario && <span style={{ color: 'red' }}>{errorComentario}</span>}
                    <p>{comentario.length}/40</p>
                  </div>
                </div>
              </section>
            </div>
            <div className='flex justify-end gap-3 mr-16 mb-5'>
              <Button
                onClick={handleClose}
                className='uppercase text-sm bg-primary text-white rounded-full'
                title='cancelar'
              />
              <Button
                disabled={data?.validation || switchDate ? false : true}
                onClick={handleSubmit}
                className={`uppercase text-sm text-white rounded-full ${
                  data?.validation || switchDate ? 'bg-accent2 ' : 'bg-[#00000030]'
                }`}
                title='guardar cambios'
              />
            </div>
          </DialogContent>
        </Dialog>
      </Fragment>
      <Modal open={modalOpen} setOpen={setModalOpen} />
      {openModalCreate && (
        <ModalAdmVinculacion
          open={openModalCreate}
          setOpen={setOpenModalCreate}
          type={type}
          idLibro={idLibro}
        />
      )}
    </div>
  )
}

export default ModalDialogMotionEditForm