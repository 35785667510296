const Modal = ({ show, handleClose, children, title }) => {
  const clickOutside = (event) => {
    if (event.currentTarget !== event.target) return;
    handleClose();
  }

  if (!show) return <div className="hidden" />

  return (
    <div
      className="fixed top-0 left-0 z-40 h-full w-full cursor-default bg-transparent-black"
      onClick={(event) => clickOutside(event)}
      role="button"
      tabIndex={0}
    >
      <div
        className="fixed inset-0 m-auto h-fit max-h-[90vh] min-h-[9rem] w-fit
        min-w-[12rem] max-w-[90vw] overflow-auto rounded-xl bg-white p-4"
      >
        <div className="flex justify-between gap-6">
          <div className="mb-4 text-xl font-bold">{title}</div>
          <div className="text-xl" onClick={handleClose} role="button" tabIndex={0}
          >
            x
          </div>
        </div>
        {children}
      </div>
    </div>
  )
}

export default Modal
