import { useState, useContext, useEffect, useMemo } from 'react'
import { HeadingTitle, Paper} from '../../../components/modules/ui/index'
import CustomButton from '../../../components/modules/ui/CustomButton'
import Table from './Table'
import GlobalContext from '../../../context/global-context'
import { BusinessAPI } from '../../../api/business.api'
import FilterDateControl from '../../../components/modules/table/FilterDateControl'
import dayjs from 'dayjs'
import { ExportCSV, ExportXLS } from '../../../components/modules/table/ExportData'
import ExportPDF from '../../../components/modules/table/typesExportPDF/ExportPDFLibroAuxiliar'
import { formatDate } from '../../../components/utils/formatter'
import SearchFilter from '../../../components/modules/ui/SearchFilter'

const ListLibroAuxiliarCliente = () => {
    const [, setMonthFilter] = useState(dayjs().format('MM'))
    const [yearFilter, setYearFilter] = useState(dayjs().format('YYYY'))
  
    const [filters] = useState({
      fechaInicio: dayjs().startOf('month').format('YYYY-MM-DD'),
      fechaFin: dayjs().format('YYYY-MM-DD'),
    })
    const [tempFilters, setTempFilters] = useState({ ...filters })
    const { ui, currentBusiness } = useContext(GlobalContext)
    const businessAPI = new BusinessAPI()
    const [data, setData] = useState([])
    //const [searchQuery, setSearchQuery] = useState('')
    const [filterValues, setFilterValues] = useState({
      montoDesde: '',
      montoHasta: '',
      descripcion: ''
    })

    /*const monthsOptions = months.map(monthOption => ({
        ...monthOption,
        disabled: yearFilter == currentYear && monthOption.value > currentMonth
    }))*/

    const handleDateChange = (key, newValue) => {
      const newDate = dayjs(newValue)
      setTempFilters((prev) => ({
        ...prev,
        [key]: newDate.format('YYYY-MM-DD'),
      }))
      
      // Solo actualizar month y year cuando cambia la fecha de inicio
      if (key === "fechaInicio") {
        setMonthFilter(newDate.format("MM"))
        setYearFilter(newDate.format("YYYY"))
      }
    }
        

    const getData = async () => {
      const selectedMonth = dayjs(tempFilters.fechaInicio).format("MM")
      const selectedYear = dayjs(tempFilters.fechaInicio).format("YYYY")
      
      if (currentBusiness.id) {
        ui.setLoader({ visible: true, text: 'Cargando los datos de la tabla...' })
        await businessAPI
          .getLibroClienteProveedor({
            businessId: currentBusiness.id,
            type: 'cliente',
            month: selectedMonth,
            year: selectedYear,
            max: 10000
          })
          .then(({ data }) => {
            setData(data)
          })
          .catch(error => {
            setData([])
            console.error(error)
          })
          .finally(() => {
            ui.setLoader({ visible: false, text: '' })
          })
      } else {
        console.error('No hay un negocio seleccionado')
      }
    }

    useEffect(() => {
      getData()
    }, [currentBusiness])

    const dataToExport = data.map(item => {
      return {
        Rut: item.rut,
        Cliente: item.razonSocial,
        Fecha: formatDate(item.fecha),
        Vencimiento: formatDate(item.fechaVencimiento),
        Comprobante: item.comprobante,
        'Tipo Documento': item.tipoComprobante,
        Documento: item.folio,
        Debe: item.debe.toLocaleString('es-CL'),
        Haber: item.haber.toLocaleString('es-CL'),
        Saldo: item.saldo.toLocaleString('es-CL')
      }
    })
      
    const icons = [
    <ExportCSV
      key='export-csv'
      currentBusiness={currentBusiness.name}
      rut={currentBusiness.rut}
      data={dataToExport}
      title='Reportes libro auxiliar de cliente'
      year={yearFilter}
    />,
    <ExportXLS
      key='export-xls'
      currentBusiness={currentBusiness.name}
      rut={currentBusiness.rut}
      data={dataToExport}
      title='Reportes libro auxiliar de cliente'
      year={yearFilter}
    />,
    <ExportPDF
      key='export-pdf'
      currentBusiness={currentBusiness.name}
      rut={currentBusiness.rut}
      data={data}
      title='Reportes libro auxiliar de cliente'
      year={yearFilter}
    />
  ]

  /*const filteredData = data.filter(item =>
    item.rut?.toLowerCase().includes(searchQuery.toLowerCase()) ||
    item.razonSocial?.toLowerCase().includes(searchQuery.toLowerCase())
  )*/

  const filteredData = useMemo(() => {
      let filtered = data
      if (filterValues.descripcion) {
        const searchLower = filterValues.descripcion.toLowerCase()
        filtered = filtered.filter(item =>
          item.rut?.toLowerCase().includes(searchLower) ||
          item.razonSocial?.toLowerCase().includes(searchLower)
        )
      }
      if (filterValues.montoDesde) {
        filtered = filtered.filter(item => item.montoTotal >= Number(filterValues.montoDesde))
      }
      if (filterValues.montoHasta) {
        filtered = filtered.filter(item => item.montoTotal <= Number(filterValues.montoHasta))
      }
      return filtered
    }, [data, filterValues])

    const handleFilterChange = (key, value) => {
      setFilterValues(prev => ({ ...prev, [key]: value }))
    }

    const handleClearFilters = () => {
      setFilterValues({
        montoDesde: '',
        montoHasta: '',
        descripcion: ''
      })
    }

    return (
        <>
        <Paper>
            <HeadingTitle title='Libro Auxiliar de Cliente' rightIcons={icons} iconHeight={32}/>
        <div>
          <div className='flex justify-between items-end'>
            <div className='flex gap-2 items-end'>
              <FilterDateControl
                label='Fecha Desde'
                value={tempFilters.fechaInicio}
                onChange={(newValue) => handleDateChange("fechaInicio", newValue)}
              />
              <FilterDateControl
                label='Fecha Hasta'
                value={tempFilters.fechaFin}
                onChange={(newValue) => handleDateChange("fechaFin", newValue)}
              />
              <CustomButton className='rounded-full' color='primary' title='Buscar' onClick={getData} />
              <CustomButton className='rounded-full' color='accent1' title='Limpiar' onClick={handleClearFilters}/>
            </div>
            <div className='flex flex-col'>
              <SearchFilter
                value={filterValues.descripcion}
                onChange={(e) => handleFilterChange('descripcion', e.target.value)}
                filterValues={filterValues}
                onFilterChange={handleFilterChange}
              />
              <label className='mt-2'>
                <input className='mr-2 rounded-sm' type='checkbox' />
                  Proveedor por pagar
              </label>
            </div>
          </div>
        </div>
        <Table rows={filteredData} getData={getData} />
      </Paper>
        </>
    )
    }

export default ListLibroAuxiliarCliente
