import Loader from './Loader'

const Button = ({ submit, children, className, onClick, disabled, loading, color='primary' }) => {
  const colorClasses = () => {
    if (disabled) return 'bg-gray cursor-not-allowed'
    if (color === 'secondary') return 'bg-secondary hover:bg-secondary-dark'

    return 'bg-primary hover:bg-primary-dark'
  }

  return (
    <button
      type={submit ? 'submit' : 'button'}
      className={`flex h-10 items-center
        justify-center gap-3 rounded-lg p-2 font-bold
        text-white ${className} ${colorClasses()}`}
      onClick={onClick}
      disabled={disabled}
    >
      {children && (<div>{loading ? <Loader size='sm' color='border-white'/>: children}</div>)}
    </button>
  )
}

export default Button
