import { useContext, useState } from 'react'
import { useForm } from 'react-hook-form'

import Button from '../shared/Button'
import Modal from '../shared/Modal'
import Input from '../shared/Input'
import ConversationsApi from '../../../../../api/seicho/conversations.api'
import { ToastContext } from '../../../../../context/ToastContext'


const EditConversationModal = ({ open, onClose, setConversations, conversation }) => {
  const { register, handleSubmit, formState: { errors, isDirty }, reset } = useForm({
    defaultValues: {
      name: conversation.name
    }
  })

  const [loading, setLoading] = useState(false)
  const { showToast } = useContext(ToastContext)


  const handleEditConversation = async (newConversationData) => {
    setLoading(true)

    try {
      await ConversationsApi.updateConversation(conversation.id, newConversationData)
      setConversations(
        (conversations) => (
          conversations.map((c) => c.id === conversation.id ? { ...c, ...newConversationData } : c)
        )
      )

      showToast('success', 'Conversación actualizada correctamente')
      onClose()
    } catch {
      showToast('error', 'Error al actualizar la conversación')
    } finally {
      setLoading(false)
    }
  }

  const handleClose = () => {
    reset()
    onClose()
  }

  return (
    <Modal
      show={open}
      handleClose={handleClose}
      title='Editar nombre de la conversación'
    >
      <form onSubmit={handleSubmit(handleEditConversation)} className="flex flex-col gap-4">
        <Input
          label="Nombre"
          type="text"
          registerProps={register('name', { required: true })}
          error={errors.name}
          disabled={loading}
        />
        <div className="flex justify-end">
          <Button submit className="w-max bg-primary" disabled={!isDirty || loading}>
            Actualizar
          </Button>
        </div>
      </form>
    </Modal>
  )
}

export default EditConversationModal
