import { useState } from 'react'
import QuestionIcon from '@mui/icons-material/HelpOutline'

const TooltipIcons = ({ tooltipText }) => {
  const [showTooltip, setShowTooltip] = useState(false)

  return (
    <div className='relative flex items-center'>
      <div
        className='cursor-pointer w-6 h-6 text-gray-400'
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
      >
        <QuestionIcon />
      </div>
      {showTooltip && tooltipText && (
        <div className='absolute left-1/2 -translate-x-1/2 bottom-full mb-2 bg-black text-white text-xs rounded p-2 shadow-md w-48'>
          {tooltipText}
        </div>
      )}
    </div>
  )
}

export default TooltipIcons
