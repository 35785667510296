//components
import { useEffect, useState } from 'react'
import Chat from '../components/modules/views/contableAgent/Chat/Chat'
import Conversations from '../components/modules/views/contableAgent/Conversations/Conversations'
import ConversationsApi from '../api/seicho/conversations.api'
import Layout from '../components/layouts/index'

/**
 *
 * @returns {JSX.Element}
 */
export default function ContableAgent() {
  const [activeTab, setActiveTab] = useState('chat')
  const [conversations, setConversations] = useState([])
  const [activeConversation, setActiveConversation] = useState({ id: null, messages: [] })
  const [favoritesConversations, setFavoritesConversations] = useState([])

  useEffect(() => {
    const fetchConversations = async () => {
      const response = await ConversationsApi.getConversations()
      setConversations(response)
    }

    fetchConversations()
  }, [])

  useEffect(() => {
    const favoritesConversations = conversations.filter((conversation) => conversation.isFavorite)
    setFavoritesConversations(favoritesConversations)
  }, [conversations])

  const activeTabClasses = (tab) => (
    activeTab === tab ? 'font-bold absolute top-0.5 border-b-2 border-accent-secondary z-10' : ''
  )

  const handleSelectConversation = (conversation) => {
    setActiveTab('chat')
    setActiveConversation(conversation)
  }

  const renderContent = () => {
    switch (activeTab) {
      case 'chat':
        return (
          <Chat
            conversation={activeConversation}
            setConversations={setConversations}
            setConversation={setActiveConversation}
          />
        )
      case 'history':
        return (
          <Conversations
            conversations={conversations}
            title='Historial de Chats'
            handleSelectConversation={handleSelectConversation}
            setConversations={setConversations}
          />
        )
      case 'favorites':
        return (
          <Conversations
            conversations={favoritesConversations}
            title='Destacados'
            handleSelectConversation={handleSelectConversation}
            setConversations={setConversations}
          />
        )
      default:
        return null
    }
  }

  return (
    <Layout>
      <div className="flex flex-col h-[calc(100vh-5rem)] p-4">
        <div className="flex border-b-2 border-gray relative text-base">
          <span
            className={`cursor-pointer pb-2 px-2 relative  ${activeTabClasses('chat')}`}
            onClick={() => setActiveTab('chat')}
          >
            Chat Principal
          </span>
          <span
            className={`cursor-pointer pb-2 px-2 relative ${activeTabClasses('history')}`}
            onClick={() => setActiveTab('history')}
          >
            Historial de Chats
          </span>
          <span
            className={`cursor-pointer pb-2 px-2 relative ${activeTabClasses('favorites')}`}
            onClick={() => setActiveTab('favorites')}
          >
            Destacados
          </span>
        </div>
        <div className="flex-grow mt-4 overflow-hidden">
          {renderContent()}
        </div>
      </div>
    </Layout>
  )
}
