export const parseDate = (dateString) => {
  if (!dateString) return ''

  const dateObj = new Date(dateString)
  const day = String(dateObj.getDate()).padStart(2, '0')
  const month = String(dateObj.getMonth() + 1).padStart(2, '0')
  const year = dateObj.getFullYear()
  const hour = String(dateObj.getHours()).padStart(2, '0')
  const minutes = String(dateObj.getMinutes()).padStart(2, '0')
  const seconds = String(dateObj.getSeconds()).padStart(2, '0')

  return `${day}/${month}/${year} ${hour}:${minutes}:${seconds}`
}
