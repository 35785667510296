
import Slider from '@mui/material/Slider'
import { useContext, useEffect, useState } from 'react'
import TablaAdmVinculacion from './TableAdmVinculacion'
import CustomButton from '../ui/CustomButton'
import { Box, Dialog, DialogActions, DialogContent, IconButton } from '@mui/material'
import { Title } from '../ui/index'
import ArrowIcon from '@mui/icons-material/KeyboardArrowUpOutlined'
import DownIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import GlobalContext from '../../../context/global-context'
import { OrganizationAPI } from '../../../api/organization.api'

const InputField = ({ label, placeholder, type }) => (
  <div>
    <label className='block text-sm font-medium text-gray-700'>{label}</label>
    <input
      type={type}
      placeholder={placeholder}
      className='mt-1 block w-full rounded-full border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
    />
  </div>
)

const ModalAdmVinculacion = ({ open, setOpen, type, idLibro }) => {
  const [value, setValue] = useState([20, 50000])
  const [showFilters, setShowFilters] = useState(true)
  const [isAnyCheckboxSelected, setIsAnyCheckboxSelected] = useState(false)
  const [data, setData] = useState({})
  const { currentBusiness } = useContext(GlobalContext)
    const organizationAPI = new OrganizationAPI()

  const handleClose = () => setOpen(false)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const handleCheckboxChange = (isSelected) => {
    setIsAnyCheckboxSelected(isSelected)
  }

  const functionGetData = async () => {
    try {
      const response = await organizationAPI.getListaParaVincular({
        businessId: currentBusiness.id, 
        idLibro})
      setData(response.data)
    } catch (error) {
      console.error(error)
    } finally {
      //aaa
    }
  }

  useEffect(() => {
      functionGetData()
    }, [])

  return (
    <Dialog
      open={open}
      keepMounted
      onClose={handleClose}
      maxWidth='lg'
      scroll='body'
      PaperProps={{
        sx: {
          backgroundColor: '#f2f2f2',
          borderRadius: '8px',
          padding: '16px',
        },
      }}
    >
      <DialogContent>
      <div className='relative w-full flex justify-end'>
        <IconButton
          onClick={() => setShowFilters(!showFilters)}
        >
          {showFilters ? <ArrowIcon /> : <DownIcon />}
        </IconButton>
      </div>
      {type === 'venta' ? (<Title className='text-3xl mb-4'>Movimientos por Abono</Title>) : (<Title className='text-3xl mb-4'>Movimientos por Carga</Title>)}
        {showFilters && (
          <div className='p-4 bg-white rounded-lg shadow-md mb-10'>
            <div className='grid grid-cols-3 gap-4'>
              <InputField label='Palabra Clave' placeholder='LIPSUM' type='text' />

              <div>
                <label className='block text-sm font-medium text-gray-700'>Fecha</label>
                <div className='flex gap-2'>
                  <input
                    type='date'
                    className='mt-1 block w-full rounded-full border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                  />
                  <input
                    type='date'
                    className='mt-1 block w-full rounded-full border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                  />
                </div>
              </div>

              <div>
                <label className='block text-sm font-medium text-gray-700'>Monto</label>
                <Box sx={{ width: '100%' }}>
                  <div className='flex justify-between text-sm text-gray-500 mb-1'>
                    <span>${value[0].toLocaleString()}</span>
                    <span>${value[1].toLocaleString()}</span>
                  </div>
                  <Slider
                    getAriaLabel={() => 'Monto range'}
                    value={value}
                    onChange={handleChange}
                    valueLabelDisplay='off'
                    min={0}
                    max={500000}
                  />
                </Box>
              </div>
              <div>
                <label className='block text-sm font-medium text-gray-700'>Banco</label>
                <select
                  className='mt-1 block w-full rounded-full border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                  onChange={(e) => console.log(e.target.value)}
                  defaultValue=''
                >
                  <option value='' disabled>
                    Selecciona un banco
                  </option>
                  <option value='caja-principal'>Caja Principal</option>
                </select>
              </div>
              <InputField label='Contraparte' placeholder='Inversiones Ultramar Ltda.' type='text' />
              <InputField label='RUT' placeholder='11.111.111-1' type='text' />
            </div>
          </div>
        )}
        <Title className='text-2xl mb-4'>Sugerencias</Title>
        <div className='p-4 rounded-lg shadow-md bg-white'>
          <div className='overflow-x-auto'>
            <TablaAdmVinculacion onCheckboxChange={handleCheckboxChange} rows={data}/>
          </div>
        </div>
      </DialogContent>

      <DialogActions>
        <CustomButton color='accent2' className='rounded-full' title='CONCILIAR MOVIMIENTOS' disabled={!isAnyCheckboxSelected}/>
        <CustomButton color='primary' onClick={handleClose} className='rounded-full' title='CANCELAR' />
      </DialogActions>
    </Dialog>
  )
}

export default ModalAdmVinculacion
