import { useState } from 'react'
import { IconosPlan } from './IconosPlantilla'
import ModalVinculacionGenerica from './modalDialogVinculacionGenerica/ModalVinculacionGenerica'
import PagoArriendos from './PagoArriendos/PagoArriendos'

const Plantillas = () => {
    const [openModal, setOpenModal] = useState(false)
    const [openModalArriendo, setOpenModalArriendo] = useState(false)
    const [templates] = useState(
      IconosPlan.map((icono) =>
        icono.id === 1 ? { ...icono, isFavorite: true } : { ...icono, isFavorite: false }
      )
    )
  
    const nonFavoriteTemplates = templates.filter((template) => !template.isFavorite)

    const handleIconClick = (id) => {
      if (id === '1') {
        setOpenModal(true)
      }
      if (id === '5') {
        setOpenModalArriendo(true)
      }
    }
  
    const renderTemplate = (template) => (
      <div
        key={template.id}
        className="flex flex-col items-center justify-center shadow-md border rounded-xl 
                   p-4 cursor-pointer w-24 h-24 md:w-28 md:h-28 aspect-square"
        onClick={() => handleIconClick(template.id)}
      >
        <template.icon className="w-10 h-10 md:w-12 md:h-12 my-2" />
        <h3 className="text-sm font-semibold text-center">{template.title}</h3>
      </div>
    )
  
    return (
      <>
          <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-6 gap-6 justify-center w-full max-w-[1100px]">
            {nonFavoriteTemplates.map(renderTemplate)}
          </div>
        {openModal && (
            <ModalVinculacionGenerica 
                open={openModal}
                setOpen={setOpenModal}
            />
        )}
        {openModalArriendo && (
            <PagoArriendos
                open={openModalArriendo}
                setOpen={setOpenModalArriendo}
            />
        )}
      </>
    )
  }
  

export default Plantillas
