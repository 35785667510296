import { TextField } from '@mui/material'
import { useState, useEffect, useContext } from 'react'
import { auxiliarCols as cols} from '../../../../data/headersTables'
import iconClip from '../../../../../../assets/iconos/image.png'
import { OrganizationAPI } from '../../../../../../api/organization.api'
import GlobalContext from '../../../../../../context/global-context'
import Loader from '../../../../ui/Loader'

const TablaAuxiliar = ({ setListaAuxiliar, razonSocial, rut, nombreComprobante, vencimiento, folio, createUserId, validation }) => {
  const [rows, setRows] = useState([
    { 
      razonSocial: razonSocial || '', 
      rut: rut || '', 
      nombreComprobante: nombreComprobante || '', 
      fechaVencimiento: vencimiento || '', 
      folio: folio || '',
      isEditable: !(razonSocial || rut || nombreComprobante || vencimiento || folio)
    }
  ])
  const organizationAPI = new OrganizationAPI()
  const { currentBusiness } = useContext(GlobalContext)
  const [dataList, setDataList] = useState([])
  const [filteredOptions, setFilteredOptions] = useState([])
  const [activeRowIndex, setActiveRowIndex] = useState(null)
  const [activeField, setActiveField] = useState('')
  const [isLoaderVisible, setIsLoaderVisible] = useState(false)
  const isDisabled = (row) => {
    if (validation === 1) {
        return createUserId === null
    }
    if (validation === -6) {
        return false
    }
    return !row.isEditable
  }
  
  const handleChange = (index, field, value) => {
    const updatedRows = [...rows]
    updatedRows[index][field] = value
  
    if (field === 'razonSocial' || field === 'rut') {
      const filtered = dataList.filter((item) =>
        item[field]?.toLowerCase().includes(value.toLowerCase())
      )
      setFilteredOptions(filtered)
      setActiveField(field)
    }
  
    setActiveRowIndex(index)
    setRows(updatedRows)
    setListaAuxiliar(updatedRows)
  }

  const handleSelect = (index, selectedOption) => {
    const updatedRows = [...rows]
    updatedRows[index] = {
      ...updatedRows[index],
      razonSocial: selectedOption.razonSocial,
      rut: selectedOption.rut,
    }
    setRows(updatedRows)
    setFilteredOptions([])
    setActiveRowIndex(null)
    setActiveField('')
    setListaAuxiliar(updatedRows)
  }

  const functionGetDataList = async () => {
    try {
      setIsLoaderVisible(true)
      const response = await organizationAPI.getListRutName(currentBusiness.id)
      setDataList(response.data)
    } catch (error) {
      console.Console.error(error)
    } finally {
      setIsLoaderVisible(false)
    }
  }

  useEffect(() => {
    functionGetDataList()
  }, [])

  const FormAdjuntar = () => (
    <div className=''>
      <label
        htmlFor='file-upload'
        className='custom-file-upload cursor-pointer shadow-4xl border-none rounded-2xl w-auto h-auto bg-white flex flex-col items-center justify-center'
      >
        <img className='w-6 h-6' src={iconClip} />
      </label>
      <input id='file-upload' type='file' className='hidden' />
    </div>
  )

  return (
    <div >
      <Loader visible={isLoaderVisible} />
      <table className='min-w-full table-fixed border-collapse text-sm text-left'>
        <thead>
          <tr className='p-2 text-center text-xs'>
            {cols?.map((col, i) => (
              <th className='border' key={i}>
                {col}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
        {rows.map((row, index) => (
            <tr key={index} className='border-b'>
              <td className='py-2 px-2 border'>
                <div className='relative'>
                  <TextField
                    name='razonSocial'
                    variant='standard'
                    value={row.razonSocial}
                    onChange={(e) => handleChange(index, 'razonSocial', e.target.value)}
                    disabled={isDisabled(row)}
                  />
                  {index === activeRowIndex && activeField === 'razonSocial' && filteredOptions.length > 0 && (
                    <ul className='absolute z-10 bg-white border rounded w-full mt-1'>
                      {filteredOptions.map((option, i) => (
                        <li
                          key={i}
                          className='cursor-pointer p-2 hover:bg-gray-200'
                          onClick={() => handleSelect(index, option)}
                        >
                          {option.razonSocial}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </td>
              <td className='py-2 px-2 border'>
                <div className='relative'>
                  <TextField
                    name='rut'
                    variant='standard'
                    value={row.rut}
                    onChange={(e) => handleChange(index, 'rut', e.target.value)}
                    disabled={isDisabled(row)}
                  />
                  {index === activeRowIndex && activeField === 'rut' && filteredOptions.length > 0 && (
                    <ul className='absolute z-10 bg-white border rounded w-full mt-1'>
                      {filteredOptions.map((option, i) => (
                        <li
                          key={i}
                          className='cursor-pointer p-2 hover:bg-gray-200'
                          onClick={() => handleSelect(index, option)}
                        >
                          {option.rut}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </td>
              <td className='py-2 px-2 border'>
                <TextField
                  name='tipoDocumento'
                  variant='standard'
                  value={row.nombreComprobante || ''} 
                  onChange={(e) => handleChange(index, 'nombreComprobante', e.target.value)}
                  disabled={isDisabled(row)}
                />
              </td>
                <td className='py-2 px-2 border'>
                  <TextField
                    variant="standard"
                    type="date"
                    value={
                      row.fechaVencimiento && typeof row.fechaVencimiento === 'string'
                        ? row.fechaVencimiento.split('-').reverse().join('-') // Convertimos de "dd-MM-yyyy" a "yyyy-MM-dd"
                        : '' // Si no es un string o está vacío, mostramos ''
                    }
                    onChange={(e) => {
                      const [year, month, day] = e.target.value.split('-'); // Input date siempre devuelve "yyyy-MM-dd"
                      const updatedRows = [...rows];
                      updatedRows[index].fechaVencimiento = `${day}-${month}-${year}`; // Convertimos a "dd-MM-yyyy"
                      setRows(updatedRows);
                      setListaAuxiliar(updatedRows);
                    }}
                    disabled={isDisabled(row)}
                  />
                </td>
                <td className='py-2 px-2 border'>
                  <TextField
                    name='folio'
                    variant='standard'
                    value={row.folio}
                    onChange={(e) => handleChange(index, 'folio', e.target.value)}
                    disabled={isDisabled(row)}
                  />
                </td>
                <td className='py-2 px-2 border'>
                  <FormAdjuntar  disabled={isDisabled(row)}/>
                </td>
            </tr>
        ))}
        </tbody>
      </table>
    </div>
  )
}

export default TablaAuxiliar
