import { useEffect, useState } from 'react'

import SearchIcon from '../../../../../assets/search.svg'
import { parseDate } from '../../../../utils/date'


const SearchBar = ({ conversations, setFilteredConversations }) => {
  const [searchValue, setSearchValue] = useState('')

  const handleSearch = (event) => {
    const value = event.target.value
    setSearchValue(value)
    const filteredConversations = conversations.filter((conversation) => (
      conversation.name.toLowerCase().includes(value.toLowerCase()) ||
      parseDate(conversation.lastInteraction).includes(value.toLowerCase())
    ))
    setFilteredConversations(filteredConversations)
  }

  useEffect(() => {
    setSearchValue('')
    setFilteredConversations(conversations)
  }, [conversations, setFilteredConversations])

  return (
    <div className="flex w-80 border border-gray rounded-full">
      <input
        value={searchValue}
        onChange={handleSearch}
        className="w-full mx-4 focus:outline-none focus:ring-0 text-base py-0.5 bg-slate-100/70 border-none"
        placeholder="Buscar chat"
      />
      <img src={SearchIcon} className="w-4 h-4 ml-auto mr-2 mt-1" />
    </div>
  )
}

export default SearchBar
