import List from './List'

const index = () => {
  return (
    <>
        <List />
    </>
  )
}

export default index
