import { useContext, useState } from 'react'

import Modal from '../shared/Modal'
import Loader from '../shared/Loader'
import ConversationsApi from '../../../../../api/seicho/conversations.api'
import Button from '../shared/Button'
import { ToastContext } from '../../../../../context/ToastContext'

const DeleteConversation = ({ open, onClose, setConversations, conversation }) => {
  const [loading, setLoading] = useState(false)
  const { showToast } = useContext(ToastContext)

  const handleDeleteConversation = async () => {
    setLoading(true)

    try {
      await ConversationsApi.deleteConversation(conversation.id)
      setConversations((conversations) => conversations.filter((c) => c.id !== conversation.id))

      showToast('success', 'Conversación eliminada correctamente')
      onClose()
    } catch {
      showToast('error', 'Error al eliminar la conversación')
    } finally {
      setLoading(false)
    }
  }

  return (
    <Modal
      show={open}
      handleClose={onClose}
      title='Eliminar conversación'
    >
      Confirma que deseas eliminar la conversación&nbsp;
      <strong className='text-primary italic'>{conversation.name}</strong>
      <div className='ml-auto mt-8 flex flex-col items-end'>
        <Button className='h-10 w-20 bg-accent-secondary' onClick={handleDeleteConversation}>
          {loading ? <Loader size='sm' color='border-white'/>: 'Eliminar'}
        </Button>
      </div>
    </Modal>
  )
}

export default DeleteConversation
