import clsx from 'clsx'
import { Text } from '../../../components/modules/ui'

// Función para formatear el mes
function formatMonth(m) {
  const monthNames = [
    "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
    "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
  ];
  // Se asume que el formato es "YYYY-MM"
  const parts = m.split("-");
  if (parts.length === 2) {
    const monthIndex = parseInt(parts[1], 10) - 1;
    return monthNames[monthIndex] || m;
  }
  return m;
}

export default function TableExtendPivotSoloSaldo({ rows }) {
  if (!rows || !rows.length) {
    return <Text className='grid place-items-center py-12'>No hay datos</Text>
  }

  const { pivotedData, uniqueMonths } = pivotData(rows);

  return (
    <div className='overflow-x-auto'>
      <table className='w-full text-xs border-collapse leading-6 table-auto'>
        <thead>
          <tr className='bg-gray-100'>
            <th className='p-4 text-left'>Cuenta</th>
            {uniqueMonths.map(m => (
              <th key={m} className='p-4 text-center'>
                {formatMonth(m)}
              </th>
            ))}
            <th className='p-4 text-center'>Acumulado</th>
          </tr>
        </thead>

        <tbody>
          {pivotedData.map((item, index) => {
            const rowClasses = item.isGroup ? 'bg-gray-50 font-bold' : ''
            return (
              <tr key={index} className={clsx('hover:bg-gray-100/50', rowClasses)}>
                <td className='p-2 text-left'>
                  {item.codeCuenta} {item.nameCuenta}
                </td>
                {uniqueMonths.map(m => (
                  <td key={m} className='p-2 text-right'>
                    {(item.monthlyData[m] ?? 0).toLocaleString('es-CL')}
                  </td>
                ))}
                <td className='p-2 text-right'>
                  {item.finalAcumulado.toLocaleString('es-CL')}
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

function pivotData(rows) {
  const uniqueMonths = Array.from(new Set(rows.map(r => r.mes))).sort();
  const dataByCuenta = new Map();

  rows.forEach(item => {
    const key = item.codeCuenta;
    if (!dataByCuenta.has(key)) {
      dataByCuenta.set(key, {
        codeCuenta: item.codeCuenta,
        nameCuenta: item.nameCuenta,
        monthlyData: {},
        finalAcumulado: 0,
        isGroup: item.isGroup || false,
      });
    }
    const obj = dataByCuenta.get(key);
    obj.monthlyData[item.mes] = (obj.monthlyData[item.mes] ?? 0) + (item.saldoPeriodo ?? 0);
    obj.finalAcumulado += (item.saldoPeriodo ?? 0);
  });

  const pivotedData = Array.from(dataByCuenta.values());
  pivotedData.sort((a, b) => a.codeCuenta.localeCompare(b.codeCuenta));

  const totalRow = {
    codeCuenta: 'TOTAL',
    nameCuenta: '',
    monthlyData: {},
    finalAcumulado: 0,
    isGroup: true,
  };

  uniqueMonths.forEach(m => {
    totalRow.monthlyData[m] = 0;
  });

  pivotedData.forEach(item => {
    if (item.isGroup) {
      uniqueMonths.forEach(m => {
        totalRow.monthlyData[m] += (item.monthlyData[m] ?? 0);
      });
      totalRow.finalAcumulado += item.finalAcumulado;
    }
  });

  pivotedData.push(totalRow);

  return { pivotedData, uniqueMonths };
}
