import { useState } from 'react'
import clsx from 'clsx'
import { Text } from '../../../components/modules/ui'

// 1) Es Ganancia si es isGroup y codeCuenta inicia con '3-1' o '3-2-1'
function isGanancia(row) {
  if (!row.isGroup) return false
  const code = row.codeCuenta ?? ''
  return code.startsWith('3-1') || code.startsWith('3-2-1')
}

// 2) Es Pérdida si es isGroup y NO esGanancia
function isPerdida(row) {
  return row.isGroup && !isGanancia(row)
}

export default function Table({ rows }) {
  const [order, setOrder] = useState({
    col: 'Código Cuenta',
    field: 'codeCuenta',
    direction: 'desc',
  })

  const orderableCols = [
    { col: 'Código Cuenta', field: 'codeCuenta' },
    { col: 'Nombre Cuenta', field: 'nameCuenta' },
    { col: 'Saldo Periodo', field: 'saldoPeriodo' },
    { col: 'Saldo Acumulado', field: 'saldoAcumulado' },
  ]

  const handleSort = ({ col, field }) => {
    const isAsc = order.col === col && order.direction === 'asc'
    setOrder({ col, field, direction: isAsc ? 'desc' : 'asc' })
  }

  if (!rows?.length) {
    return <Text className='grid place-items-center py-12'>No hay datos</Text>
  }

  // === 3) Sumas de Ganancia / Pérdida (solo filas isGroup)
  const sumGananciaPeriodo = rows
    .filter(isGanancia)
    .reduce((acc, r) => acc + Number(r.saldoPeriodo ?? 0), 0)

  const sumGananciaAcumulado = rows
    .filter(isGanancia)
    .reduce((acc, r) => acc + Number(r.saldoAcumulado ?? 0), 0)

  const sumPerdidaPeriodo = rows
    .filter(isPerdida)
    .reduce((acc, r) => acc + Number(r.saldoPeriodo ?? 0), 0)

  const sumPerdidaAcumulado = rows
    .filter(isPerdida)
    .reduce((acc, r) => acc + Number(r.saldoAcumulado ?? 0), 0)

  // === 4) Resultado = Ganancias - Pérdidas
  const sumResultadoPeriodo = sumGananciaPeriodo + sumPerdidaPeriodo
  const sumResultadoAcumulado = sumGananciaAcumulado + sumPerdidaAcumulado

  // === 5) Construimos "displayRows"
  //    - Sin fila "GANANCIAS"
  //    - Al final, insertamos "RESULTADO"

  // Metemos cada fila real como { type: 'ROW', data: row }
  let displayRows = rows.map((row) => ({
    type: 'ROW',
    data: row,
  }))

  // Agregamos la fila "RESULTADO" al final
  displayRows.push({
    type: 'RESULTADO',
    periodo: sumResultadoPeriodo,
    acumulado: sumResultadoAcumulado,
  })

  // === 6) Estilos
  const styles = {
    table: 'w-full text-[#000231] text-xs border-collapse leading-6 table-auto',
    tr: 'hover:bg-gray-100/50',
    td: 'text-left align-top p-4 whitespace-nowrap overflow-x-auto no-scrollbar-space-y-1',
    th: 'border-2 border-transparent border-b-gray-200 text-left p-4 whitespace-nowrap bg-white',
    thead: 'sticky top-0 z-10',
    tbody: 'overflow-y-auto',
    boldText: 'font-bold',
  }

  return (
    <div className='overflow-x-auto'>
      <div className='max-h-96 overflow-y-auto mt-10'>
        <table className={styles.table}>
          <thead className={styles.thead}>
            <tr>
              {orderableCols.map((col, i) => (
                <th
                  className={clsx(styles.th, 'cursor-pointer')}
                  onClick={() => handleSort(col)}
                  key={i}
                >
                  {col.col}
                </th>
              ))}
            </tr>
          </thead>

          <tbody className={styles.tbody}>
            {displayRows.map((item, i) => {
              // Fila "RESULTADO"
              if (item.type === 'RESULTADO') {
                return (
                  <tr key={`resultado-${i}`} className='bg-yellow-50'>
                    <td colSpan={2} className={clsx(styles.td, styles.boldText)}>
                      RESULTADO
                    </td>
                    <td className={clsx(styles.td, styles.boldText)}>
                      {Number(item.periodo).toLocaleString('es-CL')}
                    </td>
                    <td className={clsx(styles.td, styles.boldText)}>
                      {Number(item.acumulado).toLocaleString('es-CL')}
                    </td>
                  </tr>
                )
              }

              // Fila normal
              const row = item.data
              // Cabecera con fondo gris si isGroup
              const rowClasses = row.isGroup ? 'bg-gray-100' : ''

              return (
                <tr key={i} className={clsx(styles.tr, rowClasses)}>
                  <td className={clsx(styles.td, row.isGroup && styles.boldText)}>
                    {row.codeCuenta}
                  </td>
                  <td className={clsx(styles.td, row.isGroup && styles.boldText)}>
                    {row.nameCuenta}
                  </td>
                  <td className={clsx(styles.td, row.isGroup && styles.boldText)}>
                    {Number(row.saldoPeriodo ?? 0).toLocaleString('es-CL')}
                  </td>
                  <td className={clsx(styles.td, row.isGroup && styles.boldText)}>
                    {Number(row.saldoAcumulado ?? 0).toLocaleString('es-CL')}
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}
