import { AxiosInstance } from 'axios'
import { ICreateOrganization } from '../models/Organization'
import { BaseHTTP } from './base.http'
import { CreateComprobantesDto } from '../models/Comprobante'
import { UpdateLibroContableDto } from '../models/LibroContable'
import { UpdateMultiLibros } from '../models/MultiLibros'


export class OrganizationAPI {

  private http !: AxiosInstance

  constructor() {
    this.http = BaseHTTP.getInstance().http
  }

  createOrganization(payload: ICreateOrganization) {
    const path = 'v1/organizations'
    return this.http.post<{ token: string }>(path, payload)
  }

  // obtener datos de un ingreso / egreso en particular desde la tabla
  getDataLibroContable(businessId, comprobanteId, type) {
    const path = `v1/business/${businessId}/libros-contables/${comprobanteId}?type=${type}`
    return this.http.get(path)
  }

  // editar  libro contable de una organización
  patchDataLibroContable(businessId, comprobanteId, payload) {
    const path = `v1/business/${businessId}/libros-contables/${comprobanteId}`
    return this.http.patch(path, payload)
  }

  //obtener el comprobante de un dato (ingreso / egreso) del libro contable
  getComprobante(params) {
    const { businessId, comprobanteId, type } = params
    const path = `v1/business/${businessId}/libro-comprobante/${comprobanteId}?type=${type}`
    return this.http.get(path)
  }

  //obtener todas cuentas de un (ingreso / egreso) del libro contable
  getAllCuentas() {
    const path = 'v1/cuenta-contable'
    return this.http.get(path)
  }

  patchEditBusiness(businessId, payload) {
    const path = `v1/business/${businessId}`
    return this.http.patch(path, payload)
  }

  getUpdatePreguntonSingleBusiness(businessId) {
    const path = `v1/business/${businessId}/update`
    return this.http.get(path)
  }
 
  getNotification(params){
    const { businessId } = params
    const path = `v1/business/${businessId}/notification`
    return this.http.get(path)
  }

  postComprobante(payload: CreateComprobantesDto, businessId){
    const path = `v1/business/${businessId}/libros-contables`
    return this.http.post(path, payload)
  }
  
  getLibroComprobantes(businessId: number, queryFrom: string, queryTo: string, filter: string) {
    // Validación para el parámetro filter
    const validFilters = ['todos', 'aprobados', 'borradores'];
    if (!validFilters.includes(filter)) {
      throw new Error(`Filter value is invalid. Expected one of: ${validFilters.join(', ')}`);
    }
    // Codificación de los parámetros para asegurar la URL
    const encodedQueryFrom = encodeURIComponent(queryFrom);
    const encodedQueryTo = encodeURIComponent(queryTo);
    const encodedFilter = encodeURIComponent(filter);
  
    const path = `/v1/business/${businessId}/libro-comprobantes?queryFrom=${encodedQueryFrom}&queryTo=${encodedQueryTo}&filter=${encodedFilter}`;
    return this.http.get(path);
  }

  getListRutName(businessId: number){
    const path = `v1/business/${businessId}/list-rut-name`
    return this.http.get(path)
  }

  getListManual(businessId){
    const path = `v1/business/${businessId}/list-manual-name`
    return this.http.get(path)
  }

  updateLibroContable(businessId: number, idLibro: number, payload: UpdateLibroContableDto) {
    const path = `v1/business/${businessId}/libros-contables/${idLibro}`;
    return this.http.patch(path, payload);
  }

  // Obtener datos del borrador
  findBorrador(businessId: number, id: number) {
    const path = `v1/business/${businessId}/borrador/${id}`;
    return this.http.get(path);
  }

  getComprobanteEdit(params) {
    const { businessId, comprobanteId } = params
    const path = `v1/business/${businessId}/libro-comprobante/${comprobanteId}?formato-borrador=${true}`
    return this.http.get(path)
  }

  getComprobanteBorrador(params) {
    const { businessId, comprobanteId } = params
    const path = `v1/business/${businessId}/borrador/${comprobanteId}`
    return this.http.get(path)
  }

  patchComprobante(businessId: number,  payload: UpdateMultiLibros) {
    const path = `v1/business/${businessId}/libros-contables/complete`
    return this.http.patch(path, payload)
  }

  updateBorrador(businessId: number, id: number, payload: any) {
    const path = `v1/business/${businessId}/borrador/${id}`;
    return this.http.patch(path, payload);
  }

  updateAprobado(businessId: number, id: number, payload: any) {
    const path = `v1/business/${businessId}/borrador/${id}?comprobante=${true}`
    return this.http.patch(path, payload)
  }

  getCuentasContables(params){
    const { businessId, type } = params
    const path = `v1/cuenta-contable/business/${businessId}/cuentas-last-level?type=${type}`
    return this.http.get(path)
  }

  getListaParaVincular(params){
    const { businessId, idLibro } = params
    const path = `v1/business/${businessId}/lista-para-vincular/${idLibro}`
    return this.http.get(path)
  }

}

export default new OrganizationAPI();
