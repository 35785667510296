import { PDFDownloadLink, Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer'
import clsx from 'clsx'

const cols = [
  { header: 'N° Cuenta', width: 60 },
  { header: 'Nombre Cuenta', width: 150 },
  { header: 'Debe', width: 60 },
  { header: 'Haber', width: 60 },
  { header: 'Deudor', width: 60 },
  { header: 'Acreedor', width: 60 },
  { header: 'Activo', width: 60 },
  { header: 'Pasivo', width: 60 },
  { header: 'Perdida', width: 60 },
  { header: 'Ganancia', width: 60 }
]

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    padding: 20
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 10
  },
  title: {
    textAlign: 'center',
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 10
  },
  table: {
    display: 'table',
    width: '100%',
    marginVertical: 10,
    fontSize: 8
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  headerRow: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#000',
    fontWeight: 'bold',
    marginBottom: 4,
    paddingBottom: 2
  },
  col: {
    padding: 4
  },
  signatureSection: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 20
  },
  signatureBlock: {
    width: '40%',
    textAlign: 'center'
  },
  signatureLine: {
    borderBottomWidth: 1,
    borderBottomColor: '#000',
    width: '100%',
    marginBottom: 2
  }
})

const ExportPDFReportesLibroDiario = ({ data, title, year, currentBusiness, rut }) => {
  const MyDocument = () => {
    const rowsPerPage = 10
    const pageCount = Math.ceil(data.length / rowsPerPage)

    return (
      <Document>
        {[...Array(pageCount)].map((_, pageIndex) => (
          <Page key={pageIndex} orientation='landscape' size='A4' style={styles.page}>
            <View style={styles.header}>
              <View>
                <Text style={{ fontSize: 8 }}>Resumen Movimientos</Text>
                <Text style={{ fontSize: 8 }}>{currentBusiness}</Text>
                <Text style={{ fontSize: 8 }}>RUT: {rut}</Text>
              </View>
              <Text style={styles.title}>{title}</Text>
              <Text style={{ fontSize: 8 }}>Página {pageIndex + 1}</Text>
            </View>
            <View style={styles.table}>
              <View style={styles.headerRow}>
                {cols.map((col, i) => (
                  <View key={i} style={{ ...styles.col, width: col.width }}>
                    <Text>{col.header}</Text>
                  </View>
                ))}
              </View>
              {data.slice(pageIndex * rowsPerPage, (pageIndex + 1) * rowsPerPage).map((row, i) => (
                <View key={i} style={styles.row}>
                <View style={{ ...styles.col, width: 60 }}>
                  <Text style={styles.boldText}>{row.numCuenta}</Text>
                </View>
                <View style={{ ...styles.col, width: 150 }}>
                  <Text style={styles.boldText}>{row.nameCuenta}</Text>
                </View>
                <View style={{ ...styles.col, width: 60 }}>
                  <Text style={clsx(styles.text, 'text-right')}>{row.debe.toLocaleString('es-CL')}</Text>
                </View>
                <View style={{ ...styles.col, width: 60 }}>
                  <Text style={clsx(styles.text, 'text-right')}>{row.haber.toLocaleString('es-CL')}</Text>
                </View>
                <View style={{ ...styles.col, width: 60 }}>
                  <Text style={clsx(styles.text, 'text-right')}>{row.deudor.toLocaleString('es-CL')}</Text>
                </View>
                <View style={{ ...styles.col, width: 60 }}>
                  <Text style={clsx(styles.text, 'text-right')}>{row.acreedor.toLocaleString('es-CL')}</Text>
                </View>
                <View style={{ ...styles.col, width: 60 }}>
                  <Text style={clsx(styles.text, 'text-right')}>{row.activo.toLocaleString('es-CL')}</Text>
                </View>
                <View style={{ ...styles.col, width: 60 }}>
                  <Text style={clsx(styles.text, 'text-right')}>{row.pasivo.toLocaleString('es-CL')}</Text>
                </View>
                <View style={{ ...styles.col, width: 60 }}>
                 <Text style={clsx(styles.text, 'text-right')}>{row.perdida.toLocaleString('es-CL')}</Text>
                </View>
                <View style={{ ...styles.col, width: 60 }}>
                  <Text style={clsx(styles.text, 'text-right')}>{row.ganancia.toLocaleString('es-CL')}</Text>
                </View>
              </View>
              ))}
            </View>

            {/* FIRMAS SOLO EN LA ÚLTIMA PÁGINA */}
            {pageIndex === pageCount - 1 && (
              <View style={styles.footer}>
                <Text style={{ fontSize: 12 }}>
                  Declaro(mos) dejando constancia que el presente {title} ha sido confeccionado correctamente. Para constancia, firmamos:
                </Text>
                <View style={styles.signatureSection}>
                  <View style={styles.signatureBlock}>
                    <View style={styles.signatureLine} />
                    <Text style={{ fontSize: 12 }}>Contador</Text>
                  </View>
                  <View style={styles.signatureBlock}>
                    <View style={styles.signatureLine} />
                    <Text style={{ fontSize: 12 }}>Representante Legal</Text>
                  </View>
                </View>
              </View>
            )}

          </Page>
        ))}
      </Document>
    )
  }

  if (!data) return null
  return (
    <PDFDownloadLink
      className='flex items-center justify-center w-10 h-10 text-red-700 font-bold tracking-tighter cursor-pointer rounded-full shadow-full-sm bg-white'
      document={<MyDocument />}
      fileName={`${title}_${currentBusiness}_${rut}_${year}.pdf`}
    >
      PDF
    </PDFDownloadLink>
  )
}

export default ExportPDFReportesLibroDiario
