import axios, { AxiosInstance } from 'axios'
import { REACT_APP_BASE_URL } from '../components/utils/constant'
import { BaseHTTP } from './base.http'
import { IBusiness, ICreateBusiness , ICreateRepresentanteLegal, BusinessDetails} from '../models/Business'


export class BusinessAPI {

  private http !: AxiosInstance

  constructor() {
    this.http = BaseHTTP.getInstance().http
  }

  getListBusiness() {
    const path = 'v1/business'
    return this.http.get<IBusiness[]>(path)
  }

  getBusinessById(businessId: number) {
    const path = `v1/business/${businessId}`;
    return this.http.get<BusinessDetails>(path);
  }
  

  // getBusinessById(businessId: number) {
  //   const path = `v1/business/${businessId}`
  //   return this.http.get<IBusiness>(path)
  // }

  createBusiness(business: ICreateBusiness) {
    const path = 'v1/business'
    return this.http.post<IBusiness>(path, business)
  }

  updateBusinessById(businessId: number, business: IBusiness) {
    const path = `v1/business/${businessId}`
    return this.http.patch<IBusiness>(path, business)
  }

  updateImageByBusinessId(imgFile: File, bussinessID: number) {
    const formData = new FormData()
    formData.append('file', imgFile)
    const path = `v1/business/${bussinessID}/image`
    return this.http.patch<IBusiness>(path, formData)
  }

  getListSummaryMovements(params) {
    const { businessId, type, month, year, code } = params
    console.log('businessId', businessId, 'type', type, 'month', month, 'year', year, 'code', code)
    const path = `v1/business/${businessId}/summary-movements?type=${type}&month=${month}&year=${year}&max=${10000}&code=${code}`
    return this.http.get(path)
  }

  getPorCobrarCobradas(params) {
    const { businessId, limit, type, state, order } = params
    const path = `v1/business/${businessId}/multi-boleta-factura?limit=${limit}&type=${type}&state=${state}&order=${order}`
    return this.http.get(path)
  }

  getPorPagarPagadas(params) {
    const { businessId, limit, type, state, order } = params
    const path = `v1/business/${businessId}/multi-boleta-factura?limit=${limit}&type=${type}&state=${state}&order=${order}`
    return this.http.get(path)
  }

  getTopFive(params) {
    const { businessId, type } = params
    const path = `v1/business/${businessId}/rankings-${type}?code=1M&limit=5`
    return this.http.get(path)
  }

  getHistoricalVsCurrentIncome(params) {
    const { businessId, period } = params
    const path = `v1/business/${businessId}/ventas-historicas?limit=5&code=${period}`
    return this.http.get(path)
  }

  getRankingEgresos(params) {
    const { businessId } = params
    const path = `v1/business/${businessId}/ranking-cuenta?code=1M&limit=5`
    return this.http.get(path)
  }

  postBusinessDashboard(payload) {
    const path = 'v1/business'
    return this.http.post(path, payload)
  }

  getDataLibroDiario(params) {
    const { businessId, limit, month, year } = params
    const path = `v1/business/${businessId}/libro-diario?limit=${limit}&month=${month}&year=${year}`
    return this.http.get(path)
  }

  getCuentasUsadas(params) {
    const { businessId } = params
    const path = `v1/cuenta-contable/business/${businessId}/cuentas-last-level`
    return this.http.get(path)
  }

  getDataLibroMayor(params) {
    const { businessId, idCuenta, dateFrom, dateTo } = params
    const path = `v1/business/${businessId}/libro-mayor/cuenta/${idCuenta}?fromYear=${dateFrom.year}&toYear=${dateTo.year}&fromMonth=${dateFrom.month}&toMonth=${dateTo.month}`
    return this.http.get(path)
  }

  createRepresentanteLegal(businessId: number, payload: ICreateRepresentanteLegal) {
    const path = `v1/business/${businessId}/representante`
    return this.http.patch<IBusiness>(path, payload)
  }

  getLibroClienteProveedor(params){
    const {businessId, type, month, year, max} = params
    const path = `v1/business/${businessId}/libro-cliente-proveedor?type=${type}&month=${month}&year=${year}&max=${max}`
    return this.http.get(path)
  }

  getEstadoResultado(params){
    const { businessId, from, to } = params
    const path = `v1/business/${businessId}/estado-resultado?from=${from}&to=${to}`
    return this.http.get(path)
  }

  getEstadoResultadoExtend(params){
    const { businessId, from, to } = params
    const path = `v1/business/${businessId}/estado-resultado-extend?from=${from}&to=${to}`
    return this.http.get(path)
  }

  getTransacciones(params){
    const {businessId, type, month, year} = params
    const path = `v1/business/${businessId}/transaction-list?type=${type}&month=${month}&year=${year}`
    return this.http.get(path)
  }

  postPagoArriendo(businessId, payload) {
    const path = `v1/business/${businessId}/libros-pre-model?model=arriendo`
    return this.http.post(path, payload)
  }

}

export default new BusinessAPI()

const url = REACT_APP_BASE_URL
let changeableUrl = ''

// set cuenta
export const setCuenta = async PK => {
  changeableUrl = `${url}/setCuenta/${PK}`
  return await axios.get(changeableUrl)
}