import { useContext } from 'react'
import { toast } from 'react-toastify'

// components
import Tabs from '../components/modules/tabs/Tabs'
import Layout from '../components/layouts/index'
import Bancos from '../components/modules/views/dashboard/Administracion/bancos/Index'
import IncomePage from '../components/modules/views/dashboard/Administracion/Ingresos'
import EgresosPage from '../components/modules/views/dashboard/Administracion/egresos'
import VinculacionPage from '../components/modules/views/dashboard/Administracion/Vinculaciones/index'
// material
import GlobalContext from '../context/global-context'
import UpdateIcon from '@mui/icons-material/CachedOutlined'

//api
import { OrganizationAPI } from '../api/organization.api'
import { useUpdateDataAdministracion } from './useAdministracion.hooks'

const Administracion = () => {
  const { currentBusiness } = useContext(GlobalContext)
  const organizationAPI = new OrganizationAPI()
  // const updateData = useUpdateDataAdministracion(state => state.updateData)
  const setUpdateData = useUpdateDataAdministracion(state => state.setUpdateData)

  const updatedata = async () => {
    try {
      const response = await organizationAPI.getUpdatePreguntonSingleBusiness(currentBusiness.id)
      response && setUpdateData(true)
    } catch (error) {
      console.error(error)
    }
  }

  const handleSearchMovements = async () => {
    await toast.promise(updatedata(), {
      pending: {
        render: () => 'Actualizando datos...',
        className: 'bg-accent2 text-white',
      },
      success: {
        render: () => 'Datos actualizados exitosamente...',
        className: 'bg-accent2 text-white',
      },
      error: {
        render: () => 'No se pudieron actualizando los datos',
        className: 'bg-accent1 text-white',
      },
    })
    setTimeout(() => {
      window.location.reload()
    }, 10000)
  }

  const data = [
    {
      title: 'Clientes',
      content: <IncomePage />
    },
    {
      title: 'Proveedores',
      content: <EgresosPage />
    },
    {
      title: 'Bancos',
      content: <Bancos />
    },
    {
      title: 'Vinculaciones',
      content: <VinculacionPage />
    },
  ]

  return (
    <>
      <Layout>
        <div
          onClick={handleSearchMovements}
          className='absolute top-[100px] right-16 bg-[#fff] text-slate-400 shadow-full-xs rounded-full  hover:bg-slate-100/50 transition-colors duration-150 hover:cursor-pointer p-1 z-10'
        >
          <UpdateIcon sx={{ color: 'inherit' }} />
        </div>
        <Tabs tabs={data} />
      </Layout>
    </>
  )
}

export default Administracion