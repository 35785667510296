import { useState } from 'react'

import EditIcon from '../../../../../assets/edit.svg'
import DeleteIcon from '../../../../../assets/delete.svg'
import FavoriteActiveIcon from '../../../../../assets/favorite_active.svg'
import FavoriteInactiveIcon from '../../../../../assets/favorite_inactive.svg'
import ConversationApi from '../../../../../api/seicho/conversations.api'
import { parseDate } from '../../../../utils/date'

import DeleteConversationModal from './DeleteConversationModal'
import EditConversationModal from './EditConversationModal'

const ConversationRow = ({ conversation, handleSelectConversation, setConversations }) => {
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [openEditModal, setOpenEditModal] = useState(false)

  const changeFavorite = async () => {
    setConversations((conversations) => conversations.map((c) => {
      if (c.id === conversation.id) {
        return { ...c, isFavorite: !c.isFavorite }
      }

      return c
    }))

    await ConversationApi.updateConversation(
      conversation.id,
      { isFavorite: !conversation.isFavorite }
    )
  }


  return (
    <div className="flex flex-col py-4 border-b-2 border-gray">
      <p className="text-xs">
        Última interacción: {parseDate(conversation.lastInteraction)}
      </p>
      <div className="flex items-center justify-between">
        <span className="cursor-pointer" onClick={() => handleSelectConversation(conversation)}>
          <h2 className="text-lg">{conversation.name}</h2>
        </span>
        <div className='flex'>
          <button
            onClick={() => changeFavorite()}
          >
            {conversation.isFavorite
              ? <img src={FavoriteActiveIcon} className="w-12 h-12" />
              : <img src={FavoriteInactiveIcon} className="w-12 h-12" />
            }
          </button>
          <button onClick={() => setOpenEditModal(true)}>
            <img src={EditIcon} className="w-12 h-12"/>
          </button>
          <button onClick={() => setOpenDeleteModal(true)}>
            <img src={DeleteIcon} className="w-12 h-12"/>
          </button>
        </div>
      </div>
      <EditConversationModal
        open={openEditModal}
        onClose={() => setOpenEditModal(false)}
        conversation={conversation}
        setConversations={setConversations}
      />
      <DeleteConversationModal
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        conversation={conversation}
        setConversations={setConversations}
      />
    </div>
  )
}

export default ConversationRow
