import { PDFDownloadLink, Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer'

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    padding: 20
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 10
  },
  title: {
    textAlign: 'center',
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 10
  },
  table: {
    display: 'table',
    width: '100%',
    marginVertical: 10,
    fontSize: 8
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  headerRow: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#000',
    fontWeight: 'bold',
    marginBottom: 4,
    paddingBottom: 2
  },
  col: {
    padding: 4
  },
  signatureSection: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 20
  },
  signatureBlock: {
    width: '40%',
    textAlign: 'center'
  },
  signatureLine: {
    borderBottomWidth: 1,
    borderBottomColor: '#000',
    width: '100%',
    marginBottom: 2
  },
  boldText: {
    fontSize: 8,
    fontWeight: 'bold'
  },
  text: {
    fontSize: 8
  }
})

/**
 * Genera las columnas dinámicamente según las propiedades encontradas en el primer objeto de data.
 * Se asume que cada fila tiene "codeCuenta" y "nameCuenta" y luego varios meses + "acumulado".
 */
function getDynamicColumns(data) {
  if (!data || !data.length) return []

  // Campos fijos al inicio
  const baseCols = [
    { header: 'Código Cuenta', field: 'codeCuenta', width: 120 },
    { header: 'Nombre Cuenta', field: 'nameCuenta', width: 220 }
  ]

  // Obtenemos las llaves del primer elemento (o de todos si quieres asegurarte),
  // filtrando las que ya tenemos en baseCols.
  const skipFields = ['codeCuenta', 'nameCuenta']
  const dynamicKeys = Object.keys(data[0]).filter(
    (key) => !skipFields.includes(key)
  )

  // Construimos las columnas para cada campo dinámico (por ejemplo, "2025-01", "2025-02", "acumulado", etc.)
  // Ajusta el width según necesites
  const dynamicCols = dynamicKeys.map((key) => ({
    header: key,       // Muestra el nombre tal cual "2025-01", "acumulado", etc.
    field: key,
    width: 120
  }))

  // Retornamos la combinación: [ codeCuenta, nameCuenta, ...meses/acumulado ]
  return [...baseCols, ...dynamicCols]
}

const ExportPDFEstadoResultado = ({ data, title, year, month, currentBusiness, rut }) => {
  // Generamos las columnas dinámicas según la data
  const cols = getDynamicColumns(data)

  const MyDocument = () => {
    const rowsPerPage = 10
    const pageCount = Math.ceil(data.length / rowsPerPage)

    return (
      <Document>
        {[...Array(pageCount)].map((_, pageIndex) => (
          <Page key={pageIndex} orientation='landscape' size='A4' style={styles.page}>
            {/* ENCABEZADO */}
            <View style={styles.header}>
              <View>
                <Text style={{ fontSize: 8 }}>Resumen Movimientos</Text>
                <Text style={{ fontSize: 8 }}>{currentBusiness}</Text>
                <Text style={{ fontSize: 8 }}>RUT: {rut}</Text>
              </View>
              <Text style={styles.title}>{title}</Text>
              <Text style={{ fontSize: 8 }}>Página {pageIndex + 1}</Text>
            </View>

            {/* TABLA */}
            <View style={styles.table}>
              {/* ENCABEZADO DE COLUMNAS */}
              <View style={styles.headerRow}>
                {cols.map((col, i) => (
                  <View key={i} style={{ ...styles.col, width: col.width }}>
                    <Text>{col.header}</Text>
                  </View>
                ))}
              </View>

              {/* FILAS */}
              {data
                .slice(pageIndex * rowsPerPage, (pageIndex + 1) * rowsPerPage)
                .map((row, i) => (
                  <View key={i} style={styles.row}>
                    {cols.map((col, j) => {
                      // Para formatear como número local si es un campo distinto a codeCuenta/nameCuenta
                      const isNumericField = !['codeCuenta', 'nameCuenta'].includes(col.field)
                      const value = row[col.field] ?? 0

                      return (
                        <View key={j} style={{ ...styles.col, width: col.width }}>
                          <Text
                            style={isNumericField ? [styles.text, { textAlign: 'right' }] : styles.boldText}
                          >
                            {isNumericField
                              ? Number(value).toLocaleString('es-CL')
                              : value
                            }
                          </Text>
                        </View>
                      )
                    })}
                  </View>
                ))}
            </View>

            {/* PIE DE FIRMA EN LA ÚLTIMA PÁGINA */}
            {pageIndex === pageCount - 1 && (
              <View style={styles.footer}>
                <Text style={{ fontSize: 12 }}>
                  Declaro(mos) dejando constancia que el presente {title} ha sido confeccionado correctamente. Para constancia, firmamos:
                </Text>
                <View style={styles.signatureSection}>
                  <View style={styles.signatureBlock}>
                    <View style={styles.signatureLine} />
                    <Text style={{ fontSize: 12 }}>Contador</Text>
                  </View>
                  <View style={styles.signatureBlock}>
                    <View style={styles.signatureLine} />
                    <Text style={{ fontSize: 12 }}>Representante Legal</Text>
                  </View>
                </View>
              </View>
            )}
          </Page>
        ))}
      </Document>
    )
  }

  if (!data) return null

  return (
    <PDFDownloadLink
      className='flex items-center justify-center w-10 h-10 text-red-700 font-bold tracking-tighter cursor-pointer rounded-full shadow-full-sm bg-white'
      orientation='landscape'
      document={<MyDocument />}
      fileName={`${title}_${currentBusiness}_${rut}_${month}_${year}.pdf`}
    >
      PDF
    </PDFDownloadLink>
  )
}

export default ExportPDFEstadoResultado
