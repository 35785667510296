import { useEffect, useState } from 'react'
//ui
import { Paper, SearchInput } from '../../../../ui/index'

//assets
import QuestionIcon from '@mui/icons-material/HelpOutline'
import StarFullIcon from '@mui/icons-material/StarOutlined'

// hooks
import { useContentTypeReportes } from '../../../../../../pages/Reportes/useReportes.hook'

const FavoriteNavBar = ({ setShowModal }) => {
  const styleImage = 'cursor-pointer w-10 h-10'
  const favoriteIcons = useContentTypeReportes(state => state.favoriteIcons)
  const removeFavoriteIcon = useContentTypeReportes(state => state.removeFavoriteIcon)
  const setFavoriteContent = useContentTypeReportes(state => state.setFavoriteContent)
  const resetTypeContent = useContentTypeReportes(state => state.resetTypeContent)
  const [searchTerm, setSearchTerm] = useState('')

  useEffect(() => {
    resetTypeContent()
  }, [resetTypeContent])

  const handleIconClick = (icon) => {
    setFavoriteContent(icon.id)
    setShowModal(true)
  }

  const filteredIcons = searchTerm
    ? favoriteIcons.filter((icon) =>
        icon.title.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : favoriteIcons

  return (
    <div>
      {favoriteIcons.length === 0 ? (
        <p>No hay íconos favoritos</p>
      ) : (
        <Paper>
          <section className='flex items-center justify-end'>
            <SearchInput
              placeholder='Buscar reporte'
              classNameBorder='rounded-full'
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </section>
          <section className='grid grid-cols-[repeat(auto-fit,minmax(180px,1fr))] gap-6 py-4 max-w-screen-lg mx-auto place-items-center'>
            {filteredIcons.length > 0 ? (
              filteredIcons.map((icon, index) => (
                <div
                  key={index}
                  className='relative flex flex-col items-center justify-center shadow-md border rounded-2xl w-full max-w-[220px] aspect-square'
                  onClick={() => handleIconClick(icon)}
                >
                  <div className='absolute top-2 right-2'>
                    <QuestionIcon className={styleImage} style={{ color: '#a1a1aa' }} />
                  </div>
                  <div className='absolute top-2 left-2'>
                    <StarFullIcon
                      className={styleImage}
                      style={{ color: 'orange' }}
                      alt='icon'
                      onClick={(e) => {
                        e.stopPropagation();
                        removeFavoriteIcon(icon.id);
                      }}
                    />
                  </div>
                  <img className='w-20 h-20 cursor-pointer mt-5' src={icon.icon} alt={icon.title} />
                  <p className='text-center text-xs'>{icon.title}</p>
                </div>
              ))
            ) : (
              <p className='text-center text-gray-500'>No se encontraron resultados</p>
            )}
          </section>
        </Paper>
      )}
    </div>
  )
}

export default FavoriteNavBar