import { useState, useEffect, useContext } from 'react'
import { HeadingTitle, Paper } from '../../../components/modules/ui/index'
import { ExportCSV, ExportXLS } from '../../../components/modules/table/ExportData'
import ExportPDF from '../../../components/modules/table/typesExportPDF/ExportPDFEstadoResultado'
import GlobalContext from '../../../context/global-context'
import { BusinessAPI } from '../../../api/business.api'
import dayjs from 'dayjs'
import FilterDateControl from '../../../components/modules/table/FilterDateControl'
import TableExtend from './Table'

// Función auxiliar para pivotar la data igual que se muestra en la tabla
function pivotData(rows) {
  // 1) Obtener lista de meses únicos (se asume que cada row tiene propiedad "mes")
  const uniqueMonths = Array.from(new Set(rows.map(r => r.mes).filter(Boolean))).sort();

  // 2) Agrupar por codeCuenta
  const dataByCuenta = new Map();
  rows.forEach(item => {
    const key = item.codeCuenta;
    if (!dataByCuenta.has(key)) {
      dataByCuenta.set(key, {
        codeCuenta: item.codeCuenta,
        nameCuenta: item.nameCuenta,
        monthlyData: {}, // Se inicializa como objeto vacío
        finalAcumulado: 0,
        isGroup: item.isGroup || false,
      });
    }
    const obj = dataByCuenta.get(key);
    // Acumular saldoPeriodo para el mes correspondiente
    obj.monthlyData[item.mes] = (obj.monthlyData[item.mes] ?? 0) + (item.saldoPeriodo ?? 0);
    // Sumar saldoPeriodo para el acumulado
    obj.finalAcumulado += (item.saldoPeriodo ?? 0);
  });

  // 3) Convertir a array y ordenar por código de cuenta
  const pivotedData = Array.from(dataByCuenta.values());
  pivotedData.sort((a, b) => a.codeCuenta.localeCompare(b.codeCuenta));

  // 4) Crear una fila "TOTAL" (opcional) que sume solo los items con isGroup = true
  const totalRow = {
    codeCuenta: 'TOTAL',
    nameCuenta: 'MES',
    monthlyData: {},
    finalAcumulado: 0,
    isGroup: true,
  };

  // Inicializar cada mes en 0 para la fila total
  uniqueMonths.forEach(m => {
    totalRow.monthlyData[m] = 0;
  });

  // Sumar los valores de cada fila que sea grupo
  pivotedData.forEach(item => {
    if (item.isGroup) {
      uniqueMonths.forEach(m => {
        totalRow.monthlyData[m] += (item.monthlyData[m] ?? 0);
      });
      totalRow.finalAcumulado += item.finalAcumulado;
    }
  });
  pivotedData.push(totalRow);

  return { pivotedData, uniqueMonths };
}

const ListEstadoDeResultadosExtend = () => {
  const { ui, currentBusiness } = useContext(GlobalContext)
  const businessAPI = new BusinessAPI()
  const [data, setData] = useState([])

  // Fechas fijas: primer día del año y fecha actual
  const fixedFilters = {
    fechaInicio: dayjs().startOf('year').format('YYYY-MM-DD'),
    fechaFin: dayjs().format('YYYY-MM-DD'),
  }

  // Llamada a la API usando los filtros fijos
  const getData = async () => {
    if (currentBusiness.id) {
      ui.setLoader({ visible: true, text: 'Cargando los datos de la tabla...' })
      try {
        const response = await businessAPI.getEstadoResultadoExtend({
          businessId: currentBusiness.id,
          from: fixedFilters.fechaInicio,
          to: fixedFilters.fechaFin,
        })
        const { data } = response
        console.log('DATA', data, currentBusiness)
        setData(data)
      } catch (error) {
        console.error(error)
        setData([])
      } finally {
        ui.setLoader({ visible: false, text: '' })
      }
    } else {
      console.error('No dato')
    }
  }

  useEffect(() => {
    getData()
  }, [currentBusiness])

  // Pivotamos la data igual que se muestra en la tabla
  const { pivotedData, uniqueMonths } = pivotData(data)

  // Preparamos la data para exportación: cada fila tendrá la cuenta, nombre,
  // cada mes como columna y el acumulado final, asegurando valores numéricos.
  const dataToExport = pivotedData.map(item => {
    const exportRow = {
      codeCuenta: item.codeCuenta,
      nameCuenta: item.nameCuenta,
    }
    uniqueMonths.forEach(month => {
      // Aseguramos que sea un número, incluso si es undefined
      exportRow[month] = Number(item.monthlyData[month] ?? 0)
    })
    exportRow.acumulado = Number(item.finalAcumulado ?? 0)
    return exportRow
  })

  const icons = [
    <ExportCSV
      key='export-csv'
      currentBusiness={currentBusiness.name}
      rut={currentBusiness.rut}
      data={dataToExport}
      title='Reportes Estado de Resultados'
      from={fixedFilters.fechaInicio}
      to={fixedFilters.fechaFin}
    />,
    <ExportXLS
      key='export-xls'
      currentBusiness={currentBusiness.name}
      rut={currentBusiness.rut}
      data={dataToExport}
      title='Reportes Estado de Resultados'
      from={fixedFilters.fechaInicio}
      to={fixedFilters.fechaFin}
    />,
    <ExportPDF
      key='export-pdf'
      currentBusiness={currentBusiness.name}
      rut={currentBusiness.rut}
      data={dataToExport}
      title='Reportes Estado de Resultados'
      from={fixedFilters.fechaInicio}
      to={fixedFilters.fechaFin}
    />
  ]

  return (
    <Paper>
      <HeadingTitle title="Estado de Resultados Comparativo" rightIcons={icons} iconHeight={32} />

      <div className="p-4">
        <div className="flex gap-2 items-end">
          <FilterDateControl
            label="Fecha Desde"
            value={fixedFilters.fechaInicio}
            disabled
          />
          <FilterDateControl
            label="Fecha Hasta"
            value={fixedFilters.fechaFin}
            disabled
          />
        </div>
      </div>

      <TableExtend rows={data} getData={getData} />
    </Paper>
  )
}

export default ListEstadoDeResultadosExtend
