import iconConciliacionBancaria from '../../assets/iconosCardInformes/informe_4.png'
import iconEstadoResultado from '../../assets/iconosCardInformes/informe_2.png'
import iconFlujoCaja from '../../assets/iconosCardInformes/informe_11.png'
import iconCobranza from '../../assets/iconosCardInformes/informe_5.png'
import iconBalanceGeneral from '../../assets/iconosCardInformes/informe_5.png'
import iconLibroDiario from '../../assets/iconosCardInformes/informe_3.png'

export const arrayIcons = [
  {
    icon: iconFlujoCaja,
    title: 'Flujo de caja',
    id: '1',
    tooltip: 'Reporte Flujo de caja.',
  },
  {
    icon: iconEstadoResultado,
    title: 'Estado de resultados por periodo',
    id: '2',
    tooltip: 'Reporte Estado de resultado por periodo.',
  },
  {
    icon: iconConciliacionBancaria,
    title: 'Estado de resultados comparativo',
    id: '3',
    tooltip: 'Muestra los ingresos y gastos de la empresa.',
  },
  {
    icon: iconCobranza,
    title: 'Cobranza',
    id: '4',
    tooltip: 'Reporte Cobranza.',
  },
  {
    icon: iconBalanceGeneral,
    title: 'Balance general',
    id: '5',
    tooltip: 'Resumen financiero de su empresa, organizando sus activos, pasivos y patrimonio en un solo informe. Permite visualizar la situación económica en la ventana temporal que defina. El balance ayuda a tomar decisiones financieras y evaluar la estabilidad de su empresa en un solo reporte.',
  },
  {
    icon: iconLibroDiario,
    title: 'Libro Diario',
    id: '6',
    tooltip: 'Reporte Libro Diario.',
  },
  {
    icon: iconLibroDiario,
    title: 'Libro Mayor',
    id: '7',
    tooltip: 'Reporte Libro Mayor.',
  },
  {
    icon: iconLibroDiario,
    title: 'Libro Auxiliar de cliente',
    id: '8',
    tooltip: 'Reporte Libro Auxiliar de cliente.',
  },
  {
    icon: iconLibroDiario,
    title: 'Libro Auxiliar de proveedores',
    id: '9',
    tooltip: 'Reporte Libro Auxiliar de proveedores.',
  },
  {
    icon: iconBalanceGeneral,
    title: 'Balance clasificado',
    id: '10',
    tooltip: 'Reporte Balance Clasificado.'
  },
]
  