import { useEffect, useState } from 'react'

import ConversationRow from './ConversationRow'
import SearchBar from './SearchBar'

const Conversations = ({ conversations, title, handleSelectConversation, setConversations }) => {
  const [filteredConversations, setFilteredConversations] = useState(conversations)

  useEffect(() => {
    setFilteredConversations(conversations)
  }, [conversations])

  return (
    <div className="flex flex-col h-full border border-gray p-4 rounded-xl">
      <div className="flex">
        <h1 className="pb-2 text-2xl">{title}</h1>
        <div className="ml-auto">
          <SearchBar
            setFilteredConversations={setFilteredConversations}
            conversations={conversations}
          />
        </div>
      </div>
      <div className="border-b border-gray" />
      <div className="mt-4 flex-1 overflow-y-auto">
        {filteredConversations.map((conversation) => (
          <ConversationRow
            key={conversation.id || conversation.name}
            conversation={conversation}
            handleSelectConversation={handleSelectConversation}
            setConversations={setConversations}
          />
        ))}
      </div>
    </div>
  )
}

export default Conversations
