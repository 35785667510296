import { useContext, useEffect, useState } from 'react'

import ArrowButtonIcon from '../../../../../assets/arrow_button.svg'
import UploadIcon from '../../../../../assets/upload.png'
import ConversationsApi from '../../../../../api/seicho/conversations.api'
import MessagesApi from '../../../../../api/seicho/messages.api'
import { ToastContext } from '../../../../../context/ToastContext'

import DefaultQuestions from './DefaultQuestions'
import Messages from './Messages'

const Chat = ({ conversation, setConversation, setConversations }) =>{
  const [newMessage, setNewMessage] = useState('')
  const [messages, setMessages] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [files, setFiles] = useState([])

  const { showToast } = useContext(ToastContext)

  useEffect(() => {
    setMessages(conversation.messages)
  }, [conversation])

  const createNewConversation = async (message) => {
    const newConversation = await ConversationsApi.createConversation(message, files)
    newConversation.message = [...conversation.messages, ...newConversation.messages]

    return newConversation
  }

  const setUpdatedConversationInState = async (newMessage) => {
    if (!conversation.id) {
      const newConversation = await createNewConversation(newMessage)

      setConversations((conversations) => [...conversations, newConversation])
      setConversation(newConversation)

      return
    }

    const { message, response } = await MessagesApi.sendMessage(
      { content: newMessage, conversationId: conversation.id },
      files
    )

    const conversationMessages = [...conversation.messages, message, response]

    setConversations((conversations) => conversations.map((c) => {
      if (c.id === conversation.id) {
        return { ...c, messages: conversationMessages }
      }

      return c
    }))
    setConversation({ ...conversation, messages: conversationMessages })
  }

  const addMessagesToState = (messageContent) => {
    const consumerMessage = {
      conversationId: conversation.id,
      content: messageContent,
      isFromConsumer: true
    }
    const responseMessage = {
      conversationId: conversation.id,
      content: '',
      isFromConsumer: false
    }

    setMessages([
      ...messages,
      consumerMessage,
      responseMessage
    ])
  }


  const handleSendMessage = async (message) => {
    if (message.trim() === '') return

    setIsLoading(true)
    setNewMessage('')
    setFiles([])
    try {
      addMessagesToState(message)
      await setUpdatedConversationInState(message)
    } catch {
      showToast('error', 'Error al enviar el mensaje, intenta de nuevo')
    } finally {
      setIsLoading(false)
    }
  }
  const handleUploadFiles = (event) => {
    const files = event.target.files

    if (!files) return

    setFiles((prevFiles) => [...prevFiles, ...Array.from(files)])
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault()
      handleSendMessage(newMessage)
    }
  }

  const cleanConversation = () => {
    setConversation({ id: null, messages: [] })
  }

  return (
    <div className="flex flex-col h-full border border-gray p-4 rounded-xl">
      <div className='flex mb-2 items-center justify-between'>
        <h1 className="pb-2 mx-4 text-2xl">Chat principal</h1>
        {conversation.id && (
          <button
            className="p-2 bg-accent2 text-white rounded-lg"
            onClick={() => cleanConversation()}
          >
            Nuevo Chat
          </button>
        )}
      </div>
      <div className="border-b mx-4 border-gray" />
      <div className="flex-1 overflow-y-auto">
        <div className="flex flex-col space-y-2 mt-4">
          <Messages messages={messages} isLoading={isLoading} />
        </div>
      </div>
      <div className="flex flex-col justify-end">
        {messages.length === 0 && (<DefaultQuestions handleSendMessage={handleSendMessage} />)}
        <div className="mt-4 pb-10 flex relative flex-grow border border-gray rounded-lg text-base">
          <textarea
            className='italic-placeholder w-full h-full pl-2 mt-2 pr-10 border-none resize-none focus:outline-none focus:ring-0 bg-slate-100/70'
            rows={3}
            value={newMessage}
            onKeyDown={handleKeyDown}
            onChange={(e) => setNewMessage(e.target.value)}
            placeholder='Escribe tu Pregunta a Luca'
          />
          <div className='absolute right-6 bottom-1 flex gap-2'>
            {files.map((file, index) => (
              <div key={index} className="flex items-center gap-2">
                <span className='text-base'>{file.name}</span>
                <button
                  onClick={() => setFiles((prevFiles) => prevFiles.filter((f) => f !== file))}
                >
                  x
                </button>
              </div>
            ))}
            <label htmlFor="file-upload" className="cursor-pointer">
              <img src={UploadIcon} alt="Subir Archivo" className="w-6 h-6" />
            </label>
            <input
              id="file-upload"
              type="file"
              className="hidden"
              onChange={handleUploadFiles}
            />
            <button
              onClick={() => handleSendMessage(newMessage)}
            >
              <img src={ArrowButtonIcon} alt="Enviar" className="w-6 h-6" />
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Chat
