import { PDFDownloadLink, Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer'
import { formatDate } from '../../../utils/formatter'

const cols = [
    { header: 'RUT', width: 60 },
    { header: 'Cliente', width: 100 },
    { header: 'Fecha', width: 60 },
    { header: 'Vencimiento', width: 60 },
    { header: 'Comprobante', width: 60 },
    { header: 'Tipo Comprobante', width: 60 },
    { header: 'Documento', width: 60 },
    { header: 'Tipo Documento', width: 60 },
    { header: 'Debe', width: 60 },
    { header: 'Haber', width: 60 },
    { header: 'Saldo', width: 60 }
  ]

  const styles = StyleSheet.create({
    page: {
      flexDirection: 'column',
      padding: 20
    },
    header: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: 10
    },
    title: {
      textAlign: 'center',
      fontSize: 14,
      fontWeight: 'bold',
      marginBottom: 10
    },
    table: {
      display: 'table',
      width: '100%',
      marginVertical: 10,
      fontSize: 8
    },
    row: {
      flexDirection: 'row',
      alignItems: 'center'
    },
    headerRow: {
      flexDirection: 'row',
      borderBottomWidth: 1,
      borderBottomColor: '#000',
      fontWeight: 'bold',
      marginBottom: 4,
      paddingBottom: 2
    },
    col: {
      padding: 4
    },
    signatureSection: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginTop: 20
    },
    signatureBlock: {
      width: '40%',
      textAlign: 'center'
    },
    signatureLine: {
      borderBottomWidth: 1,
      borderBottomColor: '#000',
      width: '100%',
      marginBottom: 2
    },
    boldText: {
      fontSize: 8,
      fontWeight: 'bold'
    },
    text: {
      fontSize: 8
    }
  })

const ExportPDFLibroAuxiliar = ({ data, title, year, month, currentBusiness, rut }) => {
    const MyDocument = () => {
      const rowsPerPage = 10
      const pageCount = Math.ceil(data.length / rowsPerPage)

        return (
          <Document>
            {[...Array(pageCount)].map((_, pageIndex) => (
              <Page key={pageIndex} orientation='landscape' size='A4' style={styles.page}>
                <View style={styles.header}>
                  <View>
                    <Text style={{ fontSize: 8 }}>Resumen Movimientos</Text>
                    <Text style={{ fontSize: 8 }}>{currentBusiness}</Text>
                    <Text style={{ fontSize: 8 }}>RUT: {rut}</Text>
                  </View>
                  <Text style={styles.title}>{title}</Text>
                  <Text style={{ fontSize: 8 }}>Página {pageIndex + 1}</Text>
                </View>
                <View style={styles.table}>
                  <View style={styles.headerRow}>
                    {cols.map((col, i) => (
                      <View key={i} style={{ ...styles.col, width: col.width }}>
                        <Text>{col.header}</Text>
                      </View>
                    ))}
                  </View>
                    {data
                      .slice(pageIndex * rowsPerPage, (pageIndex + 1) * rowsPerPage)
                      .map((row, i) => (
                        <View key={i} style={styles.row}>
                          <View style={{ ...styles.col, width: 60 }}>
                            <Text style={styles.boldText}>{row.rut}</Text>
                          </View>
                          <View style={{ ...styles.col, width: 100 }}>
                            <Text style={styles.boldText}>{row.razonSocial}</Text>
                          </View>
                          <View style={{ ...styles.col, width: 60 }}>
                            <Text style={styles.text}>{formatDate(row.fecha)}</Text>
                          </View>
                          <View style={{ ...styles.col, width: 60 }}>
                            <Text style={styles.text}>{formatDate(row.fechaVencimiento)}</Text>
                          </View>
                          <View style={{ ...styles.col, width: 60 }}>
                            <Text style={styles.text}>{row.comprobante}</Text>
                          </View>
                          <View style={{ ...styles.col, width: 60 }}>
                            <Text style={styles.text}>{row.tipoComprobante}</Text>
                          </View>
                          <View style={{ ...styles.col, width: 60 }}>
                            <Text style={styles.text}>{row.folio}</Text>
                          </View>
                          <View style={{ ...styles.col, width: 60 }}>
                            <Text style={styles.text}>{row.nombreFolio}</Text>
                          </View>
                          <View style={{ ...styles.col, width: 60 }}>
                            <Text style={styles.text}>{row.debe.toLocaleString('es-CL')}</Text>
                          </View>
                          <View style={{ ...styles.col, width: 60 }}>
                            <Text style={styles.text}>{row.haber.toLocaleString('es-CL')}</Text>
                          </View>
                          <View style={{ ...styles.col, width: 60 }}>
                            <Text style={styles.text}>{row.saldo.toLocaleString('es-CL')}</Text>
                          </View>
                        </View>
                      ))}
                  </View>
                {pageIndex === pageCount - 1 && (
                  <View style={styles.footer}>
                    <Text style={{ fontSize: 12 }}>
                      Declaro(mos) dejando constancia que el presente {title} ha sido confeccionado correctamente. Para constancia, firmamos:
                    </Text>
                    <View style={styles.signatureSection}>
                      <View style={styles.signatureBlock}>
                        <View style={styles.signatureLine} />
                        <Text style={{ fontSize: 12 }}>Contador</Text>
                      </View>
                      <View style={styles.signatureBlock}>
                        <View style={styles.signatureLine} />
                        <Text style={{ fontSize: 12 }}>Representante Legal</Text>
                      </View>
                    </View>
                  </View>
                )}
              </Page>
            ))}
          </Document>
        )
      }

      if (!data) return null
      return (
        <PDFDownloadLink
          className='flex items-center justify-center w-10 h-10 text-red-700 font-bold tracking-tighter cursor-pointer rounded-full shadow-full-sm bg-white'
          orientation='landscape'
          document={<MyDocument />}
          fileName={`${title}_${currentBusiness}_${rut}_${month}_${year}.pdf`}
        >
          PDF
        </PDFDownloadLink>
      )
    }


export default ExportPDFLibroAuxiliar
