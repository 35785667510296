import { AxiosInstance } from "axios";
import { BaseHTTP } from "./seicho.http";
import { makeFormData } from "../../components/utils/formData";


export class ConversationApi {

  private http !: AxiosInstance

  constructor() {
    this.http = BaseHTTP.getInstance().http
  }

  async getConversations() {
    const conversationsResponse = await this.http.get('conversations')

    return conversationsResponse.data
  }

  async createConversation(firstMessage: string, files: File[]) {
    const selectedBusiness = localStorage.getItem('selectedBusiness')
    const companyId = selectedBusiness ? JSON.parse(selectedBusiness).id : null
    const newConversationData = makeFormData({ firstMessage, files, companyId })
    const conversationResponse = await this.http.post(
      'conversations',
      newConversationData,
      { headers: { 'Content-Type': 'multipart/form-data' } }
    )
    
    return conversationResponse.data 
  }

  async updateConversation(conversationId: string, conversation: any) {
    const conversationResponse = await this.http.put(
      `conversations/${conversationId}`,
      conversation
    )

    return conversationResponse.data
  }

  async deleteConversation(conversationId: string) {
    const deleteResponse = await this.http.delete(`conversations/${conversationId}`);

    return deleteResponse.data;
  }
}

export default new ConversationApi()
