import { AxiosInstance } from "axios";
import { BaseHTTP } from "../seicho/seicho.http";
import { IMessage } from "../../models/Messages";
import { makeFormData } from "../../components/utils/formData";


export class MessagesApi {

  private http !: AxiosInstance

  constructor() {
    this.http = BaseHTTP.getInstance().http
  }

  async sendMessage(message: IMessage, files: File[]) {
    const selectedBusiness = localStorage.getItem('selectedBusiness')
    const companyId = selectedBusiness ? JSON.parse(selectedBusiness).id : null
    const messageData = makeFormData({ ...message, files, companyId })
    const messageResponse = await this.http.post(
      'messages',
      messageData,
      { headers: { 'Content-Type': 'multipart/form-data' } }
    )

    return messageResponse.data
  }

  async deleteMessage(messageId: string) {
    const deleteResponse = await this.http.delete(`messages/${messageId}`);

    return deleteResponse.data;
  }
}

export default new MessagesApi()
