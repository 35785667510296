const Input = ({ label, type, registerProps, error, disabled }) => (
  <div className='text-sm flex flex-col'>
    <label className='whitespace-nowrap'>{label}</label>
    <div className='flex flex-col'>
      <input
        type={type}
        disabled={disabled}
        className={`w-full rounded border ${error ? 'border-alert' : 'border-gray'} p-2`}
        {...registerProps}
      />
      {error?.message && <p className='w-full text-xs text-alert'>{error.message}</p>}
    </div>
  </div>
)

export default Input
