import { createContext, useCallback, useMemo, useState } from 'react'

import Toast from '../components/modules/views/contableAgent/shared/Toast'

export const ToastContext = createContext({ showToast: () => {} })

export const ToastContextProvider = ({ children }) => {
  const [toast, setToast] = useState(null)

  const showToast = useCallback((type, message) => {
    setToast({ type, message })
  }, [])

  const value = useMemo(() => ({
    showToast
  }), [showToast])

  return (
    <ToastContext.Provider value={value}>
      {children}
      {toast && <Toast message={toast.message} type={toast.type} onClose={() => setToast(null)} />}
    </ToastContext.Provider>
  )
}