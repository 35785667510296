import { Dialog, DialogContent } from '@mui/material'
import CustomButton from '../../../../../ui/CustomButton'
import { Title } from '../../../../../ui'
import iconClip from '../../../../../../../assets/iconos/image.png'
import { useContext, useState } from 'react'
import {ReactComponent as GenericaIcon} from '../../../../../../../assets/iconosVinculaciones/2.svg'
import ModalMovimientosPorVincular from '../modalDialogPorVincular/ModalMovimientosPorVincular'
import { BusinessAPI } from '../../../../../../../api/business.api'
import GlobalContext from '../../../../../../../context/global-context'
import { bankIcons } from '../../bancos/data'
import { formatDate } from '../../../../../../utils/formatter'
import Loader from '../../../../../ui/Loader'

const InputField = ({ label, placeholder, type, value, onChange }) => (
  <div>
    <label className='block text-sm font-medium text-gray-700'>{label}</label>
    <input
      type={type}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      className='mt-1 block w-full rounded-full border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
    />
  </div>
)

const TextAreaField = ({ label, placeholder }) => (
  <div>
    <label className='block text-sm font-medium text-gray-700'>{label}</label>
    <textarea
      placeholder={placeholder}
      rows={4}
      className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
    />
  </div>
)

const PagoArriendos = ({ open, setOpen }) => {
  const [openModal, setOpenModal] = useState(false)
  const [movimientoSeleccionado, setMovimientoSeleccionado] = useState(null)
  const [fecha, setFecha] = useState('')
  const [glosa, setGlosa] = useState('')
  const [tipoContrato, setTipoContrato] = useState('')
  const [monto, setMonto] = useState('')
  const businessAPI = new BusinessAPI()
  const [isPagado, setIsPagado] = useState(false)
  const { currentBusiness } = useContext(GlobalContext)
  const [isLoaderVisible, setIsLoaderVisible] = useState(false)

  const handleClose = () => setOpen(false)
  const handleOpenModal = () => setOpenModal(true)

  const handleSeleccionarMovimiento = (movimiento) => {
    setMovimientoSeleccionado(movimiento)
    setIsPagado(true)
  }

  const handleDatos = async () => {
    if (!movimientoSeleccionado) {
      alert('Debes seleccionar un medio de pago antes de continuar.')
      return
    }
    if (!monto || isNaN(Number(monto)) || Number(monto) > movimientoSeleccionado.resto) {
      alert(`El monto ingresado no puede ser mayor a ${movimientoSeleccionado.resto}.`);
      return;
    }
    
    const payload = {
      fecha: fecha,
      vencimiento: null,
      transaccionId: movimientoSeleccionado?.id,
      razonSocial: null,
      rut: null,
      rutId: null,
      nombreComprobante: null,
      nombreComprobanteId: null,
      glosa: glosa || null, 
      respaldoId: null,
      monto: monto,
    }
    try {
      setIsLoaderVisible(true)
      const response = await businessAPI.postPagoArriendo(currentBusiness.id, payload)
      console.info(response)
      handleClose()
    } catch (error) {
      console.error('Error al agregar comprobante:', error.message)
    } finally {
      setIsLoaderVisible(false)
    }
  }

  return (
    <>
    <Loader visible={isLoaderVisible} />
      <Dialog
        open={open}
        maxWidth='lg'
        scroll='body'
        PaperProps={{
          sx: {
            backgroundColor: '#f2f2f2',
            borderRadius: '8px',
            padding: '24px',
          },
        }}
      >
        <DialogContent>
          <div className='flex justify-between items-center mb-6'>
            <div>
              <h2 className='text-lg font-bold text-gray-800'>Pago Arriendos</h2>
              <p className='text-sm text-gray-600'>
                Te permite generar un respaldo contable/financiero en caso de haber arrendado un bien sobre el cual se
                pague un precio por un periodo determinado.
              </p>
            </div>
            <GenericaIcon className='w-14 h-14 text-gray-500' />
          </div>
          <div className='p-6 bg-white rounded-lg shadow-md'>
            <div className='grid grid-cols-2 gap-6'>
              <div className='grid grid-cols-2 gap-6'>
                <InputField label='Folio' type='text' />
                <div>
                  <label className='block text-sm font-medium text-gray-700'>Fecha</label>
                  <input
                    type='date'
                    value={fecha}
                    onChange={(e) => setFecha(e.target.value)}
                    className='mt-1 block w-full rounded-full border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                  />
                </div>
                <div>
                  <label className='block text-sm font-medium text-gray-700'>Tipo Contrato</label>
                  <select
                    value={tipoContrato}
                    onChange={(e) => setTipoContrato(e.target.value)}
                    className='mt-1 block w-full rounded-full border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                  >
                    <option value=''>Seleccione...</option>
                    <option value='Maquinaria'>Maquinaria</option>
                    <option value='Vehiculo'>Vehículo</option>
                    <option value='Casa'>Casa</option>
                    <option value='Departamento'>Departamento</option>
                    <option value='Bodega'>Bodega</option>
                    <option value='Campo'>Campo</option>
                    <option value='Terreno'>Terreno</option>
                    <option value='Local'>Local</option>
                    <option value='Oficina'>Oficina</option>
                    <option value='Otro'>Otro</option>
                  </select>
                </div>
                <InputField label='Monto' placeholder='$' type='text' value={monto} onChange={(e) => setMonto(e.target.value.replace(/[^0-9]/g, ""))}/>
                <InputField label='Contraparte' placeholder='Nombre' type='text' />
                <InputField label='RUT' placeholder='11.111.111-1' type='text' />
                <div className='col-span-2'>
                  <div className='w-full h-auto bg-white rounded-md border border-gray-300 p-4 shadow-md flex items-center'>
                    {movimientoSeleccionado ? (
                      <div className='flex items-start space-x-4'>
                        <div className='text-red-500 text-3xl'>
                          <img src={bankIcons[movimientoSeleccionado.nombreCuentaBanco]}  className='min-w-8 h-8' />
                        </div>
                        <div className='text-sm'>
                          <p className='text-gray-600 text-xs'>{formatDate(movimientoSeleccionado.fecha)}</p>
                          <p className='text-gray-800 mt-1'>
                            <span className='font-bold'>{movimientoSeleccionado.nombreCuentaBanco}</span>, 
                            cuenta {movimientoSeleccionado.numeroCuentaBanco}
                          </p>
                          <p className='text-gray-700'>
                            <span className='font-semibold'>Contraparte:</span> {movimientoSeleccionado.contraparte}
                          </p>
                          <p className='text-gray-700'>
                            <span className='font-semibold'>Glosa:</span> {movimientoSeleccionado.descripcion}
                          </p>
                        </div>
                      </div>
                    ) : (
                      <p className='text-gray-400 text-center w-full'>Selecciona un movimiento para ver los detalles</p>
                    )}
                  </div>
                </div>
              </div>
              <div className='grid gap-6'>
                <div className='flex items-center gap-4'>
                  <label htmlFor='pagado' className='text-sm text-gray-700'>
                    PAGADO
                    <input type='checkbox' id='pagado' className='ml-2 h-4 w-4 rounded border-gray-300' checked={isPagado} onChange={(e) => setIsPagado(e.target.checked)}/>
                  </label>
                  <CustomButton title='Seleccionar Pago' className='rounded-full ml-auto' color='primary' onClick={handleOpenModal} />
                </div>
                <InputField label='Clasificación' placeholder='1-1-1-1 clasificación' type='text' />
                <TextAreaField
                  label='Detalle'
                  placeholder='Describe en detalle la naturaleza, origen o lo que mejor describa la vinculación que vamos a crear.'
                  value={glosa}
                  onChange={(e) => setGlosa(e.target.value)}
                />
                <div>
                  <Title className='text-sm'>Agregar respaldo</Title>
                  <label
                    htmlFor='file-upload'
                    className='mt-1 custom-file-upload cursor-pointer border rounded-lg w-full h-[130px] bg-white flex flex-col items-center justify-center'
                  >
                    <img className='w-10 h-10' src={iconClip} />
                    <p className='text-xs text-gray-400 text-center'>Documentos permitidos: pdf, png, jpg, jpeg</p>
                  </label>
                  <input id='file-upload' type='file' className='hidden' />
                </div>
              </div>
            </div>
          </div>
          <div className='mt-8 flex justify-center gap-4'>
            <CustomButton title='GRABAR VINCULACIÓN' color='accent2' className='rounded-full' onClick={handleDatos} />
            <CustomButton title='CANCELAR' color='primary' className='rounded-full' onClick={handleClose} />
          </div>
        </DialogContent>
      </Dialog>
      {openModal && <ModalMovimientosPorVincular open={openModal} setOpen={setOpenModal} onSelectMovimiento={handleSeleccionarMovimiento} />}
    </>
  )
}

export default PagoArriendos