import { Dialog, DialogContent } from '@mui/material';
import { useState, useContext, Fragment, useEffect } from 'react';
import GlobalContext from '../../../../../context/global-context';
import CloseIcon from '@mui/icons-material/CloseOutlined';
import CustomButton from '../../CustomButton';
import TablaViewComprobante from './TablaViewComprobante';
import FilterControl from '../../../table/FilterControl';
import FilterDateControl from '../../../table/FilterDateControl';
import { OrganizationAPI } from '../../../../../api/organization.api';
import translations from '../../traslation/traslationMiContador.json';

const ModalViewAdministracion = ({ openModal, setOpenModal, comprobanteId, validation }) => {
  const [totalDebe, setTotalDebe] = useState(0);
  const [totalHaber, setTotalHaber] = useState(0);
  const [data, setData] = useState(null); // Contendrá directamente los datos de la API
  const { ui, currentBusiness } = useContext(GlobalContext);
  const organizationAPI = new OrganizationAPI();
  const handleClose = () => setOpenModal(false);

  // Función para manejar la fecha sin alterar su valor
  const parseDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    if (isNaN(date)) return dateString; // Devuelve el original si no es válida
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        ui.setLoader({ visible: true, text: 'Cargando datos, por favor espera...' });
        const response = await organizationAPI.getComprobante({
          businessId: currentBusiness.id,
          comprobanteId,
        });
        const fetchedData = response?.data || {};

        // Parsear la fecha sin cambiar su valor
        fetchedData.fecha = parseDate(fetchedData.fecha);

        setData(fetchedData);

        // Calcular totales
        const totalDebe = fetchedData.data.reduce((acc, libro) => acc + libro.debe, 0);
        const totalHaber = fetchedData.data.reduce((acc, libro) => acc + libro.haber, 0);

        setTotalDebe(totalDebe);
        setTotalHaber(totalHaber);
      } catch (error) {
        console.error('Error al obtener los datos:', error);
      } finally {
        ui.setLoader({ visible: false });
      }
    };

    if (comprobanteId) fetchData();
  }, [comprobanteId, currentBusiness.id]);

  if (!data) return null; // No mostrar el modal si no hay datos

  return (
    <Fragment>
      <Dialog
        open={openModal}
        onClose={handleClose}
        maxWidth={false}
        PaperProps={{
          style: {
            width: '90vw',
            maxWidth: '1200px',
            height: 'auto',
            maxHeight: '90vh',
            overflowY: 'auto',
          },
        }}
      >
        <DialogContent className='bg-gray-100 p-6'>
          {/* Header */}
          <div className='flex justify-between items-center border-b pb-4 mb-4'>
            <h2 className='text-2xl font-semibold'>
              {translations.comprobantes.comprobanteView} - {data.comprobanteNumero || '-'}
            </h2>
            <button onClick={handleClose} className='text-gray-400 hover:text-gray-600'>
              <CloseIcon />
            </button>
          </div>

          {/* Filtros */}
          <div className='flex flex-wrap items-center gap-4 mb-6'>
            <FilterDateControl label='Fecha' value={data.fecha || ''} disabled />
            <FilterControl
              label='Tipo de Comprobante'
              value={data.tipoComprobante || ''}
              options={[{ label: 'Traspaso', value: 'traspaso' }]}
              disabled
            />
          </div>

          {/* Glosa */}
          <div className='mb-6'>
            <label className='block text-sm font-medium text-gray-700'>{translations.comprobantes.glosa}</label>
            <textarea
              className='mt-1 w-full border-gray-300 rounded-lg shadow-sm p-2'
              rows='1'
              value={data.descripcion || ''}
              disabled
            ></textarea>
          </div>

          {/* Tabla View Comprobante */}
          <TablaViewComprobante
            setTotalDebe={setTotalDebe}
            setTotalHaber={setTotalHaber}
            totalDebe={totalDebe}
            totalHaber={totalHaber}
            initialData={data.data || []} // Libros directamente de la API
            context='view'
            validation={validation}
          />

          {/* Botón de cierre */}
          <div className='flex justify-end mt-6'>
            <CustomButton className='rounded-full' color='primary' title='Cerrar' onClick={handleClose} />
          </div>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

export default ModalViewAdministracion;
