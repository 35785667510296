import * as XLSX from 'xlsx'
import { CSVLink } from 'react-csv'
// import { PDFDownloadLink, Document, Page, Text } from '@react-pdf/renderer'

const ExportXLS = ({ data, title, year, month, currentBusiness, rut }) => {
  const exportToXLS = () => {
    const ws = XLSX.utils.json_to_sheet([])

    XLSX.utils.sheet_add_aoa(ws, [
      ['Resumen de movimientos'],
      [currentBusiness],
      [rut],
      [],
      [title], 
      [`EJERCICIO DESDE ${month} A ${month} DE ${year}`],
    ], { origin: 'A1' })

    ws['A5'].s = ws['A6'].s = { 
      alignment: { horizontal: 'center' }, 
      font: { bold: true, sz: 24 } 
    }

    XLSX.utils.sheet_add_json(ws, data, { origin: 'A8', skipHeader: false })
    const headersRow = 8 
    const headers = Object.keys(data[0] || {})
    headers.forEach((key, index) => {
      const cell = XLSX.utils.encode_cell({ r: headersRow - 1, c: index })
      if (ws[cell]) {
        ws[cell].s = {
          font: { bold: true },
          border: { bottom: { style: 'medium', color: { rgb: '000000' } } }
        }
      }
    })
    const lastDataRow = data.length + 6
    const lastRow = lastDataRow + 5
    XLSX.utils.sheet_add_aoa(ws, [
      [''],
      [`Declaro(mos) dejando constancia que el presente ${title} ha sido confeccionado correctamente. Para constancia, firmamos:`],
      ['', '__________________', '', '__________________'],
      ['', 'Contador', '', 'Representante Legal'],
    ], { origin: `B${lastRow}` })

    // Ajustar el ancho de las columnas automáticamente
    const columnWidths = Object.keys(data[0] || {}).map(() => ({ wch: 20 }))
    ws['!cols'] = columnWidths

    // Ocultar cuadrícula
    ws['!gridlines'] = false

    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')
    XLSX.writeFile(wb, `${title}_${currentBusiness}_${rut}_${month}_${year}.xlsx`)
  }

  return (
    <button
      className="w-10 h-10 text-green-700 font-bold tracking-tighter cursor-pointer rounded-full shadow-full-sm bg-white"
      onClick={exportToXLS}
    >
      XLS
    </button>
  )
}


const ExportCSV = ({ data, title, year, month, currentBusiness, rut }) => {
  return (
    <CSVLink
      data={data}
      filename={`movimientos_${title}_${currentBusiness}_${rut}_${month}_${year}.csv`}
    >
      <button className='w-10 h-10 text-blue-700 font-bold tracking-tighter cursor-pointer rounded-full shadow-full-sm bg-white'>
        CSV
      </button>
    </CSVLink>
  )
}

export { ExportXLS, ExportCSV }
