import axios, { AxiosInstance } from 'axios'

/**
 *
 * @description singleton class
 */
export class BaseHTTP {

  public http: AxiosInstance;
  private static _instance: BaseHTTP;

  private constructor() {
    this.http = axios.create({
      baseURL: 'https://summa-contable-agent-bd4243b0c53e.herokuapp.com',
      timeout: 1_000 * 30, // 15 seconds,
    });
  }

  public static getInstance(): BaseHTTP {
    if (!BaseHTTP._instance) {
      BaseHTTP._instance = new BaseHTTP();
    }

    return BaseHTTP._instance;
  }

  public setBackendToken(authorizationToken: string) {
    this.http.defaults.headers.common['Authorization'] = `Bearer ${authorizationToken}`;
  }
}
