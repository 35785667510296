import { useEffect, useRef } from 'react'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import rehypeRaw from 'rehype-raw'

const Messages = ({ messages, isLoading }) => {
  const messagesEndRef = useRef(null)

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' })
  }

  useEffect(() => {
    scrollToBottom();
  }, [messages])

  const messageBackgroundColorClasses = (isFromConsumer) => {
    if (isFromConsumer) {
      return 'bg-primary text-white self-end'
    }

    return 'bg-white border border-gray self-start'
  }

  return (
    <div className="flex flex-col overflow-y-auto mr-2">
      {messages.map((message, index) => (
        <div
          key={message.id}
          className={`py-3 my-2 pl-4 pr-8 text-base rounded-xl max-w-[70vw] break-words ${
            messageBackgroundColorClasses(!!message.isFromConsumer)
          }`}
        >
          <div>
            { (isLoading && index === messages.length - 1)
              ? (<div className="w-6 h-6 animate-spin rounded-full border-4 border-gray-200 border-t-[#00cccc]" />)
              : (
                <ReactMarkdown
                  remarkPlugins={[remarkGfm]}
                  rehypePlugins={[rehypeRaw]}
                  components={{
                    table: ({ node, ...props }) => (
                      <table className="table-auto border-collapse border border-gray-300 my-4" {...props} />
                    ),
                    th: ({ node, ...props }) => (
                      <th className="border border-gray-300 px-4 py-2 bg-gray-100 text-left" {...props} />
                    ),
                    td: ({ node, ...props }) => (
                      <td className="border border-gray-300 px-4 py-2" {...props} />
                    )
                  }}
                >
                  { message.content }
                </ReactMarkdown>
              )
            }
          </div>
        </div>
      ))}
      <div ref={messagesEndRef} />
    </div>
  )
}

export default Messages
