import {ReactComponent as GenericaIcon} from '../../../../../../assets/iconosVinculaciones/17.svg'
import {ReactComponent as RendicionesIcon} from '../../../../../../assets/iconosVinculaciones/5.svg'
import {ReactComponent as GastosCIcon} from '../../../../../../assets/iconosVinculaciones/3.svg'
import {ReactComponent as PremiosIcon} from '../../../../../../assets/iconosVinculaciones/1.svg'
import {ReactComponent as ArriendosIcon} from '../../../../../../assets/iconosVinculaciones/2.svg'
import {ReactComponent as CreditosIcon} from '../../../../../../assets/iconosVinculaciones/4.svg'

export const IconosPlan = [
    {
        icon: GenericaIcon,
        title: 'Genérica',
        id: '1'
    },
    {
        icon: RendicionesIcon,
        title: 'Rendiciones',
        id: '2'
    },
    {
        icon: GastosCIcon,
        title: 'Gastos Comunes',
        id: '3'
    },
    {
        icon: PremiosIcon,
        title: 'Premios',
        id: '4'
    },
    {
        icon: ArriendosIcon,
        title: 'Pago Arriendos',
        id: '5'
    },
    {
        icon: CreditosIcon,
        title: 'Créditos',
        id: '6'
    },
]