import clsx from 'clsx'
import { useState } from 'react'
import { Text } from '../ui'
import { formatCurrency, formatDate, formatRut } from '../../utils/formatter'
import translations from '../ui/traslation/traslationAdministracion.json'

const TableConciliado = ({ onCheckboxChange, rows }) => {
    const [selectedCheckboxes, setSelectedCheckboxes] = useState({})

    const handleCheckboxToggle = (id) => {
        const updatedSelection = {
          ...selectedCheckboxes,
          [id]: !selectedCheckboxes[id],
        }
        setSelectedCheckboxes(updatedSelection)
    
        const isAnySelected = Object.values(updatedSelection).some((value) => value)
        onCheckboxChange(isAnySelected)
    }

    const styles = {
        table: 'w-full text-[#000231] text-xs border-collapse leading-6 table-auto',
        tr: 'border-b border-gray-200 hover:bg-gray-100/50',
        td: 'text-left align-top p-2 max-w-[150px] overflow-x-auto whitespace-nowrap',
        th: 'border-2 border-transparent border-b-gray-200 text-left p-2 whitespace-nowrap',
        thead: 'sticky top-0 z-10 bg-white',
        tbody: 'overflow-y-auto',
        boldText: 'font-bold',
        text: 'text-xs text-gray-500',
        smallCol: 'w-[40px] text-center', // Tamaño reducido para checkbox
        mediumCol: 'w-[120px]', 
        wideCol: 'min-w-[180px] max-w-[220px]', // Tamaño estándar para columnas amplias
        clientCol: 'min-w-[220px] max-w-[280px]', // Mayor tamaño para columna Cliente
        rightAlign: 'text-right font-bold',
    }
    
    const obtenerAbreviatura = nombre => translations.documentosContables[nombre] || nombre;
    
    return (
        <div className="p-4 overflow-x-auto">
            <table className={styles.table}>
                <thead>
                    <tr>
                        <th className={styles.th}></th>
                        <th className={styles.th}>Folio</th>
                        <th className={styles.th}>Cliente</th>
                        <th className={styles.th}>Fecha</th>
                        <th className={styles.th}>Clasificación</th>
                        <th className={`${styles.th} text-right`}>Monto</th>
                        <th className={styles.th}>Status</th>
                    </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                    {rows.map(row => (
                        <tr key={row.id} className={styles.tr}>
                            {/* Checkbox */}
                            <td className={clsx(styles.td, styles.smallCol)}>
                                <input 
                                    type="checkbox" 
                                    className="rounded cursor-pointer" 
                                    checked={!!selectedCheckboxes[row.id]} 
                                    onChange={() => handleCheckboxToggle(row.id)} 
                                />
                            </td>
                            {/* Folio */}
                            <td className={clsx(styles.td, styles.mediumColCol)}>
                                <Text className={styles.boldText}>{obtenerAbreviatura(row.nombreFolio)}</Text>
                                <Text className={styles.text}>{row.folio}</Text>
                            </td>
                            {/* Cliente (con más ancho) */}
                            <td className={clsx(styles.td, styles.clientCol)}>
                                <Text className={styles.boldText}>{row.razonSocial || '<Razon Social>'}</Text>
                                <Text className={styles.text}>{row.rut && formatRut(row.rut) || '<RUT>'}</Text>
                            </td>
                            {/* Fecha */}
                            <td className={clsx(styles.td, styles.mediumCol)}>
                                <Text className={styles.boldText}>{row.fecha && formatDate(row.fecha)}</Text>
                                <Text className={styles.text}>
                                    {row.fechaVencimiento && formatDate(row.fechaVencimiento)}
                                </Text>
                            </td>
                            {/* Clasificación */}
                            <td className={clsx(styles.td, styles.mediumCol)}>
                                <Text className={styles.boldText}>{row.nombreCuenta}</Text>
                                <Text className={styles.text}>{row.codigoCuenta}</Text>
                                <Text className={styles.text}>{row.comprobante}</Text>
                            </td>
                            {/* Monto */}
                            <td className={clsx(styles.td, styles.mediumCol, 'text-right')}>
                                <Text className={styles.boldText}>{formatCurrency(row.montoTotal)}</Text>
                                <Text className={styles.text}>{formatCurrency(row.montoIvaRetenido)}</Text>
                                <Text className={styles.text}>{formatCurrency(row.montoNetoLiquido)}</Text>
                            </td>
                            {/* Status */}
                            <td className={clsx(styles.td, styles.mediumCol)}>
                                <Text className={styles.boldText}>{row.status}</Text>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )    
}    

export default TableConciliado
