const appendFiles = (formData, files, key) => {
  if (!Array.isArray(files)) {
    formData.append(key, files)
    return
  }

  files.forEach((file) => {
    formData.append(key, file)
  })
}

export const makeFormData = (data) => {
  const formData = new FormData()

  Object.entries(data).forEach(([key, value]) => {
    if (['files'].includes(key) && value?.length) {
      appendFiles(formData, value, key)
    } else {
      formData.append(key, value)
    }
  })

  return formData
}
