import { useContentTypeReportes } from './useReportes.hook'

//components
import NavBar from './NavBar'
import Layout from '../../components/layouts/index'
import ListLibroMayor from './libroMayor/ListLibroMayor'
import ListLibroDiario from './libroDiario/ListLibroDiario'
import ListBalanceGeneral from './balanceGeneral/ListBalanceGeneral'
import ListLibroAuxiliarProveedores from './libroAuxiliarProveedores/ListLibroAuxiliarProveedores'
import ListLibroAuxiliarCliente from './libroAuxiliarCliente/ListLibroAuxiliarCliente'
import ListEstadoDeResultados from './estadoDeResultado/ListEstadoDeResultados'
import { Dialog, Paper, IconButton } from '@mui/material'
import { useCallback, useMemo, useState } from 'react'
import Draggable from 'react-draggable'
import OpenWithIcon from '@mui/icons-material/OpenWith'
import ListEstadoDeResultadosExtend from './estadoDeResultadoExtendido/ListEstadoDeResultadosExtend'

const Informes = () => {
  const typeContent = useContentTypeReportes(state => state.typeContent)
  const [showModal, setShowModal] = useState(false)
  const [isFullScreen, setIsFullScreen] = useState(false)

  const toggleFullScreen = () => {
    setIsFullScreen(prev => !prev)
  }

  const PaperComponent = useCallback((props) => (
    <Draggable handle='#draggable-dialog-title' cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  ), [])

  const renderContent = () => {
    switch (typeContent) {
      case '2': return <ListEstadoDeResultados />
      case '3': return <ListEstadoDeResultadosExtend />
      case '5': return <ListBalanceGeneral />
      case '6': return <ListLibroDiario />
      case '7': return <ListLibroMayor />
      case '8': return <ListLibroAuxiliarCliente />
      case '9': return <ListLibroAuxiliarProveedores />
      default: return <div></div>
    }
  }

  const content = useMemo(() => renderContent(), [typeContent])

  return (
    <Layout>
      <div>
        <NavBar setShowModal={setShowModal} />
        <Dialog
          open={showModal}
          onClose={(e) => {
            e.stopPropagation()
            setShowModal(false)
          }}
          fullScreen={isFullScreen}
          maxWidth={!isFullScreen ? 'xl' : false} 
          fullWidth={!isFullScreen}  
          PaperComponent={PaperComponent}
        >
          <div className='relative'>
            <IconButton 
              onClick={toggleFullScreen} 
              style={{
                position: 'absolute',
                top: 10,
                right: 10,
                zIndex: 10
              }}
            >
              <OpenWithIcon />
            </IconButton>
          </div>
          <div id='draggable-dialog-title' className='p-6'>
            {content}
          </div>
        </Dialog>
      </div>
    </Layout>
  )
}

export default Informes