import { useContext, useEffect, useState } from 'react'
import GlobalContext from '../../../context/global-context'

// MUI
import { Backdrop } from '@mui/material'

// components
import { HeadingTitle, Paper, Text } from '../../../components/modules/ui/index'
import { BusinessAPI } from '../../../api/business.api'
import Table from './Table'
import {currentMonth, currentYear } from '../../../components/utils/constant'
import { ExportCSV, ExportXLS } from '../../../components/modules/table/ExportData'
import ExportPDF from '../../../components/modules/table/typesExportPDF/ExportPDFReportesLibroDiario'
import { formatCurrency, formatDate } from '../../../components/utils/formatter'
import CustomButton from '../../../components/modules/ui/CustomButton'

const ListLibroDiario = () => {
  const [showModal, setShowModal] = useState(false)
  const [data, setData] = useState([])
  const { ui, currentBusiness } = useContext(GlobalContext)
  const [monthFilter, setMonthFilter] = useState(currentMonth.toString().padStart(2, '0'))
  const [yearFilter] = useState(currentYear)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const businessAPI = new BusinessAPI()

  const getData = async () => {
    const monthFormatter = monthFilter.toString().length === 1 ? `0${monthFilter}` : monthFilter

    if (currentBusiness.id) {
      ui.setLoader({ visible: true, text: 'Cargando los datos de la tabla...' })
      await businessAPI
        .getDataLibroDiario({
          businessId: currentBusiness.id,
          month: monthFormatter,
          year: yearFilter,
          limit: 10000
        })
        .then(({ data }) => {
          setData(data)
        })
        .catch(error => {
          setData([])
          console.error(error)
        })
        .finally(() => {
          ui.setLoader({ visible: false, text: '' })
        })
    } else {
      console.error('No hay un negocio seleccionado')
    }
  }

  const Modal = () => {
    const { show, title, body } = showModal
    if (!show) return null
    return (
      <Backdrop sx={{ zIndex: 1600 }} open={true}>
        <div className='py-6 px-12 flex flex-col items-center justify-center bg-white'>
          <Text variant='h6' className='text-xl mb-4 text-center'>
            {title}
          </Text>
          {body}
          <button
            className='bg-primary text-white rounded-full px-4 py-2 hover:bg-primary/80 focus:outline-none shadow'
            onClick={() => setShowModal(false)}
          >
            Cerrar
          </button>
        </div>
      </Backdrop>
    )
  }

  const dataToExport = data.map(item => {
    return {
      Fecha: item.fecha && formatDate(item.fecha),
      'Tipo de comprobante': item.tipoComprobante.name,
      Comprobante: item.idOrigin,
      'Nombre de cuenta': item.cuentaContable.nombreCuenta,
      Rut: item.informacion?.rutEmpresa,
      'Razon Social': item.informacion?.razonSocial,
      Documento: item.numero,
      Glosa: item.glosa,
      Debe: formatCurrency(item.debe),
      Haber: formatCurrency(item.haber)
    }
  })

  const icons = [
    <ExportCSV
      key='export-csv'
      currentBusiness={currentBusiness.name}
      rut={currentBusiness.rut}
      data={dataToExport}
      title='Reportes libro diario'
      year={yearFilter}
      month={monthFilter}
    />,
    <ExportXLS
      key='export-xls'
      currentBusiness={currentBusiness.name}
      rut={currentBusiness.rut}
      data={dataToExport}
      title='Reportes libro diario'
      year={yearFilter}
      month={monthFilter}
    />,
    <ExportPDF
      key='export-pdf'
      currentBusiness={currentBusiness.name}
      rut={currentBusiness.rut}
      data={data}
      setShowModal={setShowModal}
      title='Reportes libro diario'
      year={yearFilter}
      month={monthFilter}
    />
  ]

  /*const monthsOptions = months.map(monthOption => ({
    ...monthOption,
    disabled: yearFilter == currentYear && monthOption.value > currentMonth
  }))*/

  useEffect(() => {
    getData()
  }, [currentBusiness])
  
  const handleMonthChange = (e) => {
    const [year, month] = e.target.value.split('-')
    if (year === yearFilter.toString()) {
      setMonthFilter(month)
    }
  }

  return (
    <>
      <Paper>
        <HeadingTitle title='Libro Diario' rightIcons={icons} iconHeight={32} />
        <div>
          <div className='flex gap-2 items-end'>
            <div className='flex flex-col'>
              <label>Fecha</label>
                <input
                  type='month'
                  value={`${yearFilter}-${monthFilter}`}
                  onChange={handleMonthChange}
                  min={`${yearFilter}-01`}
                  max={`${yearFilter}-12`}
                  className='w-full border border-gray-300 rounded-full px-2 py-1 text-sm'
                />
            </div>
            <CustomButton
              className='rounded-full'
              color='primary'
              title='Buscar'
              onClick={getData}
            />
          </div>
        </div>
        <Table
          rows={data}
          getData={getData}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
        />
        <Modal onClose={() => setShowModal(false)} />
      </Paper>
    </>
  )
}

export default ListLibroDiario
