const Loader = ({ size='lg', color }) => {
  const getSizeClasses = (size) => {
    switch (size) {
      case 'sm':
        return 'h-4 w-4'
      case 'md':
        return 'h-8 w-8'
      case 'lg':
        return 'h-16 w-16'
      default:
        return ''
    }
  } 

  return (
    <div className="flex items-center justify-center">
    <div
      className={`animate-spin rounded-full border-b-2 ${color} ${getSizeClasses(size)}`}
    />
    </div>
  )
}

export default Loader
