import { useEffect, useRef, useState } from 'react'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import CustomButton from './CustomButton'
import { TextField } from '@mui/material'

const RoundedTextField = ({ placeholder, value, onChange, ...props }) => (
  <TextField
    fullWidth
    placeholder={placeholder}
    variant='outlined'
    size='small'
    className='bg-white rounded-full'
    InputProps={{
      style: {
        borderRadius: '20px',
      },
    }}
    value={value}
    onChange={onChange}
    {...props}
  />
)

const SearchFilter = ({
  placeholder = 'Buscar',
  className = '',
  value,
  onChange,
  filterValues,
  onFilterChange,
  onSearch,
  onCancel
}) => {
  const [showFilters, setShowFilters] = useState(false)
  const filterRef = useRef(null)

  useEffect(() => {
    function handleClickOutside(event) {
      if (filterRef.current && !filterRef.current.contains(event.target)) {
        setShowFilters(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    }
  }, [])

  const formatNumber = (value) => {
    if (!value) return ''
    return new Intl.NumberFormat('es-ES').format(value)
  }
  
  const parseNumber = (value) => {
    return value.replace(/\D/g, '')
  }  

  return (
    <div className={`relative ${className}`}>
      <input
        type='text'
        placeholder={placeholder}
        className='text-black border border-light-gray rounded-full h-[30px] px-2'
        value={value}
        onChange={onChange}
      />
      <button
        className='absolute right-2 top-1/2 transform -translate-y-1/2 bg-white hover:bg-gray-200 rounded-full'
        onClick={() => setShowFilters(!showFilters)}
      >
        <ArrowDropDownIcon className='text-gray-600' />
      </button>
      {showFilters && (
        <div className='absolute top-full right-0 mt-2 p-4 border rounded bg-white shadow-lg z-50' style={{ width: '350px' }}>
          <div className='p-4 space-y-4'>
            <div>
              <label className='block text-sm font-medium text-gray-700 mb-1'>Monto Total</label>
              <div className='flex space-x-2'>
                <RoundedTextField
                  placeholder='Desde'
                  value={filterValues.montoDesde ? formatNumber(filterValues.montoDesde) : ''}
                  onChange={(e) => onFilterChange('montoDesde', parseNumber(e.target.value))}
                />
                <RoundedTextField
                  placeholder='Hasta'
                  value={filterValues.montoHasta ? formatNumber(filterValues.montoHasta) : ''}
                  onChange={(e) => onFilterChange('montoHasta', parseNumber(e.target.value))}
                />
              </div>
            </div>
            <div>
              <label className='block text-sm font-medium text-gray-700 mb-1'>Descripción</label>
              <RoundedTextField
                placeholder='Buscar por descripción'
                value={filterValues.descripcion || ''}
                onChange={(e) => onFilterChange('descripcion', e.target.value)}
              />
            </div>
          </div>
          <div className='flex justify-end items-center gap-4 p-4 border-t border-gray-300'>
            <CustomButton className='rounded-full' color='primary' title='Cancelar' onClick={() => { setShowFilters(false); onCancel?.(); }} />
            {/*<CustomButton
              className='rounded-full'
              color='accent2'
              title='Buscar'
              onClick={() => onSearch()}
            />*/}
          </div>
        </div>
      )}
    </div>
  )
}

export default SearchFilter
