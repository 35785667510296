import { useContext, useEffect, useMemo, useState } from 'react'
import GlobalContext from '../../../../../../context/global-context'

//hooks
import { useUpdateDataAdministracion } from '../../../../../../pages/useAdministracion.hooks'

// MUI
import { Backdrop } from '@mui/material'

//api
//import { TablesAPI } from '../../../../../../api/table.api'

// components
import { Paper, Text } from '../../../../ui/index'
import { BusinessAPI } from '../../../../../../api/business.api'
import TableIngresos from './TableIngresos'
import Select from '../../../../ui/Select'
import {
  currentMonth,
  currentYear,
  monthsOptions as months,
  yearsOptions
} from '../../../../../utils/constant'
import { ExportCSV, ExportXLS } from '../../../../table/ExportData'
import ExportPDF from '../../../../table/typesExportPDF/ExportPDF'
import { formatCurrency, formatDate, formatRut } from '../../../../../utils/formatter'
import CustomButton from '../../../../ui/CustomButton'
import { tipoDocumento } from '../../../../data/dataTipoDocument'
import FilterPagination from '../../../../table/FilterPagination'
import Loader from '../../../../ui/Loader'
import SearchFilter from '../../../../ui/SearchFilter'
import SelectMUI from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Chip from '@mui/material/Chip'

const ListIngresos = () => {
  const [showModal, setShowModal] = useState(false)
  const [data, setData] = useState([])
  const { currentBusiness } = useContext(GlobalContext)
  const [monthFilter, setMonthFilter] = useState(currentMonth)
  const [yearFilter, setYearFilter] = useState(currentYear)
  const [opciones, setOpcionesFilter] = useState([])
  const [initialFilterDocumento, setInitialFilterDocumento] = useState([])
  const [rowsPerPage, setRowsPerPage] = useState(10)
  //const [isCheckboxChecked, setIsCheckboxChecked] = useState(false)
  const [isLoaderVisible, setIsLoaderVisible] = useState(false)
  //const [setSearchTerm] = useState('')
  //const [isLoading, setIsLoading] = useState(false)
  //const tablesAPI = new TablesAPI()
  const updateData = useUpdateDataAdministracion(state => state.updateData)
  const setUpdateData = useUpdateDataAdministracion(state => state.setUpdateData)
  const [highlightedRows, setHighlightedRows] = useState({})
  const [selectedFilters, setSelectedFilters] = useState([])
  const [filterValues, setFilterValues] = useState({
    montoDesde: '',
    montoHasta: '',
    descripcion: ''
  })

  const getData = async () => {
    const monthFormatter = monthFilter.toString().length === 1 ? `0${monthFilter}` : monthFilter

    if (currentBusiness.id) {
      const businessAPI = new BusinessAPI()
      setIsLoaderVisible(true)
      //ui.setLoader({ visible: true, text: ''})
      await businessAPI
        .getListSummaryMovements({
          type: 'ingreso',
          businessId: currentBusiness.id,
          month: monthFormatter,
          year: yearFilter,
          code: opciones || null ? opciones : data
        })
        .then(({ data }) => {
          setData(data)
          setUpdateData(false)
          if (initialFilterDocumento.length === 0) {
            setInitialFilterDocumento([
              { value: '', label: 'Todos los documentos' },
              ...tipoDocumento.filter(doc => data.some(item => item.nombreFolio === doc.label))
            ])
          }
        })
        .catch(error => {
          setData([])
          console.error(error)
        })
        .finally(() => {
          setIsLoaderVisible(false)
          //ui.setLoader({ visible: false, text: '' })
        })
    } else {
      console.error('No hay un negocio seleccionado')
    }
  }

  const Modal = () => {
    const { show, title, body } = showModal
    if (!show) return null
    return (
      <Backdrop sx={{ zIndex: 1600 }} open={true}>
        <div className='py-6 px-12 flex flex-col items-center justify-center bg-white'>
          <Text variant='h6' className='text-xl mb-4 text-center'>
            {title}
          </Text>
          {body}
          <button
            className='bg-primary text-white rounded-full px-4 py-2 hover:bg-primary/80 focus:outline-none shadow'
            onClick={() => setShowModal(false)}
          >
            Cerrar
          </button>
        </div>
      </Backdrop>
    )
  }

  const dataToExport = data.map(item => {
    return {
      'Nombre folio': item.nombreFolio,
      'Número folio': item.numeroFolio,
      'Razon Social': item.razonSocial,
      RUT: item.rut && formatRut(item.rut),
      Fecha: item.fecha && formatDate(item.fecha),
      'Fecha de vencimiento': item.fechaVencimiento && formatDate(item.fechaVencimiento),
      'Días hasta vencimiento': item.diasHastaVencimiento,
      Clasificación: item.nombreCuenta,
      'Codigo cuenta': item.codigoCuenta,
      'Número comprobante': item.comprobante,
      Usuario: ' @' + (item.usuarioClasificador || 'Luca'),
      Monto: formatCurrency(item.montoTotal),
      IVA: formatCurrency(item.montoIvaRetenido),
      NETO: formatCurrency(item.montoNetoLiquido),
      Status: item.status,
      // 'Fecha pago': item.fechaPago ? formatDate(item.fechaPago) : 'Sin pagos',
      Aprobado: item.aprobado ? 'Si' : 'No'
    }
  })

  const icons = [
    <ExportCSV
      key='export-csv'
      currentBusiness={currentBusiness.name}
      rut={currentBusiness.rut}
      data={dataToExport}
      title='ingresos'
      year={yearFilter}
      month={monthFilter}
    />,
    <ExportXLS
      key='export-xls'
      currentBusiness={currentBusiness.name}
      rut={currentBusiness.rut}
      data={dataToExport}
      title='ingresos'
      year={yearFilter}
      month={monthFilter}
    />,
    <ExportPDF
      key='export-pdf'
      currentBusiness={currentBusiness.name}
      rut={currentBusiness.rut}
      data={data}
      setShowModal={setShowModal}
      title='ingresos'
      year={yearFilter}
      month={monthFilter}
    />
  ]

  const monthsOptions = months.map(monthOption => ({
    ...monthOption,
    disabled: yearFilter == currentYear && monthOption.value > currentMonth
  }))

  const filtroDocumento = useMemo(() => {
    return initialFilterDocumento
  }, [initialFilterDocumento])

  /*const handleAprobarTodo = async () => {
    const allApproved = data.every(item => item.aprobado === 1)
    const newData = data.map(item => ({
      ...item,
      aprobado: allApproved ? 0 : 1, 
    }))
    setData(newData)
    if (currentBusiness.id) {
      try {
        ui.setLoader({ visible: true, text: allApproved ? 'Desaprobando datos...' : 'Aprobando datos...' })
        const ids = data.map(item => item.id)
        await tablesAPI.patchApproveAll(currentBusiness.id, ids, allApproved ? 0 : 1)
      } catch (error) {
        console.error(error)
      } finally {
        ui.setLoader({ visible: false, text: '' })
      }
    }
  }*/

  const filteredData = useMemo(() => {
    let filtered = data
    if (filterValues.descripcion) {
      const searchLower = filterValues.descripcion.toLowerCase()
      filtered = filtered.filter(item =>
        item.nombreCuenta?.toLowerCase().includes(searchLower) ||
        item.razonSocial?.toLowerCase().includes(searchLower) ||
        item.nombreFolio?.toLowerCase().includes(searchLower)
      )
    }
    if (filterValues.montoDesde) {
      filtered = filtered.filter(item => item.montoTotal >= Number(filterValues.montoDesde))
    }
    if (filterValues.montoHasta) {
      filtered = filtered.filter(item => item.montoTotal <= Number(filterValues.montoHasta))
    }
    if (selectedFilters.includes('aprobado')) {
      filtered = filtered.filter(item => item.aprobado === 1)
    } 
    if (selectedFilters.includes('porAprobar')) {
      filtered = filtered.filter(item => item.aprobado === 0)
    }
    if (selectedFilters.includes('destacados')) {
      filtered = filtered.filter(item => highlightedRows[item.id])
    }
    return filtered
  }, [data, filterValues, filterValues, selectedFilters, highlightedRows])
  
  useEffect(() => {
    getData()
  }, [currentBusiness])

  useEffect(() => {
    getData()
  }, [updateData === true])

  /*useEffect(() => {
    setIsCheckboxChecked(data.every(item => item.aprobado === 1))
  }, [data])*/

  const options = [
    { label: 'Aprobado', value: 'aprobado' },
    { label: 'Por Aprobar', value: 'porAprobar' },
    { label: 'Destacados', value: 'destacados' }
  ]
  
  const handleFilterChange = (key, value) => {
    setFilterValues(prev => ({
      ...prev,
      [key]: value
    }))
  }

  const handleClearFilters = () => {
    setMonthFilter(currentMonth)
    setYearFilter(currentYear)
    setOpcionesFilter([])
    setFilterValues({
      montoDesde: '',
      montoHasta: '',
      descripcion: ''
    })
    setSelectedFilters([])
  }

  const toggleHighlight = (id) => {
    setHighlightedRows((prev) => {
      const newState = { ...prev, [id]: !prev[id] }
      localStorage.setItem('highlightedRows', JSON.stringify(newState))
      return newState
    })
  }
  
  useEffect(() => {
    const savedHighlights = JSON.parse(localStorage.getItem('highlightedRows')) || {}
    setHighlightedRows(savedHighlights)
  }, [])
  

  return (
    <>
      <Loader visible={isLoaderVisible} />
      <Paper>
        <div className='relative'>
          <div className='absolute top-[-60px] right-0 z-20 flex space-x-4'>
            {icons.map((icon) => icon)}
          </div>
        </div>
        <div className='flex flex-wrap justify-between items-start mb-4'>
          <div className='flex flex-wrap gap-2 items-center flex-row'>
            <FilterPagination rowsPerPage={rowsPerPage} handleChangeRowsPerPage={setRowsPerPage} />
              <Select
                options={monthsOptions}
                defaultValue={monthFilter}
                onChange={(e) => setMonthFilter(e.target.value)}
              />
              <Select
                options={yearsOptions}
                defaultValue={yearFilter}
                onChange={(e) => setYearFilter(e.target.value)}
              />
                <Select
                  options={filtroDocumento}
                  defaultValue=''
                  onChange={(e) => setOpcionesFilter(e.target.value ? [e.target.value] : [])}
                />
                <SearchFilter
                  value={filterValues.descripcion}
                  onChange={(e) => handleFilterChange('descripcion', e.target.value)}
                  filterValues={filterValues}
                  onFilterChange={handleFilterChange}
                />
                <SelectMUI
                multiple
                displayEmpty
                value={selectedFilters}
                onChange={(event) => setSelectedFilters(event.target.value)}
                sx={{ width: 200, height: 30, borderRadius: '9999px', paddingX: 1, '& .MuiOutlinedInput-notchedOutline': { borderRadius: '9999px' },}}
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return <span className='text-gray-400'>Filtros</span>;
                  }
                  return (
                    <div className='flex gap-2'>
                      {selected.map((value) => (
                        <Chip 
                        key={value} 
                        label={options.find(option => option.value === value)?.label} 
                        className='rounded-full bg-blue-500 text-white px-2 py-1'
                        />
                      ))}
                    </div>
                  );
                }}
              >
                {options.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </SelectMUI>
                <CustomButton
                  className='rounded-full'
                  color='primary'
                  title='BUSCAR'
                  onClick={getData}
                />
                <CustomButton
                  className='rounded-full'
                  color='accent1'
                  title='LIMPIAR'
                  onClick={handleClearFilters}
                />
              {/*<label>
                  <input
                    className='mr-2 rounded-sm'
                    type='checkbox'
                    checked={isCheckboxChecked}
                    onClick={handleAprobarTodo}
                  />
                  Aprobar todos
                </label>*/}
          </div>
        </div>
        <TableIngresos 
          rows={filteredData} 
          getData={getData} 
          rowsPerPage={rowsPerPage} 
          setRowsPerPage={setRowsPerPage}
          highlightedRows={highlightedRows}
          toggleHighlight={toggleHighlight} 
          setData={setData}
        />
        <Modal onClose={() => setShowModal(false)} />
      </Paper>
    </>
  )
}

export default ListIngresos