import React, { useState } from 'react'
import clsx from 'clsx'
//import { colsCliente as cols } from '../../../components/modules/data/headersTables'
import { Text } from '../../../components/modules/ui'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { formatDate } from '../../../components/utils/formatter'
import { orderableCols, styles } from './dataCliente'

const Table = ({ rows }) => {
  const [order, setOrder] = useState({ col: 'Folio', field: 'numeroFolio', direction: 'desc' })

  // Función para manejar la ordenación
  const handleSort = ({ col, field }) => {
    const isAsc = order.col === col && order.direction === 'asc'
    setOrder({ col, field, direction: isAsc ? 'desc' : 'asc' })
  }

  // Función para agrupar filas y calcular totales
  const groupRows = () => {
    const grouped = rows.reduce((acc, row) => {
      const key = row.razonSocial
      // Solo procesar filas que tengan valores relevantes en DEBE o HABER y que sean objetos principales
      if ((row.debe !== 0 || row.haber !== 0) && row.comprobante !== undefined) {
        if (!acc[key]) {
          acc[key] = { razonSocial: key, debe: 0, haber: 0, saldo: 0, entries: [] }
        }
        acc[key].debe += row.debe || 0
        acc[key].haber += row.haber || 0
        acc[key].entries.push(row) // Guardar las entradas para mostrarlas más tarde
      }
      return acc
    }, {})

    return Object.values(grouped).filter(group => group.debe !== 0 || group.haber !== 0)
  }

  const groupedRows = groupRows()
    .sort((a, b) => {
      const comparison = (a.razonSocial || '').localeCompare(b.razonSocial || '')
      return order.direction === 'asc' ? comparison : -comparison
    })

  if (!groupedRows?.[0]) return <Text className='grid place-items-center py-12'>No hay datos</Text>

  return (
    <div className='overflow-x-auto'>
      <div className='max-h-96 overflow-y-auto mt-10'>
        <table className={styles.table}>
          <thead className={styles.thead}>
            <tr>
              {orderableCols.map((col, i) => (
                <th
                  className={clsx(styles.th, { 'cursor-pointer': true })}
                  onClick={() => handleSort(col)}
                  key={i}
                >
                  {col.col}
                  <KeyboardArrowDownIcon
                    className={clsx('transition-all ml-2 cursor-pointer', {
                      'opacity-40': order.col !== col.field,
                      'rotate-180': order.col === col.field && order.direction === 'desc'
                    })}
                  />
                </th>
              ))}
            </tr>
          </thead>
          <tbody className={styles.tbody}>
            {groupedRows.slice().map((group, i) => {
              let cumulativeSaldo = 0 // Inicializa el saldo acumulativo para cada grupo
              let totalDebe = 0 // Inicializa el total de debe
              let totalHaber = 0 // Inicializa el total de haber

              return (
                <React.Fragment key={i}>
                  {group.entries.map((row) => {
                    // Calcular el saldo acumulado
                    cumulativeSaldo += (row.debe || 0) - (row.haber || 0)
                    totalDebe += row.debe || 0
                    totalHaber += row.haber || 0

                    return (
                      <tr className={styles.tr} key={i}>
                        {/* Rut */}
                        <td className={styles.td}>
                          <Text className={styles.boldText}>{row.rut}</Text>
                        </td>
                        {/* Cliente */}
                        <td className={clsx(styles.td)}>
                          <Text className={styles.boldText}>{row.razonSocial}</Text>
                        </td>
                        {/* fecha */}
                        <td className={styles.td}>
                          <Text className={styles.boldText}>{row.fecha && formatDate(row.fecha)}</Text>
                        </td>
                        {/* vencimiento */}
                        <td className={styles.td}>
                          <Text className={styles.boldText}>{row.fechaVencimiento && formatDate(row.fechaVencimiento)}</Text>
                        </td>
                        {/*Comprobante*/}
                        <td className={styles.td}>
                          <Text className={styles.boldText}>{row.comprobante}</Text>
                        </td>
                        {/*TipoComprobante*/}
                        <td className={styles.td}>
                          <Text className={styles.boldText}>{row.tipoComprobante}</Text>
                        </td>
                        {/*
                        <td className={styles.td}>
                          <Text className={styles.boldText}>{'N/A CENTRO COSTO'}</Text>
                        </td>
                        <td className={styles.td}>
                          <Text className={styles.boldText}>{'N/A SUCURSAL'}</Text>
                        </td>/*}*/}
                        {/* Documento */}
                        <td className={styles.td}>
                          <Text className={clsx(styles.boldText)}>{row.folio}</Text>
                        </td>
                        {/*Tipo Documento*/}
                        <td className={styles.td}>
                          <Text className={clsx(styles.boldText)}>{row.nombreFolio}</Text>
                        </td>
                        {/* Debe*/}
                        <td className={styles.td}>
                          <Text className={clsx(styles.text, 'text-right')}>
                            {row.debe.toLocaleString('es-CL')}
                          </Text>
                        </td>
                        {/* Haber */}
                        <td className={styles.td}>
                          <Text className={clsx(styles.text, 'text-right')}>
                            {row.haber.toLocaleString('es-CL')}
                          </Text>
                        </td>
                        {/* Saldo*/}
                        <td className={styles.td}>
                          <Text className={clsx(styles.text, 'text-right')}>
                            {row.saldo.toLocaleString('es-CL')}
                          </Text>
                        </td>
                      </tr>
                    )
                  })}
                  <tr className={styles.tr}>
                    <td className={styles.td} colSpan={7}>
                    </td>
                    <td className={styles.td}>
                      <Text className={styles.boldText}>TOTAL</Text>
                    </td>
                    <td className={styles.td}>
                      <Text className={clsx(styles.boldText, 'text-right font-bold')}>
                        {totalDebe.toLocaleString('es-CL')}
                      </Text>
                    </td>
                    <td className={styles.td}>
                      <Text className={clsx(styles.boldText, 'text-right font-bold')}>
                        {totalHaber.toLocaleString('es-CL')}
                      </Text>
                    </td>
                    <td className={styles.td}>
                      <Text className={clsx(styles.boldText, 'text-right font-bold')}>
                        {cumulativeSaldo.toLocaleString('es-CL')}
                      </Text>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={orderableCols.length} className='border-t-2'></td>
                  </tr>
                </React.Fragment>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default Table