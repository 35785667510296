//react
import { useEffect, useState, useContext } from 'react'
import GlobalContext from '../../../../../../../context/global-context'
import { toast } from 'react-toastify'

//api
import { BankAPI } from '../../../../../../../api/bancos.api'

//components
import { Button, Paper } from '../../../../../ui/index'
import ModalEditAcounts from '../modalDialogEditAcounts/ModalEditAcounts'
import List from './List'
import { getWidgetToken } from '../../bancos/modalConectFintoc/contectModalStep'

//utils
import { formatCurrency } from '../../../../../../utils/formatter'
import { currentMonth, currentYear } from '../../../../../../utils/constant'

//data
import { firstObjectListAcountBank } from '../data'
import CloseIcon from '@mui/icons-material/CloseOutlined'
import PowerIcon from '@mui/icons-material/Power'
//import PowerOffIcon from '@mui/icons-material/PowerOff'
import CustomButton from '../../../../../ui/CustomButton'

const BancosTableInsights = ({ setConnectBank }) => {
  const [openModalEditAcounts, setOpenModalEditAcounts] = useState(false)
  const [, setDeudasPagos] = useState({ porPagar: 0, porCobrar: 0 })
  const [acounts, setAcounts] = useState([])
  const [transactionsAcount, setTransactionsAcount] = useState([])
  const [currentAcount] = useState(acounts[0] || null)
  const { currentBusiness, currentBank } = useContext(GlobalContext)
  const [uniqueAccounts, setUniqueAccounts] = useState([])
  const [selectedBank, setSelectedBank] = useState('')
  const [showPopup, setShowPopup] = useState(false)
  const [showDisconnect, setShowDisconnect] = useState(false)
  const [, setAccountToDisconnect] = useState(null)
  const [isOpen, setIsOpen] = useState(false)
  const bankAPI = new BankAPI()

  // Función para identificar la cuenta y el banco de búsqueda
  /*const handleItemSelection = event => {
    const { value } = event.target
    const selectedAcount = acounts.find(acount => acount.id === parseInt(value))
    setCurrentAcount(selectedAcount)
  }*/

  const fetchData = async () => {
    try {
      const [listAcountBankResponse] = await Promise.all([
        bankAPI.getListAcountBank(currentBusiness.id)
      ])

      const temporaryListAcountBank = [firstObjectListAcountBank, ...listAcountBankResponse.data]
      getDataListTransactionsAcount(temporaryListAcountBank[0].id)
      setAcounts(temporaryListAcountBank)
    } catch (error) {
      toast.error(
        'No se pudieron cargar los datos 🤯' + JSON.stringify(error.response.data.message)
      )
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    console.log(fetchData)
  }, [])

  const getDataListTransactionsAcount = async (defaultId = null) => {
    try {
      const limit = 100
      const monthFormatter = currentMonth.toString().padStart(2, '0') // Formatea el mes con dos dígitos

      // Llama a `getTransactionsSummary` del API
      const transactions = await bankAPI.getTransactionsSummary(
        defaultId || 0, // Si no hay cuenta seleccionada, usa 0 para todas
        currentBusiness.id, // ID del negocio
        limit, // Límite de registros
        currentYear, // Año actual
        parseInt(monthFormatter) // Mes actual formateado como número
      )
      // Actualiza el estado con los datos recibidos
      setTransactionsAcount(transactions)

      const filteredAccounts = transactions.reduce((acc, transaction) => {
        const exists = acc.some(
          (item) =>
            item.numeroCuentaBanco === transaction.numeroCuentaBanco
        )
        if (!exists) {
          acc.push({
            nombreCuentaBanco: transaction.nombreCuentaBanco,
            numeroCuentaBanco: transaction.numeroCuentaBanco,
            tipoMovimiento: transaction.tipoMovimiento,
            status: transaction.status
          })
        }
          return acc
        }, [])
      setUniqueAccounts(filteredAccounts)
    } catch (error) {
      console.error('Error al obtener las transacciones:', error)
      setTransactionsAcount([])
    }
  }

  useEffect(() => {
    getDataListTransactionsAcount()
  }, [currentBusiness])

  const handleBankSelection = (account) => {
    const value = `${account.nombreCuentaBanco}-${account.numeroCuentaBanco}`
    setSelectedBank(value)
    setIsOpen(false)
  }

  // Filtrar transacciones según el banco seleccionado
  const filteredTransactions = selectedBank
  ? transactionsAcount.filter(
      (transaction) =>
        `${transaction.nombreCuentaBanco}-${transaction.numeroCuentaBanco}` === selectedBank
    )
  : transactionsAcount

  // Función para obtener deudas y pagos
  const fetchDeudasPagos = async () => {
    try {
      const response = await bankAPI.getTotalDeudasPagos(currentBusiness.id)
      setDeudasPagos(response.data)
    } catch (error) {
      toast.error('No se pudieron cargar los datos de deudas y pagos 🤯')
      console.error('Error al obtener deudas y pagos:', error)
    }
  }

  // useEffect para cargar las deudas y pagos al iniciar o cuando cambie el negocio actual
  useEffect(() => {
    if (currentBusiness) {
      fetchDeudasPagos()
    }
  }, [currentBusiness])

  const CardInfo = ({ title, amount }) => (
    <section className='flex flex-row items-center justify-between shadow border p-2 rounded-full bg-white flex-grow'>
      <h2 className='text-lg'>{title}</h2>
      <div className='flex items-center gap-x-2'>
        <p className='text-accent2 text-lg font-bold'>
          {amount ? formatCurrency(amount) : '$ 0'}
        </p>
        <p className='uppercase text-sm text-accent6'>total</p>
      </div>
    </section>
  )

  const dataBancos = [
    //{ title: 'Facturas por pagar', amount: deudasPagos.porPagar },
    //{ title: 'Facturas por cobrar', amount: deudasPagos.porCobrar },
    { title: 'Saldo en cuenta', amount: currentAcount?.saldo },
  ]

  const handleAddNewBank = () => {
    if (currentBank) {
      setShowPopup(true)
    } else {
      getWidgetToken(currentBusiness.id)
    }
  }

  /*const handleDisconnectAccount = async () => {
    if (accountToDisconnect) {
      try {
        // Aquí podrías hacer una llamada a la API para desconectar la cuenta
        console.log(`Desconectando cuenta: ${accountToDisconnect.nombreCuentaBanco}`)
        setUniqueAccounts(prev =>
          prev.filter(account =>
            account.numeroCuentaBanco !== accountToDisconnect.numeroCuentaBanco
          )
        )
        toast.success(`Cuenta ${accountToDisconnect.nombreCuentaBanco} desconectada correctamente`)
      } catch (error) {
        toast.error('No se pudo desconectar la cuenta')
        console.error('Error al desconectar la cuenta:', error)
      }
    }
    setShowDisconnect(false) // Cierra el pop-up
    setAccountToDisconnect(null) // Resetea la cuenta seleccionada
  }*/

  const handleIconClick = (account) => {
    setAccountToDisconnect(account)
    setShowDisconnect(true)
  }

  return (
    <Paper>
    <div className='my-10 space-y-6'>
      <div className='grid grid-cols-[2fr_1fr_1fr] gap-4'>
        {dataBancos.map((item, index) => (
          <CardInfo key={index} title={item.title} amount={item.amount} />
        ))}
        <div className='relative h-full'>
          <div
            className='w-full h-full max-w-[400px] border border-gray-300 hover:border-gray-400 px-3 py-2 rounded-full shadow cursor-pointer text-sm flex items-center'
            onClick={() => setIsOpen(!isOpen)}
          >
            {selectedBank || 'Todas las cuentas'}
          </div>
          {isOpen && (
            <div className='absolute z-10 mt-1 w-full max-w-[400px] bg-white border border-gray-300 rounded-lg shadow-lg'>
              {uniqueAccounts.map((account, index) => (
                <div
                  key={index}
                  className='flex justify-between items-center px-4 py-2 hover:bg-gray-100 cursor-pointer'
                  onClick={() => handleBankSelection(account)}
                >
                  <span>{`${account.nombreCuentaBanco} - ${account.numeroCuentaBanco}`}</span>
                  <button
                    onClick={(e) => {
                      e.stopPropagation()
                      handleIconClick(account)
                    }}
                    className='text-red-500 hover:text-red-700'
                  >
                    <PowerIcon />
                  </button>
                </div>
              ))}
            </div>
          )}
        </div>
        <Button
          onClick={handleAddNewBank}
          className='shadow-md border-none px-3 rounded-full bg-accent2 text-white text-xs w-auto max-w-[190px]'
          title='Agregar nuevo banco'
        />
      </div>
      <List
        transactionsAcount={filteredTransactions}
        currentAcount={currentAcount}
        setTransactionsAcount={setTransactionsAcount}
      />
      {openModalEditAcounts && (
        <ModalEditAcounts
          acounts={acounts}
          data={transactionsAcount}
          transactionsAcount={uniqueAccounts}
          open={openModalEditAcounts}
          setOpenModal={setOpenModalEditAcounts}
        />
      )}
    </div>
    {showPopup && (
      <div className='fixed inset-0 flex items-center justify-center bg-black bg-opacity-40 z-50'>
        <div className='relative bg-white px-6 py-4 rounded-lg shadow-xl w-96 text-center'>
          <button 
            onClick={() => setShowPopup(false)} 
            className='absolute top-2 right-2 text-gray-400 hover:text-gray-600'
          >
            <CloseIcon />
          </button>
          <h2 className='text-base text-gray-800 mt-2'>
            Ya tienes una cuenta vinculada.
          </h2>
          <p className='text-sm text-gray-600 mt-3'>
            Si deseas agregar una nueva cuenta, contactarse con el equipo de Luca.
          </p>
        </div>
      </div>
    )}
    {showDisconnect && (
          <div className='fixed inset-0 flex items-center justify-center bg-black bg-opacity-40 z-50'>
            <div className='relative bg-white px-6 py-4 rounded-lg shadow-xl w-96 text-center'>
              <button
                onClick={() => setShowDisconnect(false)}
                className='absolute top-2 right-2 text-gray-400 hover:text-gray-600'
              >
                <CloseIcon />
              </button>
              <h2 className='text-base text-gray-800 mt-2'>
                ¿Seguro que quieres desconectar esta cuenta?
              </h2>
              <p className='text-sm text-gray-600 mt-3'>
                Esta acción se realizada dentro de las proximas 24 horas.
              </p>
              <div className='flex justify-center gap-4 mt-4'>
                <CustomButton
                  className='rounded-full'
                  color='accent2'
                  title='Aceptar'
                />
                <CustomButton
                  onClick={() => setShowDisconnect(false)}
                  className='rounded-full'
                  color='primary'
                  title='Cancelar'
                />
              </div>
            </div>
          </div>
        )}
  </Paper>
  )
}

export default BancosTableInsights
