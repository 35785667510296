import { useState, useContext, useEffect } from 'react'
import GlobalContext from '../../../../../../../context/global-context'
import { toast } from 'react-toastify'

// MUI
import { Backdrop, TextField } from '@mui/material'
import SelectMUI from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Chip from '@mui/material/Chip'

// components
import { Text, Button, Select } from '../../../../../ui/index'
import TableMovements from './Table'
import { ExportCSV, ExportXLS } from '../../../../../table/ExportData'
import ExportPDFBankMovements from '../../../../../table/typesExportPDF/ExportPDFbankMovements'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'

// api
import { BankAPI } from '../../../../../../../api/bancos.api'

// utils
import {currentMonth,currentYear,monthsOptions as months,yearsOptions} from '../../../../../../utils/constant'
import { formatCurrency, formatDate } from '../../../../../../utils/formatter'
import FilterPagination from '../../../../../table/FilterPagination'
import CustomButton from '../../../../../ui/CustomButton'

const RoundedTextField = ({ placeholder, value, onChange, ...props }) => (
  <TextField
    fullWidth
    placeholder={placeholder}
    variant='outlined'
    size='small'
    className='bg-white rounded-full'
    InputProps={{
      style: {
        borderRadius: '20px',
      },
    }}
    value={value}
    onChange={onChange}
    {...props}
  />
)

const List = ({ transactionsAcount, currentAcount, setTransactionsAcount }) => {
  const [showModal, setShowModal] = useState(false)
  const [monthFilter, setMonthFilter] = useState(currentMonth)
  const [yearFilter, setYearFilter] = useState(currentYear)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [checkedDestacados] = useState(false)
  const [filteredTransactions, setFilteredTransactions] = useState(transactionsAcount)
  const [showFilters, setShowFilters] = useState(false)
  const [montoDesde, setMontoDesde] = useState('')
  const [montoHasta, setMontoHasta] = useState('')
  const { currentBusiness } = useContext(GlobalContext)
  const [searchTerm, setSearchTerm] = useState('')
  const [highlightedRows, setHighlightedRows] = useState({})
  const [selectedFilters, setSelectedFilters] = useState([])
  const [rutFilter, setRutFilter] = useState('')
  const bankAPI = new BankAPI()

  const Modal = () => {
    const { show, title, body } = showModal
    if (!show) return null
    return (
      <Backdrop sx={{ zIndex: 1600 }} open={true}>
        <div className='py-6 px-12 flex flex-col items-center justify-center bg-white'>
          <Text variant='h6' className='text-xl mb-4 text-center'>
            {title}
          </Text>
          {body}
          <button
            className='bg-primary text-white rounded-full px-4 py-2 hover:bg-primary/80 focus:outline-none shadow'
            onClick={() => setShowModal(false)}
          >
            Cerrar
          </button>
        </div>
      </Backdrop>
    )
  }

  const getDataListTransactionsAcount = async () => {
    try {
      const limit = 1000
      const formattedMonth = monthFilter.toString().padStart(2, '0') // Asegura dos dígitos en el mes
      // Validaciones de `currentAcount` y `currentBusiness`
      const productId = currentAcount?.id || 0 // Si no hay cuenta seleccionada, usa 0 para todas
      if (!currentBusiness || !currentBusiness.id) {
        console.error('currentBusiness o su id no están definidos.')
        return // Salir si no hay un negocio válido
      }
      // Llamar al API con valores validados
      const responseGetDataListTransactionsAcount = await bankAPI.getTransactionsSummary(
        productId, // ID de la cuenta (o 0)
        currentBusiness.id, // ID del negocio
        limit, // Límite de registros
        yearFilter, // Año
        parseInt(formattedMonth) // Mes formateado como número
      )
      // Actualizar estado con los datos
      setTransactionsAcount(responseGetDataListTransactionsAcount)
    } catch (error) {
      console.error(
        `Error al obtener movimientos (productoId=${currentAcount?.id}, businessId=${currentBusiness?.id}):`,
        error.response?.data || error.message
      )
      setTransactionsAcount([]) // Estado vacío en caso de error
    }
  }

  const handleSearchMovements = async () => {
    await toast.promise(getDataListTransactionsAcount(), {
      pending: 'Cargando datos... 🚀',
      success: 'Datos cargados exitosamente... 👌',
      error: 'No se pudieron cargar los datos 🤯'
    })
  }

  const dataToExport = transactionsAcount?.map(item => ({
    'Usuario Asignador Id': item.usuarioAsignadorId || 'ID no definido',
    'Usuario Asignador': item.usuarioAsignador || 'Usuario no definido',
    'Nombre Banco': item.nombreCuentaBanco || 'Banco no definido',
    'ID': item.id || 'ID no definido',
    'Fecha': item.fecha ? formatDate(item.fecha) : 'Fecha no definida',
    'Monto Abono': item.montoAbono ? formatCurrency(item.montoAbono) : formatCurrency(0),
    'Monto Cargo': item.montoCargo ? formatCurrency(item.montoCargo) : formatCurrency(0),
    'Número de Cuenta': item.numeroCuentaBanco || 'Cuenta no definida',
    'Descripción': item.descripcion || 'Sin descripción',
    'Estado': item.status || 'Estado no definido',
    'Fecha de Pago': item.fechaPago ? formatDate(item.fechaPago) : 'Fecha no definida',
    'Aprobado': item.aprobado ? 'Sí' : 'No'
  })) || []

  const icons = [
    <ExportCSV
      key='export-csv'
      data={dataToExport}
      title='bancarios'
      year={yearFilter}
      month={monthFilter}
    />,
    <ExportXLS
      key='export-xls'
      data={dataToExport}
      title='bancarios'
      year={yearFilter}
      month={monthFilter}
    />,
    <ExportPDFBankMovements
      key='export-pdf'
      data={transactionsAcount}
      setShowModal={setShowModal}
      title='bancarios'
      year={yearFilter}
      month={monthFilter}
    />
  ]

  const monthsOptions = months.map(monthOption => ({
    ...monthOption,
    disabled: yearFilter == currentYear && monthOption.value > currentMonth
  }))

  const filterTransactions = () => {
    let filtered = transactionsAcount
    if (selectedFilters.includes('abono')) {
      filtered = filtered.filter(item => item.montoAbono > 0)
    } 
    if (selectedFilters.includes('cargo')) {
      filtered = filtered.filter(item => item.montoCargo > 0)
    }
    if (selectedFilters.includes('destacados')) {
      filtered = filtered.filter(item => highlightedRows[item.id])
    }
    if (selectedFilters.includes('pendiente')) {
      filtered = filtered.filter(item => item.status === 'PENDIENTE')
    }
    if (selectedFilters.includes('conciliados')) {
      filtered = filtered.filter(item => item.status === 'CONCILIADO')
    }
    if (selectedFilters.includes('sugerido')) {
      filtered = filtered.filter(item => item.status === 'SUGERIDO')
    }
    if (searchTerm.trim() !== '') {
      const searchWords = searchTerm.toLowerCase().split(' ')
      filtered = filtered.filter(transaction =>
        searchWords.every(word => transaction.descripcion?.toLowerCase().includes(word))
      )
    }
    setFilteredTransactions(filtered)
  }
  
  useEffect(() => {
    filterTransactions()
  }, [selectedFilters, highlightedRows, transactionsAcount, searchTerm])

  const handleToggleFilters = () => setShowFilters(!showFilters)

  const formatNumber = (num) => {return new Intl.NumberFormat().format(num)}
  
  const handleMontoChange = (e, setMonto) => {
    let value = e.target.value
    value = value.replace(/[^0-9]/g, '')
    const numValue = value ? parseInt(value, 10) : ''
    setMonto(numValue !== '' ? formatNumber(numValue) : '')
  }

  const resetFilters = () => {
    setMontoDesde('')
    setMontoHasta('')
    setRutFilter('')
    setSelectedFilters([])
    setShowFilters(false)
    setMonthFilter(currentMonth)
    setYearFilter(currentYear)
    setFilteredTransactions(transactionsAcount)
  }

  const handleRutChange = (e) => {
    setRutFilter(e.target.value)
  }

  const handleSearch = () => {
    const montoDesdeNum = montoDesde ? parseInt(montoDesde.replace(/[^0-9]/g, ''), 10) : null
    const montoHastaNum = montoHasta ? parseInt(montoHasta.replace(/[^0-9]/g, ''), 10) : null
    const filtered = transactionsAcount.filter(transaction => {
      const monto = parseFloat(transaction.montoAbono) || parseFloat(transaction.montoCargo)
      const descripcion = transaction.descripcion?.toLowerCase().replace(/[.-]/g, '') || ''
      const rutCleaned = rutFilter.toLowerCase().replace(/[.-]/g, '')
      return (
        (montoDesdeNum ? monto >= montoDesdeNum : true) &&
        (montoHastaNum ? monto <= montoHastaNum : true) &&
        (rutCleaned ? descripcion.includes(rutCleaned) : true)
      )
    })
    setFilteredTransactions(filtered)
    setShowFilters(false)
  }

  const options = [
    { label: 'Abono', value: 'abono' },
    { label: 'Cargo', value: 'cargo' },
    { label: 'Destacados', value: 'destacados' },
    { label: 'Pendiente', value: 'pendiente' },
    { label: 'Conciliado', value: 'conciliados' },
    { label: 'Sugerido', value: 'sugerido' },
  ];
  
  const handleFilterChange = (event) => {
    setSelectedFilters(event.target.value);
  }

  useEffect(() => {
    const storedHighlights = localStorage.getItem('highlightedRows')
    if (storedHighlights) {
      setHighlightedRows(JSON.parse(storedHighlights))
    }
  }, [])
  
  useEffect(() => {
    if (Object.keys(highlightedRows).length > 0) {
      localStorage.setItem('highlightedRows', JSON.stringify(highlightedRows))
    }
  }, [highlightedRows])
  
  const toggleHighlight = (id) => {
    setHighlightedRows((prev) => {
      const newState = { ...prev, [id]: !prev[id] }
      return newState
    })
  }

  useEffect(() => {
    if (checkedDestacados) {
      setFilteredTransactions(transactionsAcount.filter(tx => highlightedRows[tx.id]))
    } else {
      setFilteredTransactions(transactionsAcount)
    }
  }, [checkedDestacados, transactionsAcount, highlightedRows])
  

  return (
    <>
        <div className='relative'>
          <div className='absolute top-[-165px] right-0 z-20 flex space-x-4'>
            {icons.map((icon) => icon)}
          </div>
        </div>
        <div className='flex flex-wrap  justify-between items-center mb-4'>
            <div className='flex flex-wrap gap-2'>
            <FilterPagination
              rowsPerPage={rowsPerPage}
              handleChangeRowsPerPage={setRowsPerPage}
            />
              <Select
                key={`month-${monthFilter}`}
                options={monthsOptions}
                defaultValue={monthFilter}
                onChange={(e) => setMonthFilter(e.target.value)}
              />
              <Select
                key={`year-${yearFilter}`}
                options={yearsOptions}
                defaultValue={yearFilter}
                onChange={(e) => setYearFilter(e.target.value)}
              />
              <div className='relative'>
              <input
                type='text'
                placeholder='Buscar'
                className='text-black border border-light-gray rounded-full h-[30px] px-2'
                value={searchTerm}
                onChange={(e) => {
                  setSearchTerm(e.target.value)
                  filterTransactions()
                }}
              />
                <button
                  className='absolute right-2 top-1/2 transform -translate-y-1/2 bg-white  hover:bg-gray-200 rounded-full'
                  onClick={handleToggleFilters}
                >
                  <ArrowDropDownIcon className='text-gray-600' />
                </button>
              {showFilters && (
              <div
                className='absolute top-full right-0 mt-2 p-4 border rounded bg-white shadow-lg z-50'
                style={{ width: '350px' }}
              >
                <div className='p-4 space-y-4'>
                  {[
                    { label: 'Monto', fields: ['Desde', 'Hasta'] },
                    { label: 'RUT(s)', fields: ['11111111-K'] },
                    { label: 'Descripción', fields: ['Escribe aquí'] },
                  ].map(({ label, fields }, idx) => (
                    <div key={idx}>
                      <label className='block text-sm font-medium text-gray-700 mb-1'>
                        {label}
                      </label>
                      {fields.length > 1 ? (
                        <div className='flex space-x-2'>
                          {fields.map((placeholder, index) => {
                            if (placeholder === 'Desde') {
                              return (
                                <RoundedTextField
                                  key={index}
                                  placeholder={placeholder}
                                  value={montoDesde}
                                  onChange={(e) => handleMontoChange(e, setMontoDesde)} 
                                />
                              )
                            }
                            if (placeholder === 'Hasta') {
                              return (
                                <RoundedTextField
                                  key={index}
                                  placeholder={placeholder}
                                  value={montoHasta}
                                  onChange={(e) => handleMontoChange(e, setMontoHasta)}
                                />
                              )
                            }
                            return null
                          })}
                        </div>
                      ) : label === 'RUT(s)' ? (
                        <RoundedTextField
                          placeholder={fields[0]}
                          value={rutFilter}
                          onChange={handleRutChange}
                        />
                      ) : (
                        <RoundedTextField placeholder={fields[0]} />
                      )}
                    </div>
                  ))}
                </div>
                <div className='flex justify-end items-center gap-4 p-4 border-t border-gray-300'>
                  <CustomButton
                    className='rounded-full'
                    color='primary'
                    title='Cancelar'
                    onClick={() => setShowFilters(false)}
                  />
                  <CustomButton
                    className='rounded-full'
                    color='accent2'
                    title='Buscar'
                    onClick={() => {filterTransactions(); setShowFilters(false); handleSearch() }}
                  />
                </div>
              </div>
              )}
            </div>
              <SelectMUI
              multiple
              displayEmpty
              value={selectedFilters}
              onChange={handleFilterChange}
              sx={{ width: 200, height: 30, borderRadius: '9999px', paddingX: 1, '& .MuiOutlinedInput-notchedOutline': { borderRadius: '9999px' },}}
              renderValue={(selected) => {
                if (selected.length === 0) {
                  return <span className='text-gray-400'>Filtros</span>;
                }
                return (
                  <div className='flex gap-2'>
                    {selected.map((value) => (
                      <Chip 
                      key={value} 
                      label={options.find(option => option.value === value)?.label} 
                      className='rounded-full bg-blue-500 text-white px-2 py-1'
                      />
                    ))}
                  </div>
                );
              }}
            >
              {options.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </SelectMUI>
              <Button
              className='rounded-full'
              color='primary'
              title='BUSCAR'
              onClick={e => handleSearchMovements(e, yearFilter, monthFilter)}
              />
              <CustomButton
                className='rounded-full'
                color='accent3'
                title='LIMPIAR'
                onClick={resetFilters}
              />
            </div>
        </div>
        
        <TableMovements
          rows={filteredTransactions}
          getData={getDataListTransactionsAcount}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          transactions={filteredTransactions}
          highlightedRows={highlightedRows}
          toggleHighlight={toggleHighlight}
        />
        <Modal onClose={() => setShowModal(false)} />
    </>
  )
}

export default List