import clsx from 'clsx'
import { Text } from '../../../../../ui'
import { useState } from 'react'
import { formatDate } from '../../../../../../utils/formatter'

const TableMovVincular = ({ onCheckboxChange, rows }) => {
    const [selectedCheckboxes, setSelectedCheckboxes] = useState({})

    /*const handleCheckboxToggle = (id) => {
        const updatedSelection = {
          ...selectedCheckboxes,
          [id]: !selectedCheckboxes[id],
        }
        setSelectedCheckboxes(updatedSelection)
    
        const isAnySelected = Object.values(updatedSelection).some((value) => value)
        onCheckboxChange(isAnySelected)
    }*/

    const handleCheckboxToggle = (item) => {
        const updatedSelection = { [item.id]: !selectedCheckboxes[item.id] }; // Solo uno seleccionado
        setSelectedCheckboxes(updatedSelection);
    
        const isSelected = updatedSelection[item.id];
        onCheckboxChange(isSelected ? item : null); // Pasar el objeto completo
    }

    const styles = {
        table: 'w-full text-[#000231] text-xs border-collapse leading-6 overflow-x-auto table-fixed',
        tr: 'border-b border-gray-200 hover:bg-gray-100/50',
        td: 'text-left align-top p-4 whitespace-nowrap max-w-52 overflow-x-auto no-scrollbar-space-y-1',
        th: 'border-2 border-transparent border-b-gray-200 text-left p-4 whitespace-nowrap cursor-pointer',
        boldText: 'font-bold',
        text: 'text-xs text-gray-500',
        smallCol: 'w-[45px]',
        wideCol: 'w-[300px]',
        mediumCol: 'w-[140px]',
    }

    return (
        <div className='p-4'>
            <table className={styles.table}>
                <thead className='justify-center items-center'>
                    <tr>
                    <th className={clsx(styles.th, styles.smallCol)}></th>
                    <th className={clsx(styles.th, styles.mediumCol)}>Fecha</th>
                    <th className={clsx(styles.th, styles.mediumCol)}>Cuenta corriente</th>
                    <th className={clsx(styles.th, styles.wideCol)}>Descripción</th>
                    <th className={clsx(styles.th, styles.mediumCol)}>Abono</th>
                    <th className={clsx(styles.th, styles.mediumCol)}>Cargo</th>
                    </tr>
                </thead>
                <tbody className='divide-y divide-gray-200'>
                    {rows.map((item) => (
                        <tr key={item.id} className={styles.tr}>
                            <td className={clsx(styles.td, styles.smallCol)}>
                                <input type='checkbox' className='rounded' checked={!!selectedCheckboxes[item.id]} onChange={() => handleCheckboxToggle(item)}/>
                            </td>
                            <td className={clsx(styles.td, styles.mediumCol)}>
                                <Text className={styles.boldText}>{formatDate(item.fecha)}</Text>
                            </td>
                            <td className={clsx(styles.td, styles.mediumCol)}>
                                <Text className={styles.boldText}>{item.nombreCuentaBanco}</Text>
                                <Text className={styles.text}>{item.numeroCuentaBanco}</Text>
                            </td>
                            <td className={clsx(styles.td, styles.wideCol)}>
                                <Text className={styles.boldText}>{item.descripcion}</Text>
                            </td>
                            <td className={clsx(styles.td, styles.mediumCol)}>
                                <Text className={styles.boldText}>{item.amount > 0 ? (item.amount).toLocaleString('es-CL') : 0}</Text>
                            </td>
                            <td className={clsx(styles.td, styles.mediumCol)}>
                                <Text className={styles.boldText}>{item.amount < 0 ? (Math.abs(item.amount).toLocaleString('es-CL')) : 0}</Text>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default TableMovVincular
