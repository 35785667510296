import { FC, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { sendEmailVerification } from "firebase/auth";
import { UserAuth } from "../../../../../context/AuthContext";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";
import { auth } from "../../../../../firebase";

export const ResentValidationEmail: FC = () => {
  const { user } = UserAuth();
  const navigate = useNavigate();
  // Estado que indica si el correo fue verificado (basado en user.emailVerified)
  const [isValidated, setIsValidated] = useState<boolean>(false);

  // Polling para actualizar el estado de validación llamando a user.reload()
  useEffect(() => {
    const interval = setInterval(async () => {
      const currentUser = auth.currentUser;
      if (currentUser) {
        await currentUser.reload();
        setIsValidated(currentUser.emailVerified);
        if (currentUser.emailVerified) {
          clearInterval(interval);
        }
      }
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  // Navega automáticamente a /auth/complete-signup cuando el correo está validado
  useEffect(() => {
    if (isValidated) {
      navigate("/auth/complete-signup", { replace: true });
    }
  }, [isValidated, navigate]);

  const resendCode = async () => {
    try {
      await toast.promise(sendEmailVerification(user), {
        pending: {
          render: () => "Cargando usuarios",
          className: "bg-accent2 text-white",
        },
        success: {
          render() {
            return "Hemos enviado un correo de verificación 👌";
          },
          onClose() {
            toast.success("Revisa tu correo para completar el registro");
          },
          className: "bg-accent2 text-white",
        },
        error: {
          render(error) {
            const myError = error.data as AxiosError<{ message: string }>;
            if (myError.response?.status) {
              return myError.response?.data.message;
            }
            return "No se pudo enviar el correo 🤯";
          },
          className: "bg-accent1 text-white",
        },
      });
    } catch (error) {
      return null;
    }
  };

  return (
    <div className="flex flex-col gap-6 justify-between mt-6 w-2/3 mx-auto">
      <div className="mb-6">
        <h3 className="mb-3 text-3xl font-bold text-center">Correo Enviado</h3>
        <p>
          Si no has recibido el correo de verificación, haz clic en el botón para reenviar el código.
        </p>
      </div>

      <div className="flex flex-col gap-2 mt-12 w-2/3 mx-auto">
        <div className="flex items-center justify-center gap-8">
          <button
            onClick={resendCode}
            type="button"
            className="btn-primary rounded-full"
          >
            <span>Reenviar</span>
            <FontAwesomeIcon size="lg" icon={faPaperPlane} />
          </button>
        </div>
      </div>
    </div>
  );
};
