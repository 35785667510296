import React, { useState, useEffect } from 'react'
import { formatCurrency } from '../../../../../utils/formatter'
import { OrganizationAPI } from '../../../../../../api/organization.api'
import CancelIcon from '@mui/icons-material/HighlightOffOutlined'
import AddIcon from '@mui/icons-material/AddCircleOutlineOutlined'
import { TextField } from '@mui/material'
import { cuentaAux, editLuca, usuarioLuca } from '../../../../data/dataComprobante'
import TablaAuxiliar from './TablaAuxiliar'
import Loader from '../../../../ui/Loader'

const TablaNuevoComprobante = ({ 
  setTotalDebe, 
  setTotalHaber, 
  totalDebe, 
  totalHaber,
  setLibros,
  glosaPrincipal,
  isRequired,
  setListaAuxiliar,
  context,
  initialData = [],
  isBorrador,
  validation,
  razonSocial,
  rut,
  nombreComprobante,
  vencimiento,
  folio,
  createUserId,
  listaDataAuxiliar
}) => {
  const [dataCuentas, setDataCuentas] = useState([])
  const [filteredCuentas, setFilteredCuentas] = useState([])
  const [rows, setRows] = useState([])
  const [activeRowIndex, setActiveRowIndex] = useState(null)
  const [activeField, setActiveField] = useState('')
  const organizationAPI = new OrganizationAPI()
  const [rowsWithAuxiliar, setRowsWithAuxiliar] = useState([])
  const [isLoaderVisible, setIsLoaderVisible] = useState(false)
  const isRowEnabled = (codigoCuenta) => {
    if (parseInt(validation) === 0) {
      if (createUserId === null) {
        return codigoCuenta.startsWith('3');
      } else {
        return true;
      }
    }
    return true
  }
  
  useEffect(() => {
    if (context === 'edit') {
      if (Array.isArray(initialData) && initialData.length > 0) {
        setRows(
          initialData.map((row) => {
            const cuentaEncontrada = dataCuentas.find(
              (cuenta) => cuenta.id === row.cuentaContableId
            )
            return {
              searchTerm: `${row.codigoCuenta || cuentaEncontrada?.codigoCuenta || ''} - ${row.nombreCuenta || cuentaEncontrada?.nombreCuenta || ''}`,
              glosa: row.glosa || '',
              debe: row.debe || 0,
              haber: row.haber || 0,
              cuentaContableId: row.cuentaContableId || null,
              isDisabled: !isRowEnabled(row.codigoCuenta || cuentaEncontrada?.codigoCuenta || ''),
              isModified: false,
            }
          })
        )
        const matchingIndexes = initialData.reduce((indexes, row, index) => {
          if (cuentaAux.includes(row.codigoCuenta)) {
            indexes.push(index)
          }
          return indexes
        }, [])
        setRowsWithAuxiliar(matchingIndexes)
      } else if (initialData.length === 0 && isBorrador && rows.length === 0) {
        setRows([
          { searchTerm: '', glosa: '', debe: '', haber: '' },
          { searchTerm: '', glosa: '', debe: '', haber: '' },
        ])
      }
    }
  }, [initialData, context, isBorrador, createUserId, validation, dataCuentas])  

  useEffect(() => {
    if (context === 'nuevo') {
      setRows([
        { searchTerm: '', glosa: '', debe: '', haber: '' },
        { searchTerm: '', glosa: '', debe: '', haber: '' },
      ])
    }
  }, [context])

  const handleCuentaInputChange = (index, value) => {
  const updatedRows = [...rows]
  updatedRows[index].searchTerm = value
  setRows(updatedRows)

  const tieneDebeMayorCero = listaDataAuxiliar?.[0]?.debe > 0
  const filtered = dataCuentas.filter((cuenta) => {
    const cumpleFiltro =
      cuenta.codigoCuenta.includes(value) || cuenta.nombreCuenta?.toLowerCase().includes(value.toLowerCase())

    if (createUserId === null) {
      if (tieneDebeMayorCero) {
        return cumpleFiltro && editLuca.includes(cuenta.codigoCuenta)
      }
      return cumpleFiltro && usuarioLuca.includes(cuenta.codigoCuenta)
    }
    return cumpleFiltro 
  })

  setFilteredCuentas(filtered)
  setActiveRowIndex(index)
  setActiveField('searchTerm')
  setLibros(updatedRows)
}
  
  const handleCuentaSelect = (index, cuenta) => {
    if (isRequired && (!cuenta.codigoCuenta || !cuenta.nombreCuenta) ) {
      alert('La cuenta seleccionada es obligatoria.')
      return
    }
    const updatedRows = [...rows]
    updatedRows[index] = {
      ...updatedRows[index],
      nombreCuenta: cuenta.nombreCuenta,
      codigoCuenta: cuenta.codigoCuenta,
      cuentaContableId: cuenta.id,
      searchTerm: `${cuenta.codigoCuenta} - ${cuenta.nombreCuenta}`,
      isDisabled: !isRowEnabled(cuenta.codigoCuenta),
      isModified: true
    }
    setRows(updatedRows)
    setFilteredCuentas([])
    setActiveRowIndex(null)
    setLibros(updatedRows)

    const isAux = cuentaAux.includes(cuenta.codigoCuenta.toString())
    setRowsWithAuxiliar((prev) =>
      isAux ? [...prev, index] : prev.filter((i) => i !== index)
    )
  }
  
  const handleDebeChange = (index, value) => {
    const updatedRows = [...rows]
    updatedRows[index].debe = value
    if (value) {
      updatedRows[index].haber = 0
    }
    setRows(updatedRows)
    setLibros(updatedRows)
  }
  
  const handleHaberChange = (index, value) => {
    const updatedRows = [...rows]
    updatedRows[index].haber = value
    if (value) {
      updatedRows[index].debe = 0
    }
    setRows(updatedRows)
    setLibros(updatedRows)
  }

  const functionGetData = async () => {
    try {
      setIsLoaderVisible(true)
      const responseCuentas = await organizationAPI.getAllCuentas()
      setDataCuentas(responseCuentas.data)
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoaderVisible(false)
    }
  }

  useEffect(() => {
    functionGetData()
  }, [])

  useEffect(() => {
    const newTotalDebe = rows.reduce((sum, row) => sum + (parseFloat(row.debe) || 0), 0)
    const newTotalHaber = rows.reduce((sum, row) => sum + (parseFloat(row.haber) || 0), 0)

    setTotalDebe(newTotalDebe)
    setTotalHaber(newTotalHaber)
  }, [rows, setTotalDebe, setTotalHaber])

  useEffect(() => {
    setLibros(rows)
  }, [rows])

  return (
    <div className='border rounded-lg p-4 mt-4 bg-white'>
      <Loader visible={isLoaderVisible} />
      <table className='min-w-full table-fixed border-collapse text-sm text-left'>
        <thead>
          <tr className='p-2 text-center text-xs'>
            <th className='w-2/5'>Cuenta</th>
            <th className='w-1/5'>Glosa</th>
            <th className='w-1/8'>Debe</th>
            <th className='w-1/8'>Haber</th>
            <th className='w-1/8'></th>
          </tr>
        </thead>
        <tbody>
          {rows.map((row, index) => (
            <React.Fragment key={index}>
              <tr>
                <td className='py-3 px-2 w-2/5'>
                  <div className='relative'>
                    <TextField
                      type='text'
                      placeholder='Buscar por código o nombre'
                      value={row.searchTerm || ''}
                      onChange={(e) => handleCuentaInputChange(index, e.target.value)}
                      className='rounded p-1 w-full text-xs'
                      variant='standard'
                      required={isRequired}
                      disabled={row.isDisabled}
                    />
                    {index === activeRowIndex && activeField === 'searchTerm' && (
                      <ul className='absolute z-10 bg-white border rounded w-full mt-1'>
                        {filteredCuentas.map((cuenta) => (
                          <li
                            key={cuenta.id}
                            className='cursor-pointer p-2 hover:bg-gray-200'
                            onClick={() => handleCuentaSelect(index, cuenta)}
                          >
                            {cuenta.codigoCuenta} - {cuenta.nombreCuenta}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </td>
                <td className='py-3 px-2 w-1/5'>
                  <TextField
                    name='glosa'
                    className='rounded p-1 w-full'
                    variant='standard'
                    value={row.glosa || ''}
                    onBlur={(e) => {
                      if (!e.target.value.trim()) {
                        const updatedRows = [...rows]
                        updatedRows[index].glosa = e.target.value || glosaPrincipal
                        setRows(updatedRows)
                        setLibros(updatedRows)
                      }
                    }}
                    onChange={(e) => {
                      const updatedRows = [...rows]
                      updatedRows[index].glosa = e.target.value
                      setRows(updatedRows)
                      setLibros(updatedRows)
                    }}
                    required={isRequired}
                    disabled={row.isDisabled}
                  />
                </td>
                <td className='w-1/8 py-3 px-2'>
                  <TextField
                    name='debe'
                    value={row.debe}
                    onChange={(e) => handleDebeChange(index, e.target.value)}
                    className='rounded p-1 w-full'
                    variant='standard'
                    onKeyDown={(e) => {
                      if (['-', '+', '.', 'e'].includes(e.key)) {
                        e.preventDefault()
                      }
                    }}
                    required={isRequired}
                    disabled={row.isDisabled}
                  />
                </td>
                <td className='w-1/8 py-3 px-2'>
                  <TextField
                    name='haber'
                    value={row.haber}
                    onChange={(e) => handleHaberChange(index, e.target.value)}
                    className='rounded p-1 w-full'
                    variant='standard'
                    onKeyDown={(e) => {
                      if (['-', '+', '.', 'e'].includes(e.key)) {
                        e.preventDefault()
                      }
                    }}
                    required={isRequired}
                    disabled={row.isDisabled}
                  />
                </td>
                <td className='flex items-center space-x-2 py-2 w-1/8'>
                  {index === rows.length - 1 && (
                    <button
                      onClick={() =>
                        setRows([...rows, { searchTerm: '', debe: '', haber: '' }])
                      }
                      disabled={row.isDisabled}
                    >
                      <AddIcon />
                    </button>
                  )}
                  {index >= 2 && (
                    <button
                      onClick={() => setRows(rows.filter((_, i) => i !== index))}
                      disabled={row.isDisabled}
                    >
                      <CancelIcon />
                    </button>
                  )}
                </td>
              </tr>
              {rowsWithAuxiliar.includes(index) && (
                <tr>
                  <td colSpan='5'>
                    <TablaAuxiliar
                      setListaAuxiliar={(auxiliar) => {
                        const updatedRows = [...rows]
                        updatedRows[index].auxiliar = auxiliar
                        setRows(updatedRows)
                        setLibros(updatedRows)
                        setListaAuxiliar(auxiliar)
                      }}
                      razonSocial={razonSocial}
                      rut={rut}
                      nombreComprobante={nombreComprobante}
                      vencimiento={vencimiento}
                      folio={folio}
                      createUserId={createUserId} 
                      validation={validation}
                    />
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
          <tr>
            <td colSpan='2' className='text-right font-bold py-2'>Total</td>
            <td className='font-bold py-2'>{formatCurrency(totalDebe)}</td>
            <td className='font-bold py-2'>{formatCurrency(totalHaber)}</td>
            <td className='w-1/8'></td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default TablaNuevoComprobante