import { createContext, useContext, useEffect, useState } from 'react'
import { signInWithEmailAndPassword, signOut } from '@firebase/auth'
import { auth } from '../firebase'
import GlobalContext from './global-context'
import { REACT_APP_BASE_URL } from '../components/utils/constant'
import { useNavigate } from 'react-router-dom'
const UserContext = createContext({})
const savedToken = localStorage.getItem('backendToken')
import { onAuthStateChanged } from 'firebase/auth'
import { BaseHTTP } from '../api/base.http'
import LogoutAlert from '../components/modules/ui/LogOut'
import { toast } from 'react-toastify';

/**
 * Provides authentication context to components and hooks.
 * @typedef {Object} AuthContextValue
 * @property {Function} login - Function to log in a user with email and password.
 * @property {Function} logout - Function to log out the current user.
 * @property {import('@firebase/auth').User} user - Current authenticated user object or null.
 * @property {string|null} token - Authentication token.
 * @property {boolean} isAuthenticated - Function to set authentication token.
 * @property {Function} setIsAuthenticated - Function to set authentication token.
 * @property {boolean} isError - Function to set authentication token.
 * @property {Function} setIsError - Function to set authentication token.
 * @property {Function} setToken - Function to set authentication token.
 * @property {boolean} isLoaded - Loading state indicator.
 * @property {Function} setIsloaded - Loading state indicator.
 * @property {Function} reset - Function to reset authentication state.
 * @property {Function} setUser - Function to set authenticated user object.
 */

/**
 * Provider component for authentication context.
 * @param {Object} props - React component props.
 * @param {React.ReactNode} props.children - Child components to be wrapped by the provider.
 * @returns {JSX.Element} JSX element wrapping the child components with authentication context.
 */
export const AuthContextProvider = ({ children }) => {
  const { 
    setBusiness, 
    setCurrentBusiness, 
    setCurrentBank, 
    ui: { setLoader, setDialog, setDialogForm, setBlockedPeriods }
  } = useContext(GlobalContext)
  const [user, setUser] = useState(null)
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false)
  const [isError, setIsError] = useState(false)
  const [token, setToken] = useState(savedToken || null)
  const [logoutAlertOpen, setLogoutAlertOpen] = useState(false)
  const navigate = useNavigate()
  const INACTIVITY_TIMEOUT = 15 * 60 * 1000 // 15 minutos en milisegundos
  let inactivityTimer

  const reset = () => {
    setUser(null)
    setToken(null)
    setIsAuthenticated(false)
    setIsLoaded(false)
    setIsError(false)

    // Reiniciamos los estados del GlobalContext
    setBusiness(null)
    setCurrentBusiness(undefined)
    setCurrentBank(undefined)
    // Reiniciamos la UI a sus valores predeterminados
    setLoader({ visible: false, text: '' })
    setDialog({ title: '', body: '', btnText: '', open: false })
    setDialogForm({ title: '', body: '', btnTextClose: '', btnTextOpen: '', open: false })
    setBlockedPeriods([])
  }

  const loginWithBackend = async (firebaseToken) => {
    const url = REACT_APP_BASE_URL
    return BaseHTTP.getInstance().http.post(`${url}/v1/auth/login`, {
      token: firebaseToken,
    })
  }

  const login = async (email, password) => {
    let userCredential
    let firebaseToken

    try {
      userCredential = await signInWithEmailAndPassword(auth, email, password)

      if (!userCredential.user.emailVerified) {
        await signOut(auth); // Cerrar sesión automáticamente
        toast.error('Por favor, verifica tu correo antes de iniciar sesión.');
        return { status: 403, message: 'Por favor, verifica tu correo antes de iniciar sesión.' };
      }

      firebaseToken = await userCredential.user.getIdToken()
    } catch (error) {
      if (error.code === 'auth/invalid-email') {
        return { status: 400, message: 'Correo electrónico inválido.' }
      } else if (error.code === 'auth/invalid-credential') {
        return { status: 401, message: error.message }
      } else if (error.code === 'auth/user-not-found') {
        return { status: 404, message: 'Usuario no encontrado.' }
      }
      return { status: 500, message: 'Error inesperado.' }
    }

    const backendResponse = await loginWithBackend(firebaseToken).catch((error) => error);

    // Validaciones de error
    if (backendResponse.status === 401 || backendResponse.status === 404) {
      return backendResponse;
    }
    if (backendResponse.status >= 400) {
      return { status: 500, message: 'Error inesperado.' };
    }
    
    const backendToken = await loginWithBackend(firebaseToken).catch((error) => error)
    if (backendToken.status === 401 || backendToken.status === 404) {
      return backendToken
    }
    if (backendToken.status >= 400) {
      return { status: 500, message: 'Error inesperado.' }
    }

    const token = backendToken?.data?.token
    const paso = backendResponse?.data?.paso; // <-- rescatamos "paso"
    BaseHTTP.getInstance().setBackendToken(token)
    setIsAuthenticated(true)
    setIsLoaded(true)
    setToken(token)
    setUser(userCredential.user)

    return { status: 200, backendToken: token, paso: paso }
  }

  const logout = async () => {
    try {
      setIsAuthenticated(false)
      await signOut(auth)
      setCurrentBusiness(undefined)
      setCurrentBank(undefined)
      setLoader({ visible: false, text: '' })
      setDialog({ title: '', body: '', btnText: '', open: false })
      setDialogForm({ title: '', body: '', btnTextClose: '', btnTextOpen: '', open: false })
      setBlockedPeriods([])
      clearInactivityTimer()
      navigate('/auth/signin', { replace: true })
      localStorage.clear()
      window.location.reload()
    } catch (error) {
      console.error('Error al cerrar sesión:', error)
    }
  }

  const resetInactivityTimer = () => {
    clearTimeout(inactivityTimer)
    inactivityTimer = setTimeout(() => {
      logout()
      setLogoutAlertOpen(true)
    }, INACTIVITY_TIMEOUT)
  }

  const clearInactivityTimer = () => {
    clearTimeout(inactivityTimer)
  }

  const handleUserActivity = () => {
    if (isAuthenticated) {
      resetInactivityTimer()
    }
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user && user?.emailVerified) {
        try {
          setUser(user)
          const firebaseToken = await user.getIdToken()
          const { data } = await loginWithBackend(firebaseToken)
          BaseHTTP.getInstance().setBackendToken(data.token)
          setIsAuthenticated(true)
        } catch (error) {
          setIsError(true)
        } finally {
          setIsLoaded(true)
        }
      } else {
        setUser(null)
        setIsAuthenticated(false)
        setIsLoaded(true)
        setIsError(false)
        reset()
      }
    })
    return unsubscribe
  }, [])

  useEffect(() => {
    const events = ['mousemove', 'keydown', 'scroll', 'click']
    if (isAuthenticated) {
      events.forEach((event) => window.addEventListener(event, handleUserActivity))
      resetInactivityTimer()
    }
    return () => {
      events.forEach((event) => window.removeEventListener(event, handleUserActivity))
      clearInactivityTimer()
    }
  }, [isAuthenticated])

  return (
    <UserContext.Provider
      value={{
        login,
        logout,
        user,
        isAuthenticated,
        setIsAuthenticated,
        isError,
        setIsError,
        token,
        setToken,
        isLoaded,
        setIsLoaded,
        reset,
        setUser,
      }}
    >
      {children}
      <LogoutAlert
        open={logoutAlertOpen}
        onClose={() => setLogoutAlertOpen(false)}
      />
    </UserContext.Provider>
  )
}

/**
 * Hook to access authentication context.
 * @returns {AuthContextValue} Authentication context values and functions.
 */
export const UserAuth = () => useContext(UserContext)