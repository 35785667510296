import { PDFDownloadLink, Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer'
import { formatCurrency, formatDate } from '../../../utils/formatter'

const cols = [
  { header: 'Fecha', width: 80 },
  { header: 'Cuenta Banco', width: 170 },
  { header: 'Descripcion', width: 170 },
  { header: 'Abono', width: 120 },
  { header: 'Cargo', width: 120 },
  { header: 'Status', width: 80 }
]

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    padding: 20
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 10
  },
  title: {
    textAlign: 'center',
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 10
  },
  table: {
    display: 'table',
    width: '100%',
    marginVertical: 10,
    fontSize: 8
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  headerRow: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#000',
    fontWeight: 'bold',
    marginBottom: 4,
    paddingBottom: 2
  },
  col: {
    padding: 4
  },
  signatureSection: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 20
  },
  signatureBlock: {
    width: '40%',
    textAlign: 'center'
  },
  signatureLine: {
    borderBottomWidth: 1,
    borderBottomColor: '#000',
    width: '100%',
    marginBottom: 2
  },
  boldText: {
    fontSize: 8,
    fontWeight: 'bold'
  },
  text: {
    fontSize: 8
  }
})

const ExportPDFBankMovements = ({ data, title, year, month, currentBusiness, rut }) => {
  const MyDocument = () => {
    const rowsPerPage = 10
    const pageCount = Math.ceil(data.length / rowsPerPage)
    // const pages = Array.from({ length: pageCount }, (_, i) =>
    //   data.slice(i * rowsPerPage, i * rowsPerPage + rowsPerPage)
    // )

    return (
      <Document>
        {[...Array(pageCount)].map((_, pageIndex) => (
          <Page key={pageIndex} orientation='landscape' size='A4' style={styles.page}>
            <View style={styles.header}>
              <View>
                <Text style={{ fontSize: 8 }}>Resumen Movimientos</Text>
                <Text style={{ fontSize: 8 }}>{currentBusiness}</Text>
                <Text style={{ fontSize: 8 }}>RUT: {rut}</Text>
              </View>
              <Text style={styles.title}>{title}</Text>
              <Text style={{ fontSize: 8 }}>Página {pageIndex + 1}</Text>
            </View>
            <View style={styles.table}>
              <View style={styles.headerRow}>
                {cols.map((col, i) => (
                  <View key={i} style={{ ...styles.col, width: col.width }}>
                    <Text>{col.header}</Text>
                  </View>
                ))}
              </View>
                {data
                  .slice(pageIndex * rowsPerPage, (pageIndex + 1) * rowsPerPage)
                  .map((row, i) => (
                    <View key={i} style={styles.row}>
                      <View style={{ ...styles.col, width: 80 }}>
                        <Text style={styles.boldText}>{formatDate(row.fecha)}</Text>
                      </View>
                      <View style={{ ...styles.col, width: 170 }}>
                        <Text style={styles.boldText}>{row.nombreCuentaBanco}</Text>
                        <Text style={styles.text}>{row.numeroCuentaBanco}</Text>
                      </View>
                      <View style={{ ...styles.col, width: 170 }}>
                        <Text style={styles.text}>{row.descripcion}</Text>
                      </View>
                      <View style={{ ...styles.col, width: 120 }}>
                        <Text style={[styles.boldText, { textAlign: 'right' }]}>
                          {formatCurrency(row.montoAbono)}
                        </Text>
                      </View>
                      <View style={{ ...styles.col, width: 120 }}>
                        <Text style={(styles.text, { textAlign: 'right' })}>
                          {formatCurrency(row.montoCargo)}
                        </Text>
                      </View>
                      <View style={{ ...styles.col, width: 80 }}>
                        <Text style={styles.boldText}>{row.status}</Text>
                      </View>
                    </View>
                  ))}
              </View>
            {pageIndex === pageCount - 1 && (
              <View style={styles.footer}>
                <Text style={{ fontSize: 12 }}>
                  Declaro(mos) dejando constancia que el presente {title} ha sido confeccionado correctamente. Para constancia, firmamos:
                </Text>
                <View style={styles.signatureSection}>
                  <View style={styles.signatureBlock}>
                    <View style={styles.signatureLine} />
                    <Text style={{ fontSize: 12 }}>Contador</Text>
                  </View>
                  <View style={styles.signatureBlock}>
                    <View style={styles.signatureLine} />
                    <Text style={{ fontSize: 12 }}>Representante Legal</Text>
                  </View>
                </View>
              </View>
            )}
          </Page>
        ))}
      </Document>
    )
  }

  // const onClick = () => {
  //   setShowModal({
  //     show: true,
  //     title: 'Exportar a PDF',
  //     body: () => (
  //       <PDFDownloadLink orientation='landscape' document={<MyDocument />} fileName='data.pdf'>
  //         {({ loading }) =>
  //           loading ? <CircularProgress color='primary' thickness={5} size={60} /> : 'Descargar PDF'
  //         }
  //       </PDFDownloadLink>
  //     )
  //   })
  // }
  if (!data) return null
  return (
    <PDFDownloadLink
      className='flex items-center justify-center w-10 h-10 text-red-700 font-bold tracking-tighter cursor-pointer rounded-full shadow-full-sm bg-white'
      orientation='landscape'
      document={<MyDocument />}
      fileName={`${title}_${currentBusiness}_${rut}_${month}_${year}.pdf`}
    >
      {/* {({ loading }) =>
        loading ? <CircularProgress color='primary' thickness={3} size={20} /> : 'PDF'
      } */}
      PDF
    </PDFDownloadLink>
  )
  //   <>
  //     <button
  //       onClick={onClick}
  //       className='w-10 h-10 text-blue-700 font-bold cursor-pointer rounded-full shadow-full-xs bg-white'
  //     >
  //       PDF
  //     </button>
  //   </>
  // )
}

export default ExportPDFBankMovements
