//react
import clsx from 'clsx'
import { useState, useEffect, useContext } from 'react'
import GlobalContext from '../../../../../../context/global-context'

//utils
import { formatCurrency, formatDate, formatRut } from '../../../../../utils/formatter'
import { getCurrentDate } from '../../../../../utils/constant'

//ui
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import DoneAllIcon from '@mui/icons-material/DoneAll'
import BlockIcon from '@mui/icons-material/Block'

//api
import { OrganizationAPI } from '../../../../../../api/organization.api'

//components
import { colsIngresos as cols } from '../../../../data/headersTables'
import { Text } from '../../../../ui'
import CustomPagination from '../../../../table/CustomPagination'
import ModalDialogMotionEditForm from '../../../../modalDialogEditarMovimiento/ModalDialogMotionEditForm'
import { Link } from '@mui/material'
import translations from '../../../../ui/traslation/traslationAdministracion.json'
import ModalViewAdministracion from '../../../../ui/tables/baseTable/ModalViewAdministracion'
//assets
import isologo_SII from '../../../../../../assets/isologo_SII.png'
import iconNuevo from '../../../../../../assets/iconos/iconNuevo.png'
import StarIcon from '@mui/icons-material/StarOutlineOutlined'
import StarFullIcon from '@mui/icons-material/StarOutlined'
import { TablesAPI } from '../../../../../../api/table.api'
import CustomButton from '../../../../ui/CustomButton'

const TableIngresos = ({ rows, getData, rowsPerPage, setRowsPerPage, highlightedRows, toggleHighlight, setData }) => {
  const [page, setPage] = useState(0)
  const { ui, currentBusiness } = useContext(GlobalContext)
  const organizationAPI = new OrganizationAPI()
  const [switchGetData, setSwitchGetData] = useState(false)
  const [order, setOrder] = useState({ col: 'Folio', field: 'numeroFolio', direction: 'desc' })
  const [currentDate] = useState(getCurrentDate())
  const [open, setOpen] = useState(false)
  const tablesAPI = new TablesAPI()
  const [showPopup, setShowPopup] = useState(false);
  const [pendingApproval, setPendingApproval] = useState(null)
  const [, setIsCheckboxChecked] = useState(false);
  //const [approveAll, setApproveAll] = useState(false)
  const [dataModal, setDataModal] = useState({
    idLibro: '',
    status: ''
  })
  const [openComprobante, setOpenComprobante] = useState(false)
  const [dataComprobante, setDataComprobante] = useState({
    libroId: ''
  })

  const handleComprobante = (libroId) => {
    setDataComprobante({ ...dataComprobante, libroId: libroId })
    setOpenComprobante(true)
  }

  const handleSort = ({ col, field }) => {
    const isAsc = order.col === col && order.direction === 'asc'
    setOrder({ col, field, direction: isAsc ? 'desc' : 'asc' })
  }

  const sortRows = () => {
    return rows.sort((a, b) => {
      if (order.direction === 'asc') {
        return a[order.field] < b[order.field] ? -1 : 1
      } else {
        return a[order.field] > b[order.field] ? -1 : 1
      }
    })
  }

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0

  const handleChangePage = newPage => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = value => {
    setRowsPerPage(value)
    setPage(0)
  }

  const styles = {
    table: 'w-full text-[#000231] text-xs border-collapse leading-6 table-fixed', // Fijo para scroll
    tr: 'border-b border-gray-200 hover:bg-gray-100/50',
    td: 'text-left align-top p-4 max-w-[200px] overflow-x-auto whitespace-nowrap', // Scroll horizontal
    th: 'border-2 border-transparent border-b-gray-200 text-left p-4 whitespace-nowrap bg-white',
    thead: 'sticky top-0 z-10',
    tbody: 'overflow-y-auto',
    boldText: 'font-bold',
    text: 'text-xs text-gray-500',
    smallCol: 'w-[100px]', // Columna pequeña para íconos
    wideCol: 'w-[300px]' // Ancho controlado para texto largo
  }

  const handleClickOpen = (idLibro, status) => {
    setDataModal({ ...dataModal, idLibro: idLibro, status: status })
    setOpen(true)
  }

  const handleClickApproved = async data => {
    ui.setLoader({ visible: true, text: 'editando datos' })
    const updateData = {
      cuentaContableId: null,
      glosa: '',
      vencimiento: data.fechaVencimiento,
      validation: 1
    }
    try {
      const response = await organizationAPI.patchDataLibroContable(
        currentBusiness.id,
        data.id,
        updateData
      )
      await getData()
      console.info(response)
    } catch (error) {
      console.log(error)
    } finally {
      ui.setLoader({ visible: false, text: '' })
    }
  }

  const orderableCols = [
    { col: '', field: null },
    { col: 'Folio', field: 'numeroFolio' },
    { col: 'Cliente', field: 'razonSocial' },
    { col: 'Fecha', field: 'fecha' },
    { col: 'Clasificación', field: 'nombreCuenta' },
    { col: 'Monto', field: 'montoTotal' },
    { col: 'Status', field: 'status' },
    { col: 'Acciones', field: 'aprobado' }
  ]

  useEffect(() => {
    getData()
    setSwitchGetData(false)
  }, [switchGetData === true])

  const obtenerAbreviatura = nombre => {
    return translations.documentosContables[nombre] || nombre // Si no encuentra la abreviatura, devuelve el nombre original.
  }

  const handleCheckboxClick = (event) => {
    event.stopPropagation()
    event.preventDefault()
    const allApproved = rows.every(item => item.aprobado === 1)
    setPendingApproval(!allApproved)
    setShowPopup(true)
  }

  const confirmAction = async () => {
    setShowPopup(false)

    const newData = rows.map(item => ({
        ...item,
        aprobado: pendingApproval ? 1 : 0,
    }))

    setData(newData);
    setPendingApproval(null);

    if (currentBusiness.id) {
        try {
            const ids = rows.map(item => item.id)
            await tablesAPI.patchApproveAll(currentBusiness.id, ids, pendingApproval ? 1 : 0)
        } catch (error) {
            console.error(error)
        }
    }
  }

  useEffect(() => {
      setIsCheckboxChecked(rows.every(item => item.aprobado === 1))
  }, [rows])

  if (!rows?.[0])
    return <Text className='grid place-items-center py-12'>{translations.tableAction.noData}</Text>
  else
    return (
      <div className='overflow-x-auto'>
        <div className='max-h-96 overflow-y-auto mt-10'>
          <table className={styles.table}>
            <thead className={styles.thead}>
              <tr>
                {orderableCols.map(({ col, field }, i) => (
                  <th
                    className={clsx(
                      styles.th,
                      { 'cursor-pointer': field },
                      i === 0 && styles.smallCol
                    )}
                    onClick={() => field && handleSort({ col, field })}
                    key={i}
                  >
                    {col === 'Acciones' ? (
                      <div className='flex items-center'>
                        <span>{col}</span>
                        <label className='ml-2 flex items-center'>
                          <input
                            className='rounded-sm'
                            type='checkbox'
                            checked={rows.every(item => item.aprobado === 1)}
                            onClick={handleCheckboxClick}
                          />
                        </label>
                      </div>
                    ) : (
                      col
                    )}
                    {order.col === col && (
                      <KeyboardArrowDownIcon
                        className={clsx('transition-all ml-2 cursor-pointer', {
                          'opacity-40': order.col !== col,
                          'rotate-180': order.direction === 'desc'
                        })}
                      />
                    )}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className={styles.tbody}>
              {(rowsPerPage > 0
                ? sortRows().slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                : sortRows()
              )?.map((row, i) => (
                <tr className={clsx(styles.tr)} key={i}>
                  {/* Nombre origen */}
                  <td className={clsx(styles.td, styles.smallCol)}>
                    <div className='flex items-center'>
                      {highlightedRows[row.id] ? (
                          <StarFullIcon
                            style={{ color: '#FFD700', cursor: 'pointer' }}
                            onClick={() => toggleHighlight(row.id)}
                          />
                        ) : (
                          <StarIcon
                            style={{ color: '#a1a1aa', cursor: 'pointer' }}
                            onClick={() => toggleHighlight(row.id)}
                          />
                        )}
                      <div className='relative'>
                        {currentDate === formatDate(row.fecha) ? (
                          <td className={clsx(styles.td, styles.smallCol)}>
                            <img src={isologo_SII} className='min-w-6 h-6 mt-2' />
                            <img
                              src={iconNuevo}
                              className='relative z-auto bottom-11 left-5 w-5 h-5 opacity-60'
                            />
                          </td>
                          ) : (
                            <td className={clsx(styles.td, styles.smallCol)}>
                              <img src={isologo_SII} className='min-w-6 h-6 mt-2' />
                            </td>
                          )}
                      </div>
                    </div>
                  </td>
                  {/* Folio */}
                  <td className={styles.td}>
                    <Text className={styles.boldText}>{obtenerAbreviatura(row.nombreFolio)}</Text>
                    <Text className={styles.text}>{row.folio}</Text>
                  </td>
                  {/* Cliente */}
                  <td className={clsx(styles.td)}>
                    <Text className={styles.boldText}>{row.razonSocial || '<Razon Social>'}</Text>
                    <Text className={styles.text}>
                      {(row.rut && formatRut(row.rut)) || '<RUT>'}
                    </Text>
                  </td>
                  {/* Fecha */}
                  <td className={styles.td}>
                    <Text className={styles.boldText}>{row.fecha && formatDate(row.fecha)}</Text>
                    <Text className={styles.text}>
                      {row.fechaVencimiento && formatDate(row.fechaVencimiento)}
                    </Text>
                    <Text className={styles.text}>{row.diasHastaVencimiento}</Text>
                  </td>
                  {/* Clasificacion */}
                  <td className={styles.td}>
                    <Text className={styles.boldText}>{row.nombreCuenta}</Text>
                    <Text className={styles.text}>{row.codigoCuenta}</Text>
                    <Text className={styles.text}>
                      <Link
                        onClick={() => handleComprobante(row.id, 'venta')}
                        className={styles.text}
                        style={{ cursor: 'pointer' }}
                      >
                        {row.comprobante || 'ID'}
                      </Link>
                      {' @' + (row.usuarioClasificador || 'Luca')}
                    </Text>
                  </td>
                  {/* Monto */}
                  <td className={styles.td}>
                    <Text className={clsx(styles.boldText, 'text-right font-bold')}>
                      {formatCurrency(row.montoTotal)}
                    </Text>
                    <Text className='text-xs text-right text-accent3'>
                      {row.nombreFolio === 'Nota de Crédito Electrónica'
                        ? `RETENIDO: ${formatCurrency(row.montoIvaRetenido)}`
                        : `IVA: ${formatCurrency(row.montoIvaRetenido)}`}
                    </Text>
                    <Text className='text-xs text-right text-gray-500'>
                      NETO: {formatCurrency(row.montoNetoLiquido)}
                    </Text>
                  </td>
                  {/* Status */}
                  <td className={styles.td}>
                    <Text
                      className={clsx(styles.boldText, 'tracking-wider', {
                        'text-accent3': row.status === 'POR PAGAR',
                        'text-accent2': row.status === 'PAGADO'
                      })}
                    >
                      {row.status}
                    </Text>
                  </td>
                  {/* Acciones */}
                  <td className={styles.td}>
                    <div className='flex h-full items-center justify-center space-x-3'>
                      <div className='flex flex-col text-xs text-gray-500 items-center justify-center hover:cursor-pointer'>
                        <div
                          onClick={() =>
                            row.aprobado !== -5 && handleClickOpen(row.id, row.status, 'venta')
                          }
                          className='bg-[#fff] flex text-slate-400 shadow-full-xs rounded-full items-center justify-center w-9 h-9 hover:bg-slate-100/50 transition-colors duration-150'
                        >
                          <EditOutlinedIcon sx={{ color: 'inherit' }} />
                        </div>
                        <Text className='mt-2'>Editar</Text>
                      </div>
                      <div className='flex flex-col text-xs text-gray-500 items-center justify-center'>
                        <button
                          onClick={() => row.aprobado === 0 && handleClickApproved(row)}
                          className={`${
                            row.aprobado === -5
                              ? 'bg-red-500 hover:bg-red-600 text-white'
                              : row.aprobado !== 0
                                ? 'bg-accent2 text-white hover:bg-white hover:text-slate-400'
                                : 'bg-[#fff] text-slate-400 hover:bg-slate-100/50 '
                          } flex shadow-full-xs rounded-full items-center justify-center w-9 h-9 transition-colors duration-150`}
                        >
                          {row.aprobado === -5 ? (
                            <BlockIcon />
                          ) : row.aprobado !== 0 ? (
                            <DoneAllIcon />
                          ) : (
                            <CheckOutlinedIcon />
                          )}
                        </button>
                        {row.aprobado === -5 ? (
                          <Text className='mt-2'>{translations.tableAction.notConsidered}</Text>
                        ) : row.aprobado !== 0 ? (
                          <Text className='mt-2'>{translations.tableAction.approved}</Text>
                        ) : (
                          <Text className='mt-2'>{translations.tableAction.approve}</Text>
                        )}
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
              {emptyRows > 0 && (
                <tr className={styles.tr} style={{ height: 89 * emptyRows }}>
                  <td className={styles.td} colSpan={cols?.length} aria-hidden />
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <CustomPagination
          count={rows?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        {open && (
          <ModalDialogMotionEditForm
            title='Editar movimiento'
            open={open}
            setOpen={setOpen}
            idLibro={dataModal.idLibro}
            status={dataModal.status}
            type={'venta'}
            setSwitchGetData={setSwitchGetData}
          />
        )}
        {openComprobante && (
           <ModalViewAdministracion
           openModal={openComprobante}
           setOpenModal={setOpenComprobante}
           comprobanteId={dataComprobante.libroId}
           validation={1} // O ajusta según lo que necesites
         />
        )}
         {showPopup && (
          <div className='fixed inset-0 flex items-center justify-center bg-black bg-opacity-40 z-50'>
            <div className='bg-white px-6 py-4 rounded-lg shadow-xl w-96 text-center'>

              <h2 className='text-base text-gray-800 mt-2'>
                {pendingApproval
                  ? '¿Estás seguro de que deseas aprobar masivamente los movimientos?'
                  : '¿Estás seguro de que deseas desaprobar masivamente los movimientos?'}
              </h2>
              <p className='text-sm text-gray-600 mt-3'>
                Esta acción tiene impacto en el algoritmo de clasificación.
              </p>
              <div className='flex justify-between mt-4'>
                <CustomButton title='Aceptar' color='accent2' className='rounded-full' onClick={confirmAction} />
                <CustomButton title='Cancelar' color='primary' className='rounded-full' onClick={() => setShowPopup(false)} />
              </div>
            </div>
          </div>
        )}
      </div>
    )
}

export default TableIngresos
