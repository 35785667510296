import { useEffect, useState } from 'react'
import {Paper, SearchInput} from '../../components/modules/ui/index'

//assets
import StarIcon from '@mui/icons-material/StarOutlineOutlined'
import StarFullIcon from '@mui/icons-material/StarOutlined'

//data
import { arrayIcons } from './reporteIcon'

// hooks
import { useContentTypeReportes } from './useReportes.hook'
import TooltipIcons from './TooltipIcons'

const NavBar = ({ setShowModal }) => {
  const styleImage = 'cursor-pointer w-8 h-8'
  const setTypeContent = useContentTypeReportes(state => state.setTypeContent)
  const addFavoriteIcon = useContentTypeReportes(state => state.addFavoriteIcon)
  const removeFavoriteIcon = useContentTypeReportes(state => state.removeFavoriteIcon)
  const clickedIcons = useContentTypeReportes(state => state.clickedIcons)
  const resetTypeContent = useContentTypeReportes(state => state.resetTypeContent)
  const [searchTerm, setSearchTerm] = useState('')

  useEffect(() => {
    resetTypeContent()
  }, [resetTypeContent])

  const handleIconClick = (unIcons) => {
    setTypeContent(unIcons.id)
    setShowModal(true)
  }

  const handleStarClick = (e, unIcons) => {
    e.stopPropagation()
    if (clickedIcons.has(unIcons.id)) {
      removeFavoriteIcon(unIcons.id)
    } else {
      addFavoriteIcon(unIcons)
    }
  }

  const filteredIcons = searchTerm
  ? arrayIcons.filter((unIcons) =>
      unIcons.title.toLowerCase().includes(searchTerm.toLowerCase())
    )
  : arrayIcons
  
  return (
    <Paper>
      <section className='flex items-center justify-end'>
        <SearchInput
          placeholder='Buscar reporte'
          classNameBorder='rounded-full'
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </section>
      <section className='grid grid-cols-[repeat(auto-fit,minmax(180px,1fr))] gap-6 py-4 max-w-screen-lg mx-auto place-items-center'>
        {filteredIcons.length > 0 ? (
          filteredIcons.map((unIcons, index) => (
            <div
              key={index}
              className='relative flex flex-col items-center justify-center shadow-md border rounded-2xl w-full max-w-[220px] aspect-square'
              onClick={() => handleIconClick(unIcons)}
            >
              {unIcons.tooltip && (
                <div className='absolute top-2 right-2'>
                  <TooltipIcons tooltipText={unIcons.tooltip} />
                </div>
              )}
              <div className='absolute top-2 left-2'>
                {clickedIcons.has(unIcons.id) ? (
                  <StarFullIcon
                    className={styleImage}
                    style={{ color: 'orange' }}
                    onClick={(e) => handleStarClick(e, unIcons)}
                    alt='icon'
                  />
                ) : (
                  <StarIcon
                    className={styleImage}
                    style={{ color: '#a1a1aa' }}
                    onClick={(e) => handleStarClick(e, unIcons)}
                    alt='icon'
                  />
                )}
              </div>
              <img className='w-20 h-20 cursor-pointer mt-5' src={unIcons.icon} alt={unIcons.title} />
              <p className='text-center text-xs'>{unIcons.title}</p>
            </div>
          ))
        ) : (
          searchTerm && <p className='text-center text-gray-500'>No se encontraron resultados</p>
        )}
      </section>
    </Paper>
  )
}

export default NavBar